import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'user-dependants',
  templateUrl: './dependants.component.html',
  styleUrls: ['./dependants.component.css']
})
export class DependantsComponent extends BaseCrudComponent {

  	@Input() profile: any;

    translations:any;

    relations:any;
    doctypes:any;
    countries:any;
    provinces:any;
    cities:any;
    curr_country:any;

    files:any;
    filetypes:any;
    download_url:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'employee/dependant';
        this.create_url = 'employee/dependant';
        this.update_url = 'employee/dependant';
        this.delete_url = 'employee/dependant/del';
        this.download_url = 'employee/dependant/doc';
        this.search_fields = [];
        this.relations = [];
        this.doctypes = [];
        this.countries = [];
        this.provinces = [];
        this.cities = [];
        this.curr_country = {};

        this.files = [];
        this.filetypes = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;

              this.storage.get('common').then(c => {
              		this.relations = c['data']['fam'];
              		this.doctypes = c['data']['doc'];
              		this.countries = c['countries'];
              });

              super.ngOnInit();
              this.getList();
        });
    }

    openCreateModal(content:any){
        this.c_obj = {};
        super.openCreateModal(content);
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'fam_id': new FormControl(fields.fam_id, [Validators.required]),
            'doc_id': new FormControl(fields.doc_id, [Validators.required]),
            'doc_val': new FormControl(fields.doc_val, [Validators.required]),
            'first_name': new FormControl(fields.first_name, [Validators.required]),
            'last_name': new FormControl(fields.last_name, [Validators.required]),
            'dob': new FormControl(fields.dob, [Validators.required]),
            'country_id': new FormControl(fields.country_id, [Validators.required]),
            'province_id': new FormControl(fields.province_id, [Validators.required]),
            'nationality_id': new FormControl(fields.nationality_id, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        /*var to:any;
        to = form;*/
        const formData = new FormData();
        formData.append('first_name', form.first_name);
        formData.append('last_name', form.last_name);
        formData.append('fam_id', form.fam_id);
        formData.append('doc_id', form.doc_id);
        formData.append('doc_val', form.doc_val);
        formData.append('dob', form.dob);
        formData.append('country_id', form.country_id);
        formData.append('province_id', form.province_id);
        formData.append('city_id', form.city_id);
        formData.append('nationality_id', form.nationality_id);

        if( this.files.length > 0 ){
            const file = this.files[0];
            formData.append('file', file);
        }
        

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.customPostCall(qst, formData).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.files = [];
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        /*var to:any;
        to = form;*/

        const formData = new FormData();
        formData.append('first_name', form.first_name);
        formData.append('last_name', form.last_name);
        formData.append('fam_id', form.fam_id);
        formData.append('doc_id', form.doc_id);
        formData.append('doc_val', form.doc_val);
        formData.append('dob', form.dob);
        formData.append('country_id', form.country_id);
        formData.append('province_id', form.province_id);
        formData.append('city_id', form.city_id);
        formData.append('nationality_id', form.nationality_id);

        if( this.files.length > 0 ){
            const file = this.files[0];
            formData.append('file', file);
        }

        var qst = this.update_url + '/' + form.id;
        this.isUpdating = true;

        this.srv.customPostCall(qst, formData).subscribe(x => {
            this.isUpdating = false;
            if(x.status){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.message, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    private getFilterStr(id:any, cat:any){
		var str = '';
		var resp:any;

		resp = this[cat].filter(f => {
			return f.id == id;
		})

		if( resp.length > 0 ){
			str = resp[0]['description'];
		}

		return str;
	}

	public filterProvinces(id:any){
        var resp:boolean;
        this.provinces = [];
        this.cities = [];

        if( id != '' ){
            let test = this.countries.filter(c => {
                return c.id == id;
            });

            if( test.length > 0 ){
            	this.provinces = test[0]['provinces'];
            }
        }
        else{
        	this.provinces = [];
        }
    }

    public filterCities(id:any){
        var resp:boolean;
        this.cities = [];

        if( id != '' ){
            let test = this.provinces.filter(p => {
                return p.id == id;
            });

            if( test.length > 0 ){
            	this.cities = test[0]['cities'];
            }
        }
        else{
        	this.cities = [];
        }
    }

    openUpdateModal(content:any, obj:any){
    	this.filterProvinces(obj.country_id);
    	this.filterCities(obj.province_id);
        this.files = [];
    	super.openUpdateModal(content, obj);
    }

    handleInputChange(e, obj:any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        //console.log(file);
        this.files = [];
        this.files.push(file);
    }

    public showFile(id:any){
        var url = this.download_url + '/' + id;
        //window.open(url);

        this.srv.getBlobCall(url).subscribe(resp => {
            //this.isCreating = false;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

}
