import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ApiService } from '../../services/main/api.service';
import { LoginService } from '../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import * as moment from 'moment-timezone';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseCrudComponent {
	public psconfig: PerfectScrollbarConfigInterface = {};

	translations:any;
    momentjs: any = moment;
    img_exts:any;
    api_url:any;
    banner_url:any;

    notif_refresh_time = 120000;
    notiftimer:any;
    banners:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService,
        private _lightbox: Lightbox
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'dashboard/events';
        this.pageSize = 4;

        this.img_exts = ['png', 'jpg', 'jpeg', 'mp4'];
        this.api_url = Global.url;
        this.banner_url = this.api_url + 'banner/';
        this.banners = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
                this.getBanners();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
            this.getBanners();

            var curr = this;
            this.notiftimer = setInterval(function(){ curr.getList(); }, this.notif_refresh_time);
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
            	x.data.rows.forEach(y => {
            		y.images = [];
            		y.links = [];

            		y.media.forEach(m => {
            			if( this.img_exts.indexOf(m.ext) > -1 ){
            				y.images.push(m);
            			}
            			else{
            				y.links.push(m);
            			}
            		});
            	});

                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    public getBanners(){
        var qst = 'dashboard/banners';
        var tosend = {};

        this.srv.getCall(qst, tosend).subscribe(x => {
            //console.log(x);
            if(x.status){
                this.banners = x.data.rows;
            }
        });
    }

    public showFile(id:any){
		var url = 'event/doc/' + id;
        //window.open(url);

        this.srv.getBlobCall(url).subscribe(resp => {
    		//this.isCreating = false;
    		var myurl = URL.createObjectURL(resp);
    		//console.log(resp);
    		window.open(myurl);
    	});
	}

    clearTimers(){
        clearInterval(this.notiftimer);
    }

    ngOnDestroy(){
        this.clearTimers();
    }

    openPreview(obj:any): void {
        var album:any;

        album = [
            {src: this.api_url + 'event/doc/' + obj.id}
        ];
        
        // open lightbox
        this._lightbox.open(album, 0, {showZoom:true});
    }


}
