import { Component, OnInit, Input, ChangeDetectionStrategy, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { ApiService } from '../../../services/main/api.service';
import { LoginService } from '../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat, Subject, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  isAfter,
  isBefore,
  addHours,
  format,
  parse,
  setHours,
  setMinutes,
  getHours,
  getMinutes
} from 'date-fns';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewBeforeRenderEvent
} from 'angular-calendar';

@Component({
  selector: 'app-dinner',
  templateUrl: './dinner.component.html',
  styleUrls: ['./dinner.component.css']
})
export class AdminDinnerComponent extends BaseCrudComponent {

  	@ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
	@ViewChild('create1', { static: true }) create1: TemplateRef<any>;

  	view = 'month';

  	viewDate: Date = new Date();

  	modalData: {
    	action: string;
    	event: CalendarEvent;
  	};

  	actions: CalendarEventAction[] = [
	    {
	      label: '<i class="fa fa-fw fa-pencil text-white"></i>',
	      onClick: ({ event }: { event: CalendarEvent }): void => {
	        this.handleEvent('Edited', event);
	      }
	    },
	    {
	      label: '<i class="fa fa-fw fa-times  text-white"></i>',
	      onClick: ({ event }: { event: CalendarEvent }): void => {
	        this.events = this.events.filter(iEvent => iEvent !== event);
	        this.handleEvent('Deleted', event);
	      }
	    }
	];

  	refresh: Subject<any> = new Subject();

	//events: CalendarEvent[] = [];
	events: any = [];
	//events: Observable<any[]>;

  	activeDayIsOpen = false;

  	translations:any;
    loadingRequired:any;
    teamid:any;

    bookings:any;
    orders:any;
    sysparams:any;
    teamsubscription: Subscription;
    reloadSub: Subscription;

    listApprovers:any;
    sudo:any;
    showSudo:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public _login: LoginService
      ) {

      	super(srv, modalService, storage, router);
        this.loadingList = false;
        this.loadingRequired = false;

        this.bookings = [];
        this.orders = [];
        this.sysparams = {};

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;

        this.reloadSub = this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });

        this.teamsubscription = this.srv.team_changed.subscribe(val => {
        	if( val ){
        		//console.log('team changed | updating');
        		this.updateCalendar();
        	}
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();

            this._login.hasRole(['superadmin','admin']).then(r => {
                //this.showSudo = r;
                this.showSudo = true;
                //if( r ){
                if( true ){
                    this.getApproversData();
                }
            });
        });

        this._login.getSystemInfo().then(x => {
        	this.sysparams = x;
        });
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    public getList(){
    	this.updateCalendar();
    }

    public getOrdersData(teamid:any, start:any){
        var qst = 'order/list';
        var tosend = {
        	start: start,
        	tid: teamid,
        	dinner: 1,
        };

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        //this.srv.postCall(qst, tosend).subscribe(x => {
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            this.orders = [];
            this.events = [];
            
            if(x.status){
                //console.log(x.data.rows);
                var nrows = x.data.rows;

                nrows.forEach((val,key) => {
	                this.orders.push({
	                    id: val.id,
	                    start: val.due_date,
	                    total: val.total,
	                    subtotal: val.subtotal,
	                    tax: val.tax,
	                    comment:val.comment,
	                    today_soup:val.today_soup
	                });

	                val.details.forEach(( x , i ) => {
	                    this.events.push({
	                    	title: x.description,
	                    	start: parse(val.due_date),
	                    	color: {primary:x.color, secondary:x.color},
	                    	id: x.id,
	                    	meal_id: x.item_id,
	                    	qty: x.qty,
	                    	actions: this.actions
	                    });
	                });
	                //console.log(this.events);
	            });

                this.refresh.next();
            }
        });
    }

    public getBookingData(teamid:any, start:any){
        var par_url = 'mealbooking/list';
        var tosend = {
        	start: start,
        	tid: teamid,
        	dinner: 1
        };

        return this.srv.getCall(par_url, tosend).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'amount': new FormControl(fields.amount, [Validators.required])
        });

        return mygroup.valid;
    }

    //calendar actions
    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }){
	    /*console.log(events);
	    console.log(date);*/
	    this.c_obj = {};

	    //var bypass_time = ((curr.user.role == 'superadmin') || (curr.user.role == 'admin')) ? true : false;
	    let role = this.sysparams.user.role;
        //var bypass_time = ((role == 'superadmin') || (role == 'admin')) ? true : false;
        var bypass_time = true;
        var seldate = date;
        var seldate_str = format(date, "YYYY-MM-DD");
        var today = new Date();
        var h = getHours(today);
        var m = getMinutes(today);
        
        seldate = setMinutes(seldate, m);
        seldate = setHours(seldate, h);
        
        var limit_hour = this.sysparams.settings.max_dinner_time;
        limit_hour = limit_hour.split(':');
        //i think they asked to trick the user with a hour but check for another
        //var h_lim = (limit_hour[0] != undefined) ? parseInt(limit_hour[0]) + 5 : 9;
        var h_lim = (limit_hour[0] != undefined) ? parseInt(limit_hour[0]) : 9;
        var m_lim = (limit_hour[1] != undefined) ? limit_hour[1] : 30;
        
        var today_limit = new Date();
        today_limit = setMinutes(today_limit, m_lim);
        today_limit = setHours(today_limit, h_lim);


        this.updateDayMeals(seldate_str);

	    this.c_obj.seldate = seldate;
	    this.c_obj.seldate_str = seldate_str;

	    if( this.c_obj.meals.length > 0 ){
            /*console.log(today_limit);
            console.log(seldate);*/
            if( isSameDay(seldate, today) ){
                if( isAfter(seldate, today_limit) && !bypass_time ){
                	//i think they asked to trick the user with a hour but check for another
                    //Swal.fire('Lo sentimos. La merienda se puede solicitar hasta las '+(h_lim - 5)+':'+m_lim+' pm', '', 'error');
                    Swal.fire('Lo sentimos. La merienda se puede solicitar hasta las '+(h_lim)+':'+m_lim+' pm', '', 'error');
                    return;
                }
            }
            else{
            	if( isBefore(seldate, today) && !bypass_time ){
	                return;
	            }
				//console.log('here');
            }

            this.c_obj.start = seldate_str;
            
            if( this.c_obj.order != undefined ){
                this.c_obj.id = this.c_obj.order.id;
                this.c_obj.comment = this.c_obj.order.comment;
            }

            super.openCreateModal(this.create1);
        }
	}

	updateDayMeals(dstart:any){
		var curr = this.c_obj;

        curr.meals = [];
        curr.order = null;
        curr.comment = '';
        curr.id = -1;

        curr.meals = this.bookings.filter(function(e){
            return (e.start == dstart);
        });

        //update meals with events if exist
        curr.meals.filter(e => {
            var checker = this.events.filter(item => {
            	//(item.start == dstart)
              	return (item.meal_id == e.id) && isSameDay(item.start, parse(dstart));
            });
			/*console.log(e);
			console.log(this.events);*/
            if( checker[0] != undefined ){
                e.detail_id = checker[0].id;
                e.qty = checker[0].qty;
                e.selected = true;
                //e.selected = 'checked';
            }
        });
        
        //update order if exist
        curr.order = this.orders.filter(function(item) {
          return item.start == dstart;
        })[0];
    }

    checkItem(obj:any){
    	obj.selected = !obj.selected;

        if( obj.selected ){
            obj.qty = 1;
        }
        else{
            obj.qty = 0;
        }
    }

	eventTimesChanged({
	    event,
	    newStart,
	    newEnd
	  }: CalendarEventTimesChangedEvent){
	  //}: CalendarEventTimesChangedEvent): void {
	    event.start = newStart;
	    event.end = newEnd;
	    this.handleEvent('Dropped or resized', event);
	    this.refresh.next();
	}

  	//handleEvent(action: string, event: CalendarEvent): void {
  	handleEvent(action: string, event: CalendarEvent){
		/*this.modalData = { event, action };
	    this.modalService.open(this.modalContent, { size: 'lg' });*/
  	}

	updateCalendar(){
		this._login.getTeam().then(x => {
	        this.teamid = x;
	        var refdate = format(this.viewDate, "YYYY-MM-DD");
	        this.clearData();

	        this.loadingRequired = true;
            this.loadingList = true;

		    this.getBookingData(this.teamid, refdate).subscribe(x => {
		    	this.loadingRequired = false;
                this.loadingList = false;

		        if(x != false){
		            this.bookings = [];
		            var nrows = x;
            
		            nrows.forEach((val,key) => {
		                var stock = (val.stock == undefined) ? 'Si' : ((val.stock == 0) ? 'Si' : (val.stock - val.used));
		                var price = (val.meal.price) ? val.meal.price : val.meal.category.price;
		                //var name = val.meal.name + ' ( ' + stock + ' )' + ' - ' + price + ' USD';
		                var name = val.meal.name + ' ( ' + val.meal.category.name + ' )';
		                //this time we use meal id
		                this.bookings.push({
		                    title: name,
		                    name: val.meal.name,
		                    start: val.due_date, 
		                    color: val.meal.category.color, 
		                    cat: val.meal.category_id,
		                    catname: val.meal.category.name,
		                    id: val.meal.id
		                });
		                //change date background
		                //$('#fullcalendar td[data-date='+val.due_date+']').addClass('day-booked');
		            });

		            //console.log(this.bookings);
		        }

                this.getOrdersData(this.teamid, refdate);
		    });

		    //this.getOrdersData(this.teamid, refdate);
	    });
	}

	handleDateChange(){
	  	//console.log(this.viewDate);
        this.getList();
	}

	beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent){
	    renderEvent.body.forEach((day) => {
	      	const dayOfMonth = format(day.date, "YYYY-MM-DD");
	      	
	      	let checker = this.bookings.filter(b => {
	      		//return b.start == dayOfMonth;
                return format(b.start, "YYYY-MM-DD") == dayOfMonth;
	      	});

	      	if(checker.length > 0){
	      		day.cssClass = 'bg-booked';
	      	}
		    
	    });
	}

	/*beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
	    renderEvent.header.forEach((day) => {
	      	const dayOfMonth = format(day.date, "YYYY-MM-DD");
	      	//console.log(day);
	      	
	      	let checker = this.bookings.filter(b => {
	      		return b.start == dayOfMonth;
	      	});

	      	if(checker.length > 0){
	      		day.cssClass = 'bg-booked';
	      	}
		    
	    });
	}*/

	ngOnDestroy() {
		//console.log('unsubscribing');
        this.reloadSub.unsubscribe();
        this.teamsubscription.unsubscribe();
    }

    clearData(){
    	//this.bookings = [];
    	this.events = [];
    	this.orders = [];
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        //validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        if( (form.meals == undefined) || (!Array.isArray(form.meals)) ){
        	Swal.fire('Data invalida', '', 'error');
            return;
        }

        var to:any;
        to = {};
        
        var rows = [];
        form.meals.forEach(( val , key ) => {
            if( val.selected ){
                rows.push({id: val.id, qty: val.qty, detail_id: val.detail_id});
            }
        });

        if( (rows.length <= 0) && (form.id == -1) ){
            Swal.fire('Seleccione al menos 1 plato', '', 'error');return;
        }

        to.start = form.start;
        to.tid = this.teamid;
        to.comment = form.comment;
        to.items = rows;
        to.dinner = 1;

        if( form.id != -1 ){
            to.id = form.id;
        }

        if( (this.sudo != undefined) && (this.sudo != null) ){
            to['uid'] = this.sudo.id;
        }

        var qst = 'order/process';
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.updated, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

    updateSudo(){
        //console.log(this.sudo);
        this.getList();
    }

}
