import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'user-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {

  	@Input() rows: any;
	
	constructor() { }

	ngOnInit() {
	}

}
