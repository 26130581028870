import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-report-remotework',
  templateUrl: './report-remotework.component.html',
  styleUrls: ['./report-remotework.component.css']
})
export class ReportRemoteworkComponent extends BaseCrudComponent {

    translations:any;
    momentjs: any = moment;
    
    from = null;
    to = null;
    status = '';
    cb_status = [];
    total = 0;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public _login: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'admin/remotework/report/list';
        this.create_url = '';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.clearData();
                //this.getList();
            }
        });
    }

    public clearData(){
        this.rows = [];
        this.total = 0;
    }

    public clearFilters(){
        this.search = '';
        this.from = null;
        this.to = null;
        this.status = '';
    }

    ngOnInit() {
        //this.f_datefrom = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');

        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;

              super.ngOnInit();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};
        this.clearData();

        if( (this.from == null) || (this.to === null) ){
          Swal.fire('Debe seleccionar fecha desde y hasta', '', 'error');
          return;
        }

        let m_from = this.momentjs(this.from);
        let m_to = this.momentjs(this.to);

        if(m_from.isAfter(m_to)){
          Swal.fire('Fecha hasta debe ser mayor a fecha desde', '', 'error');
          return;
        }

        tosend['from'] = this.from;
        tosend['to'] = this.to;
        //tosend['status'] = this.status;
        tosend['name'] = this.search;
        
        this.loadingList = true;
        this.srv.postCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rows = x.data.rows;
                this.total = x.data.total;
            }
        });
    }

    public showFile(id:any){
      var url = 'remotework/img/' + id;
          //window.open(url);
  
          this.srv.getBlobCall(url).subscribe((resp:any) => {
          //this.isCreating = false;
          var myurl = URL.createObjectURL(resp);
          //console.log(resp);
          window.open(myurl);
        });
    }

    public download(){
        var url = this.read_url;
        var tosend = {};

        if( (this.from == null) || (this.to === null) ){
          Swal.fire('Debe seleccionar fecha desde y hasta', '', 'error');
          return;
        }

        let m_from = this.momentjs(this.from);
        let m_to = this.momentjs(this.to);

        if(m_from.isAfter(m_to)){
          Swal.fire('Fecha hasta debe ser mayor a fecha desde', '', 'error');
          return;
        }

        tosend['from'] = this.from;
        tosend['to'] = this.to;
        //tosend['status'] = this.status;
        tosend['name'] = this.search;
        tosend['export'] = 1;
        
        this.isDownloading = true;
        this.srv.postBlobCall(url, tosend).subscribe((resp:any) => {
            this.isDownloading = false;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

}
