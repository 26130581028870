import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gpconfig',
  templateUrl: './gpconfig.component.html',
  styleUrls: ['./gpconfig.component.css']
})
export class GpconfigComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
