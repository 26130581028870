import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-report-monthly',
	templateUrl: './report-monthly.component.html',
	styleUrls: ['./report-monthly.component.css']
})
export class ReportMonthlyComponent extends BaseCrudComponent {


	translations: any;
	momentjs: any = moment;
	files: any;
	teamid: any;
	teamsubscription: Subscription;
	categories: any;
	weeks: any;
	cell_count: number;
	cat_count: number;

	cell_count_arr = [];

	constructor(
		srv: ApiService,
		modalService: NgbModal,
		storage: Storage,
		router: Router,
		private route: ActivatedRoute,
		public translate: TranslateService,
		public _login: LoginService
	) {
		super(srv, modalService, storage, router);

		this.read_url = 'monthlyreport/list';
		this.create_url = '';
		this.update_url = '';
		this.delete_url = '';
		this.search_fields = [];
		this.categories = [];
		this.weeks = [];
		this.cell_count = 0;
		this.cat_count = 0;

		this.reload.subscribe(val => {
			if (val) {
				super.clearData();
				this.getList();
			}
		});

		this.teamsubscription = this.srv.team_changed.subscribe(val => {
			if (val) {
				this.getList();
			}
		});
	}

	ngOnInit() {
		this.f_datefrom = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');
		this.f_dateto = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');

		this.translate.getTranslation(this.translate.currentLang)
			.subscribe((translations) => {
				this.translations = translations;
				super.ngOnInit();
				this.getList();
			});
	}

	downloadOption(option: string) {
		if (option === 'resumido') {
			this.downloadResume();
		} else if (option === 'detallado') {
			this.downloadDetail();
		}
	}

	downloadDetail() {
		const exportData = this.rows.map(row => {
			const rowData = {
				'Código': row.ucode,
				'Nombre': row.name,
			};

			this.weeks.forEach(week => {
				week.days.forEach(day => {
					this.categories.forEach(category => {
						const dayValue = this.getDayValue(row.weeks['w' + week.id], day.id, 'c' + category.id);
						rowData[` ${week.name} / Día ${day.name} / ${category.name}`] = dayValue;
					});
				});

				this.categories.forEach(category => {
					const totalValue = row.weeks['w' + week.id]['totals']['c' + category.id];
					rowData[` ${week.name} / Total / ${category.name}`] = totalValue;
				});

				this.categories.forEach(category => {
					const totalUsdValue = row.weeks['w' + week.id]['totals_usd']['c' + category.id];
					rowData[` ${week.name} / Total USD / ${category.name}`] = totalUsdValue;
				});
			});

			rowData['Subtotal'] = Number(row.totals_usd['subtotal']);
			rowData['Subsidio'] = Number(row.totals_usd['dsct']);
			rowData['Total'] = Number(row.totals_usd['total']);

			return rowData;
		});

		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_json(worksheet, exportData, { origin: 'A4', skipHeader: true });

		const originalHeaders = Object.keys(exportData[0]);

		const weekRow: any[] = ['', ''];
		const dayRow: any[] = ['', ''];
		let columnIndex = 2;

		this.weeks.forEach(week => {
			const weekStart = columnIndex;
			week.days.forEach(day => {
				const dayStart = columnIndex;
				this.categories.forEach(category => {
					dayRow.push(day.name);
					columnIndex++;
				});

				worksheet['!merges'] = worksheet['!merges'] || [];
				worksheet['!merges'].push({
					s: { r: 1, c: dayStart },
					e: { r: 1, c: columnIndex - 1 }
				});
			});

			const totalStart = columnIndex;
			this.categories.forEach(() => {
				dayRow.push('TOTAL');
				columnIndex++;
			});

			worksheet['!merges'].push({
				s: { r: 1, c: totalStart },
				e: { r: 1, c: columnIndex - 1 }
			});

			const totalUsdStart = columnIndex;
			this.categories.forEach(() => {
				dayRow.push('TOTAL USD');
				columnIndex++;
			});

			worksheet['!merges'].push({
				s: { r: 1, c: totalUsdStart },
				e: { r: 1, c: columnIndex - 1 }
			});

			weekRow.push(week.name);
			for (let i = weekStart + 1; i < columnIndex; i++) {
				weekRow.push('');
			}

			worksheet['!merges'].push({
				s: { r: 0, c: weekStart },
				e: { r: 0, c: columnIndex - 1 }
			});
		});
		// Añadir las últimas tres columnas a las filas de semana y día
		['Subtotal', 'Subsidio', 'Total'].forEach(() => {
			weekRow.push('');
			dayRow.push('TOTALES');
		});

		// Añadir merge para las últimas tres columnas de la segunda fila
		const totalStart = columnIndex; // Guardar el índice de la primera columna de los totales
		worksheet['!merges'].push({
			s: { r: 1, c: totalStart },  // Iniciar el merge en la segunda fila, columna de subtotales
			e: { r: 1, c: totalStart + 2 }  // Agrupar las tres últimas columnas
		});

		// Asegurar el texto "TOTALES" en las celdas combinadas
		const cellAddressForTotales = XLSX.utils.encode_cell({ r: 1, c: totalStart });
		worksheet[cellAddressForTotales] = {
			v: 'TOTALES', t: 's', s: {
				alignment: { horizontal: 'center', vertical: 'center' },
				font: { bold: true }
			}
		};


		// Asegúrate de que el estilo de las celdas combinadas se aplique correctamente
		worksheet['!merges'].forEach(merge => {
			if (merge.s.r === 1 && merge.s.c === totalStart) {
				const cellAddress = XLSX.utils.encode_cell(merge.s);
				worksheet[cellAddress].s = {
					alignment: { horizontal: 'center', vertical: 'center' },
					font: { bold: true }  // Aplica negrita a la celda combinada
				};
			}
		});


		// Asegurarte de que el texto se muestre en las celdas combinadas
		worksheet['!merges'].forEach(merge => {
			const cellAddress = XLSX.utils.encode_cell(merge.s);

			if (!worksheet[cellAddress]) {
				worksheet[cellAddress] = { v: '', t: 's' };
			}

			// Asegurar que el texto "TOTALES" se muestre en la celda combinada
			if (merge.s.r === 1 && merge.s.c === totalStart) {
				worksheet[cellAddress].v = 'TOTALES';
			}
		});


		const modifiedHeaders = originalHeaders.map(header => {
			if (header.includes(' / ')) {
				const parts = header.split(' / ');
				return parts[parts.length - 1].substring(0, 2);
			}
			return header;
		});

		XLSX.utils.sheet_add_aoa(worksheet, [weekRow], { origin: 'A1' });
		XLSX.utils.sheet_add_aoa(worksheet, [dayRow], { origin: 'A2' });
		XLSX.utils.sheet_add_aoa(worksheet, [modifiedHeaders], { origin: 'A3' });

		const range = XLSX.utils.decode_range(worksheet['!ref']!);
		range.e.r += 2;
		worksheet['!ref'] = XLSX.utils.encode_range(range);

		function applyRowStyle(worksheet, rowIndex) {
			const range = XLSX.utils.decode_range(worksheet['!ref']);
			for (let col = range.s.c; col <= range.e.c; col++) {
				const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: col });
				console.log(cellAddress);
				if (!worksheet[cellAddress]) continue;

				worksheet[cellAddress].s = {
					alignment: {
						horizontal: 'center',
						vertical: 'center'
					},
					font: { bold: true }
				};
			}
		}

		applyRowStyle(worksheet, 0);
		applyRowStyle(worksheet, 1);
		applyRowStyle(worksheet, 2);

		const workbook: XLSX.WorkBook = {
			Sheets: { 'DatosTabla': worksheet },
			SheetNames: ['DatosTabla']
		};

		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		this.saveAsExcelFile(excelBuffer, 'TablaDatos');
	}


	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
		saveAs(data, `${fileName}_export_${new Date().getTime()}.xlsx`);
	}



	public getList() {
		var qst = this.read_url;
		var tosend = {};

		this._login.getTeam().then(x => {
			this.teamid = x;
			tosend['start'] = this.f_datefrom;
			tosend['end'] = this.f_dateto;
			tosend['search'] = this.search;
			tosend['tid'] = this.teamid;

			this.loadingList = true;
			this.srv.postCall(qst, tosend).subscribe(x => {
				this.loadingList = false;

				if (x.status) {
					this.cell_count = 2

					this.categories = [];
					Object.keys(x.data.categories).forEach(c => {
						let cat = x.data.categories[c + ''];
						this.categories.push({
							id: c,
							name: cat.name,
							price: cat.price
						});
					});

					this.cat_count = this.categories.length;

					this.weeks = [];
					Object.keys(x.data.weeks).forEach(w => {
						let days = [];
						x.data.weeks[w + ''].forEach((d, k) => {
							days.push({
								id: d,
								name: this.momentjs(d).format('dddd DD MMMM')
							});
						});

						this.weeks.push({
							id: w,
							name: 'Semana ' + w,
							days: days,
							day_count: x.data.weeks[w + ''].length,
							colspan: (x.data.weeks[w + ''].length + 2) * this.cat_count
						});

						this.cell_count += x.data.weeks[w + ''].length * this.cat_count;
						//add total groups to cell count
						this.cell_count += 2 * (this.cat_count);
					});
					//console.log(this.weeks);


					//add last 3 TOTAL columns to cell count
					this.cell_count += 3;
					this.cell_count_arr = Array(this.cell_count - 2).fill(1);
					//console.log(this.cell_count);
					//console.log(this.cell_count);

					//this.rows = x.data.rows;
					this.rows = [];
					Object.keys(x.data.rows).forEach(r => {
						this.rows.push(x.data.rows[r + '']);
					});

					//console.log(this.rows);
				}
			});
		});
	}

	public getDayValue(obj: any, day: any, cat: any) {
		let val = 0;

		if ((obj[day] != undefined) && (obj[day][cat] != undefined)) {
			val = obj[day][cat];
		}

		return val;
	}

	public downloadResume() {
		var url = this.read_url;
		var tosend = {};

		this._login.getTeam().then(x => {
			this.teamid = x;
			tosend['start'] = this.f_datefrom;
			tosend['end'] = this.f_dateto;
			tosend['search'] = this.search;
			tosend['tid'] = this.teamid;
			tosend['export'] = 1;

			this.isDownloading = true;
			this.srv.postBlobCall(url, tosend).subscribe(resp => {
				this.isDownloading = false;
				var myurl = URL.createObjectURL(resp);
				//console.log(resp);
				window.open(myurl);
			});
		});
	}

}
