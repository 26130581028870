import { Component, OnInit, Input, ChangeDetectionStrategy, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { ApiService } from '../../../services/main/api.service';
import { LoginService } from '../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat, Subject, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  isAfter,
  isBefore,
  addHours,
  format,
  parse,
  setHours,
  setMinutes,
  getHours,
  getMinutes
} from 'date-fns';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewBeforeRenderEvent
} from 'angular-calendar';


@Component({
  selector: 'app-mealbooking',
  templateUrl: './mealbooking.component.html',
  styleUrls: ['./mealbooking.component.css']
})

export class MealbookingComponent extends BaseCrudComponent {
	@ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
	@ViewChild('create1', { static: true }) create1: TemplateRef<any>;

  	view = 'month';

  	viewDate: Date = new Date();

  	modalData: {
    	action: string;
    	event: CalendarEvent;
  	};

  	actions: CalendarEventAction[] = [
	    /*{
	      label: '<i class="fa fa-fw fa-pencil text-white"></i>',
	      onClick: ({ event }: { event: CalendarEvent }): void => {
	        this.handleEvent('Edited', event);
	      }
	    },*/
	    {
	      	label: '<i class="fa fa-fw fa-times  text-white"></i>',
	      	onClick: ({ event }: { event: CalendarEvent }): void => {
		      	let today = new Date();
		      	
		        if( !isSameDay(event.start, today) && isBefore(event.start, today) ){
		        	Swal.fire('No se pueden eliminar menus pasados', '', 'error');
		        	return;
		        }
		        else{
		        	this.removeBooking(event.id);
		        }
	      	}
	    }
	];

  	refresh: Subject<any> = new Subject();

	//events: CalendarEvent[] = [];
	events: any = [];
	//events: Observable<any[]>;

  	activeDayIsOpen = false;

  	translations:any;
    loadingRequired:any;
    teamid:any;

    bookings:any;
    meals:any;
    sysparams:any;
    teamsubscription: Subscription;
    reloadSub: Subscription;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public _login: LoginService
      ) {

      	super(srv, modalService, storage, router);
        this.loadingList = false;
        this.loadingRequired = false;

        this.bookings = [];
        this.meals = [];
        this.sysparams = {};

        this.reloadSub = this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });

        this.teamsubscription = this.srv.team_changed.subscribe(val => {
        	if( val ){
        		//console.log('team changed | updating');
        		this.updateCalendar();
        	}
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });

        this._login.getSystemInfo().then(x => {
        	this.sysparams = x;
        });
    }

    public getList(){
    	this.updateCalendar();
    }

    public getMealsData(teamid:any, start:any){
        var qst = 'meal/list';
        var tosend = {};

        this.loadingList = true;
        //this.srv.postCall(qst, tosend).subscribe(x => {
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            this.meals = [];
            
            if(x.status){
                //console.log(x.data.rows);
                //var nrows = x.data.rows.data;
                var nrows = x.data.rows;
                this.meals = nrows;
            }
        });
    }

    public getBookingData(teamid:any, start:any){
        var par_url = 'mealbooking/list';
        var tosend = {
        	start: start,
        	all: 1,
        	tid: teamid
        };

        return this.srv.getCall(par_url, tosend).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'amount': new FormControl(fields.amount, [Validators.required])
        });

        return mygroup.valid;
    }

    //calendar actions
    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }){
	    /*console.log(events);
	    console.log(date);*/
	    this.c_obj = {};
        var seldate = date;
        var seldate_str = format(date, "YYYY-MM-DD");
        var today = new Date();
        

        this.updateDayMeals(seldate_str);

	    this.c_obj.seldate = seldate;
	    this.c_obj.seldate_str = seldate_str;

	    if( this.c_obj.meals.length > 0 ){
            /*console.log(today_limit);
            console.log(seldate);*/
            if( !isSameDay(seldate, today) && isBefore(seldate, today) ){
            	if( (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0 ) {
			        this.activeDayIsOpen = false;
			    }
			    else {
			        this.activeDayIsOpen = true;
			        this.viewDate = date;
			    }
            	return;
            }
            
            this.c_obj.start = seldate_str;
            this.activeDayIsOpen = false;
            super.openCreateModal(this.create1);
        }
	}

	updateDayMeals(dstart:any){
		var curr = this.c_obj;

        curr.meals = [];
        curr.rawmeals = [];

        this.meals.forEach(m => {
        	curr.rawmeals.push(m);
        });

        curr.rawmeals.forEach(r => {
        	let tmp = this.bookings.filter(e => {
        		let dt = format(e.start, "YYYY-MM-DD");
	            return (dt == dstart) && (e.meal_id == r.id);
	        });

	        if( tmp.length > 0 ){
	        	r.selected = true;
	        }
	        else{
	        	r.selected = false;
	        }
        });

        /*console.log(curr.rawmeals);
        console.log(this.bookings);*/

        curr.meals = curr.rawmeals;
    }

    checkItem(obj:any){
    	obj.selected = !obj.selected;

        if( obj.selected ){
            obj.qty = 1;
        }
        else{
            obj.qty = 0;
        }
    }

	eventTimesChanged({
	    event,
	    newStart,
	    newEnd
	  }: CalendarEventTimesChangedEvent){
	  //}: CalendarEventTimesChangedEvent): void {
	    event.start = newStart;
	    event.end = newEnd;
	    this.handleEvent('Dropped or resized', event);
	    this.refresh.next();
	}

  	//handleEvent(action: string, event: CalendarEvent): void {
  	handleEvent(action: string, event: CalendarEvent){
  		//console.log(event);
  		var date = event.start;
		if( action == 'Clicked' ){
			if( (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ) {
		        this.activeDayIsOpen = false;
		    }
		    else {
		        this.activeDayIsOpen = true;
		        this.viewDate = date;
		    }
		}
  	}

	updateCalendar(){
		this._login.getTeam().then(x => {
	        this.teamid = x;
	        var refdate = format(this.viewDate, "YYYY-MM-DD");
	        this.clearData();

	        this.loadingRequired = true;

		    this.getBookingData(this.teamid, refdate).subscribe(x => {
		    	this.loadingRequired = false;

		        if(x != false){
		            this.bookings = [];
		            var nrows = x;
            
		            nrows.forEach((val,key) => {
		                var stock = (val.stock == undefined) ? 'Si' : ((val.stock == 0) ? 'Si' : val.stock);
	                    var name = val.meal.name + ' ( ' + stock + ' )';
	                    this.bookings.push({
	                    	title: name,
	                    	start: parse(val.due_date),
	                    	color: {primary: val.meal.category.color, secondary: val.meal.category.color},
	                    	id: val.id,
	                    	meal_id: val.meal_id,
	                    	actions: this.actions
	                    });
		            });

		            this.events = this.bookings;
		            this.refresh.next();
		        }
		    });

		    this.getMealsData(this.teamid, refdate);
	    });
	}

	handleDateChange(){
	  	//console.log(this.viewDate);
	  	this.getList();
	}

	beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent){
	    renderEvent.body.forEach((day) => {
	      	const dayOfMonth = format(day.date, "YYYY-MM-DD");
	      	
	      	let checker = this.bookings.filter(b => {
	      		return format(b.start, "YYYY-MM-DD") == dayOfMonth;
	      	});

	      	if(checker.length > 0){
	      		day.cssClass = 'bg-booked';
	      	}
		    
	    });
	}

	ngOnDestroy() {
		//console.log('unsubscribing');
        this.reloadSub.unsubscribe();
        this.teamsubscription.unsubscribe();
    }

    clearData(){
    	this.bookings = [];
    	this.events = [];
    	this.meals = [];
    	this.search = '';
    }

    filterMeals(){
    	if( this.search == '' ){
            this.c_obj.meals = this.c_obj.rawmeals;
        }
        else{
            let test = this.c_obj.rawmeals.filter(s => {
                return String(s['name']).toLowerCase().includes(this.search.toLowerCase());
            });

            this.c_obj.meals = test;
        }
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        //validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        if( (form.meals == undefined) || (!Array.isArray(form.meals)) ){
        	Swal.fire('Data invalida', '', 'error');
            return;
        }

        var to:any;
        to = {};
        
        var rows = [];
        form.meals.forEach(( val , key ) => {
            if( val.selected ){
                rows.push({id: val.id, stock: val.stock});
            }
        });

        if( (rows.length <= 0) ){
            Swal.fire('Seleccione al menos 1 plato', '', 'error');return;
        }

        to.start = form.start;
        to.tid = this.teamid;
        to.meal_ids = rows;

        if( form.id != -1 ){
            to.id = form.id;
        }

        /*if( form.uid != '' ){
            to.uid = form.uid;
        }*/

        var qst = 'mealbooking/new';
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.updated, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public removeBooking(id) {
    	var obj = {};
        var qst = 'mealbooking/del/' + id;

        Swal.fire({
            title: '¿Esta seguro que desea eliminarlo?',
            text: `Si acepta se eliminará el registro`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar'
        }).then((result) => {
            if (result.value) {
                this.srv.postCall(qst, obj).subscribe(x => {
                    if(x.status){
                    	this.activeDayIsOpen = false;
                        this.reload.next(true);
                        Swal.fire('¡Eliminado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            }
        });
    }

}
