import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'user-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {
	@Input() rows: any;
	
	constructor() { }

	ngOnInit() {
	}
}
