import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoginService } from '../../../services/authentication/login.service';
import { ApiService } from '../../../services/main/api.service';
declare var $: any;

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  permissions = [];

  constructor(
      public router: Router,
      public srv: LoginService,
      public apisrv: ApiService
    )
  {
      apisrv.team_changed.subscribe(val => {
          if( val ){
              //console.log('team changed');
              this.router.navigate(['/home']);
          }
      });
  }

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;
  /*public expandLogo = false;*/

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'mini-sidebar', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
      if (this.router.url === '/') {
        //this.router.navigate(['/dashboard/classic']);
      }
      this.defaultSidebar = this.options.sidebartype;
      this.handleSidebar();

      this.router.events.subscribe((val) => {
          // see also 
          //console.log(val instanceof NavigationEnd);
          if( val instanceof NavigationEnd ){
              this.showMobileMenu = false;
          }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.handleSidebar();
  }

  handleSidebar() {
      this.innerWidth = window.innerWidth;
      //console.log(this.defaultSidebar);
      switch (this.defaultSidebar) {
        case 'full':
        case 'iconbar':
          //if (this.innerWidth < 1170) {
          if (this.innerWidth < 768) {
            this.expandLogo = true;
            this.options.sidebartype = 'mini-sidebar';
          }
          //else if (this.innerWidth < 767) {
          else if (this.innerWidth < 1170) {
            this.expandLogo = false;
            this.options.sidebartype = 'mini-sidebar';
          } 
          else {
            this.expandLogo = true;
            this.options.sidebartype = this.defaultSidebar;
          }
        break;

        case 'overlay':
          this.expandLogo = true;
          if (this.innerWidth < 767) {
            this.options.sidebartype = 'mini-sidebar';
          } else {
            this.options.sidebartype = this.defaultSidebar;
          }
        break;

        default:
      }
      //console.log(this.options.sidebartype);
  }

  toggleSidebarType() {
    this.Logo();
    console.log('cool');
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }
  
}
