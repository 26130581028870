import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { LoginService } from '../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent extends BaseCrudComponent {

  	translations:any;
    files:any;
    filetypes:any;
    img_exts:any;
    api_url:any;
    file_url:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService,
        private _lightbox: Lightbox
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'admin/banner';
        this.create_url = 'admin/banner';
        this.delete_url = 'admin/banner/del';
        this.search_fields = ['id', 'fname', 'desc', 'ext'];

        this.files = [];
        this.filetypes = [];

        this.img_exts = ['png', 'jpg', 'jpeg'];
        this.api_url = Global.url;
        this.file_url = this.api_url + 'banner/';

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    openCreateModal(content:any){
        this.c_obj = {};
        this.c_obj.img = '';
        this.files = [];

        super.openCreateModal(content);
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            /*'desc': new FormControl(fields.desc, [Validators.required]),
            'order_num': new FormControl(fields.order_num, [Validators.required])*/
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        if( this.files.length == 0 ){
        	Swal.fire('No archivo seleccionado', '', 'error');
            return;
        }

        const file = this.files[0];
		const formData = new FormData();
    	formData.append('file', file);
    	

        if( form.desc != undefined ){
        	formData.append('desc', form.desc);
        }

        if( form.order_num != undefined ){
        	formData.append('order_num', form.order_num);
        }

        var qst = this.create_url;
        this.isCreating = true;

    	this.srv.customPostCall(qst, formData).subscribe(x => {
            this.isCreating = false;

            if(x.status){
            	this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    handleInputChange(e, obj:any) {
	    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
	    //console.log(file);
	    this.files = [];
	    this.files.push(file);
	    //var pattern = /image-*/;
	    var reader = new FileReader();

	    reader.onload = (_event) => { 
	      obj.img = reader.result;
	      //obj.img[0].fname = file.name;
	    }

	    reader.readAsDataURL(file);
	}

	public showFile(id:any){
		var url = 'event/doc/' + id;
        //window.open(url);

        this.srv.getBlobCall(url).subscribe(resp => {
    		//this.isCreating = false;
    		var myurl = URL.createObjectURL(resp);
    		//console.log(resp);
    		window.open(myurl);
    	});
	}

    removeFile(obj:any){
    	var i = 0;
    	var count = 0;

    	this.files.forEach(x => {
    		if( x.name == obj.name ){
    			i = count;
    		}

    		count++;
    	});

    	this.files.splice(i, 1);
    }

    public deleteFile(obj: any) {
        var qst = 'admin/banner/del/' + obj.id;
        
        Swal.fire({
            title: this.translations.delete_item_title,
            text: '',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText: this.translations.cancel,
            confirmButtonText: this.translations.delete,
            preConfirm: () => {
                this.srv.postCall(qst, {}).subscribe(x => {
                    let msg = x.msg;

                    if(x.status){
                    	this.getList();
                        Swal.fire(this.translations.deleted, msg, 'success');
                    }
                    else{
                        Swal.fire(msg, '', 'error');
                    }
                });
            }
        }).then((result) => {
        });
    }

    openPreview(obj:any): void {
        var album:any;

        album = [
            {src: this.file_url + obj.id}
        ];
        
        // open lightbox
        this._lightbox.open(album, 0);
    }

}
