import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/authentication/login.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.css']
})
export class AdminsComponent implements OnInit {

  	menu:any;
  	permissions = [];

	constructor(public auth: LoginService) { }

	ngOnInit() {
		this.menu = [
			{name:'Usuarios', desc:'Mantenimiento de usuarios', icon:'fas fa-users', url:'/users', perm: 'ADMINS-USUARIOS'},
			{name:'Roles', desc:'Mantenimiento de roles', icon:'fas fa-key', url:'/roles', perm: 'ADMINS-ROLES'},
			{name:'Sucursales', desc:'Mantenimiento de sucursales', icon:'fas fa-map-marker-alt', url:'/branches', perm: 'ADMINS-SUCURSALES'},
			{name:'Esquema de aprobación', desc:'Esquema de aprobaciones', icon:'fas fa-tasks', url:'/docapproval', perm: 'ADMINS-ESQUEMA_APROBACION_DOCUMENTOS'},
			{name:'Comunicados', desc:'Mantenimiento de comunicados', icon:'fas fa-bell', url:'/events', perm: 'ADMINS-COMUNICADOS'},
			{name:'Aprobar Permisos', desc:'Aprobación de permisos', icon:'fas fa-hospital-user', url:'/absence_approval', perm: 'ADMINS-APROBACION_PERMISOS'},
			{name:'Consultar Permisos', desc:'Consulta de permisos', icon:'fas fa-hospital-user', url:'/absence_query', perm: 'ADMINS-CONSULTA_PERMISOS'},
			{name:'Aprobar Anticipos', desc:'Aprobación de anticipos', icon:'fas fa-money-bill-alt', url:'/advance_approval', perm: 'ADMINS-APROBACION_ANTICIPOS'},
			{name:'Consultar Anticipos', desc:'Consultar anticipos', icon:'fas fa-money-bill-alt', url:'/advance_query', perm: 'ADMINS-CONSULTA_ANTICIPOS'},
			{name:'Consultar Anticipos Ext.', desc:'Consultar anticipos extraordinarios', icon:'fas fa-money-bill-alt', url:'/advanceext_query', perm: 'ADMINS-CONSULTA_ANTICIPOS_EXTRA'},
			{name:'Aprobar Vacaciones', desc:'Aprobación de vacaciones', icon:'fas fa-umbrella-beach', url:'/vacation_approval', perm: 'ADMINS-APROBACION_VACACIONES'},
			{name:'Aprobar Acumulación Beneficios', desc:'Aprobar Acumulación Beneficios', icon:'fas fa-hospital-user', url:'/benefit-req-approval', perm: 'ADMINS-APROBACION_VACACIONES'},
			{name:'Consulta Vacaciones', desc:'Consulta de vacaciones', icon:'fas fa-umbrella-beach', url:'/vacation_query', perm: 'ADMINS-CONSULTA_VACACIONES'},
			{name:'Aprobar Prestamos', desc:'Aprobación de prestamos', icon:'fas fa-hand-holding-usd', url:'/loan_approval', perm: 'ADMINS-APROBACION_PRESTAMOS'},
			{name:'Consultar Prestamos', desc:'Consulta de prestamos', icon:'fas fa-hand-holding-usd', url:'/loan_query', perm: 'ADMINS-CONSULTA_PRESTAMOS'},
			{name:'Mis Colaboradores', desc:'Consulta de mi personal', icon:'fas fa-users', url:'/my_collabs', perm: 'ADMINS-MIS_COLABORADORES'},
			//{name:'Viáticos', desc:'Aprobación de viáticos', icon:'fas fa-coins', url:'', perm: 'ADMINS-APROBACION_VIATICOS'},
			{name:'Nuevo personal', desc:'Requerimiento de personal', icon:'fas fa-people-arrows', url:'/staff', perm: 'ADMINS-REQUERIMIENTO_PERSONAL'},
			{name:'Aprobar Nuevo personal', desc:'Requerimiento de personal', icon:'fas fa-people-arrows', url:'/staffapproval', perm: 'ADMINS-REQUERIMIENTO_PERSONAL_APROBACION'},
			{name:'Roles de pago', desc:'Mantenimiento de roles de pago', icon:'fas fa-money-check-alt', url:'/payroladmin', perm: 'ADMINS-ROLES_PAGO'},
			{name:'Consulta Roles de pago', desc:'Consulta de roles de pago', icon:'fas fa-money-check-alt', url:'/payrol_query', perm: 'ADMINS-CONSULTA_ROLES_PAGO'},
			{name:'Consulta Nóminas Especiales', desc:'Consulta de nóminas especiales', icon:'fas fa-money-check-alt', url:'/payrolspecial_query', perm: 'ADMINS-CONSULTA_NOMINAS_ESPECIALES'},
			{name:'Dscto Utilidades', desc:'Mantenimiento de Descuento de Utilidades', icon:'fas fa-money-check-alt', url:'/profitadmin', perm: 'ADMINS-ROLES_PAGO'},
			{name:'Menú Alimentación', desc:'Menú Alimentación', icon:'fas fa-utensils', url:'/mealhome', perm: 'ADMINS-ALIMENTACION'},
			{name:'Menú Cocina', desc:'Opciones para cocina', icon:'fas fa-user-ninja', url:'/kitchen', perm: 'ADMINS-COCINA'},
			{name:'Encuestas', desc:'Mantenimiento de encuestas', icon:'fas fa-poll', url:'/polls', perm: 'ADMINS-ENCUESTAS'},
			{name:'Banners', desc:'Mantenimiento de banners', icon:'far fa-images', url:'/banners', perm: 'ADMINS-BANNERS'},
			{name:'Comisiones', desc:'Mantenimiento de rol de comisiones', icon:'fas fa-coins', url:'/commissions', perm: 'ADMINS-COMISIONES'},
			{name:'Subsidios', desc:'Mantenimiento Subsidios', icon:'fas fa-percent', url:'/subsidies', perm: 'ADMINS-SUBSIDIOS'},
			{name:'Cargas familiares', desc:'Aprobación de cargas', icon:'fas fa-baby', url:'/dependant_approval', perm: 'ADMINS-APROBACION_CARGAS_FAMILIARES'},
			{name:'Reporte Colaboradores', desc:'Reporte Colaboradores', icon:'fas fa-id-badge', url:'/employee_report', perm: 'ADMINS-REPORTE_EMPLEADOS'},
			{name:'Reportes', desc:'Reportes varios', icon:'fas fa-file-alt', url:'/reports', perm: 'ADMINS-REPORTES'},
			{name:'Configuración del Sistema', desc:'Parámetros generales del sistema', icon:'fas fa-cog', url:'/settings', perm: 'ADMINS-SETTINGS'},
			{name:'Modalidad Híbrida y Flexible', desc:'Modalidad Híbrida y Flexible', icon:'fas fa-laptop-house', url:'/flexhybridmode', perm: 'ADMINS-REMOTE'},
			{name:'SRI', desc:'Opciones SRI', icon:'fas fa-file-alt', url:'/sri-admin', perm: 'ADMINS-SRI'},
		];

		this.auth.getPermissions().then(x => {
            this.permissions = x;
            //console.log(this.permissions);
        });
	}

	hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }

}
