import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-advanceapprove',
  templateUrl: './advanceapprove.component.html',
  styleUrls: ['./advanceapprove.component.css']
})
export class AdvanceapproveComponent extends BaseCrudComponent {

  	translations:any;
    loadingRequired:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'admin/advance';
        this.create_url = 'admin/advance';
        this.update_url = 'admin/advance';
        this.delete_url = '';
        this.search_fields = [];
        this.loadingRequired = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        this.loadingList = true;
        //this.srv.postCall(qst, tosend).subscribe(x => {
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                    /*this.rows.forEach(x => {
                    	//used for search bar
                    	x.doctype_str = x.doctype.description;
                    	x.userto_str = x.userto.name;
                    	x.responsible_str = x.responsible.name;
                    });*/
                }

                this.filterAll();
            }
        });
    }

    public approve(id:any) {
        var qst = 'admin/advance/approve/' + id;

        Swal.fire({
            title: '¿Aprobar anticipo?',
            text: '',
            icon: 'success',
            showCancelButton: true,
            /*confirmButtonColor: '#f39000',
            cancelButtonColor: '#80a41b',*/
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.value) {
                this.srv.postCall(qst, {}).subscribe(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Aprobado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            }
        });
    }

    public reject(id:any) {
        var qst = 'admin/advance/reject/' + id;

        Swal.fire({
            title: '¿Rechazar anticipo?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            /*confirmButtonColor: '#f39000',
            cancelButtonColor: '#80a41b',*/
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.value) {
                this.srv.postCall(qst, {}).subscribe(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Rechazado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            }
        });
    }

}
