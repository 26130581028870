import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-report-monthly',
  templateUrl: './report-monthly.component.html',
  styleUrls: ['./report-monthly.component.css']
})
export class ReportMonthlyComponent extends BaseCrudComponent {

  	translations:any;
    momentjs: any = moment;
    files:any;
    teamid:any;
    teamsubscription: Subscription;
    categories:any;
    weeks:any;
    cell_count:number;
    cat_count:number;

    cell_count_arr = [];

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public _login: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'monthlyreport/list';
        this.create_url = '';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = [];
        this.categories = [];
        this.weeks = [];
        this.cell_count = 0;
        this.cat_count = 0;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });

        this.teamsubscription = this.srv.team_changed.subscribe(val => {
        	if( val ){
        		this.getList();
        	}
        });
    }

    ngOnInit() {
        this.f_datefrom = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');
        this.f_dateto = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');

        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        this._login.getTeam().then(x => {
	        this.teamid = x;
	        tosend['start'] = this.f_datefrom;
	        tosend['end'] = this.f_dateto;
	        tosend['search'] = this.search;
	        tosend['tid'] = this.teamid;
	        
	        this.loadingList = true;
	        this.srv.postCall(qst, tosend).subscribe(x => {
	            this.loadingList = false;
	            
	            if(x.status){
	                this.cell_count = 2

	                this.categories = [];
	                Object.keys(x.data.categories).forEach(c => {
	                	let cat = x.data.categories[c+''];
	                	this.categories.push({
	                		id: c,
	                		name: cat.name,
	                		price: cat.price
	                	});
	                });

	                this.cat_count = this.categories.length;
	                
	                this.weeks = [];
	                Object.keys(x.data.weeks).forEach(w => {
	                	let days = [];
	                	x.data.weeks[w+''].forEach((d,k) => {
	                		days.push({
	                			id:d,
	                			name: this.momentjs(d).format('dddd DD MMMM')
	                		});
	                	});
	                	
	                	this.weeks.push({
	                		id: w,
	                		name: 'Semana ' + w,
	                		days: days,
	                		day_count: x.data.weeks[w+''].length,
	                		colspan: (x.data.weeks[w+''].length + 2) * this.cat_count
	                	});

	                	this.cell_count += x.data.weeks[w+''].length * this.cat_count;
	                	//add total groups to cell count
	                	this.cell_count += 2 * (this.cat_count);
	                });
	                //console.log(this.weeks);
	                

	                //add last 3 TOTAL columns to cell count
	                this.cell_count += 3;
	                this.cell_count_arr = Array(this.cell_count - 2).fill(1);
	                //console.log(this.cell_count);
	                //console.log(this.cell_count);

	                //this.rows = x.data.rows;
	                this.rows = [];
	                Object.keys(x.data.rows).forEach(r => {
	                	this.rows.push(x.data.rows[r+'']);
	                });

	                //console.log(this.rows);
	            }
	        });
	    });
    }

    public getDayValue(obj:any, day:any, cat:any){
    	let val = 0;

    	if( (obj[day] != undefined) && (obj[day][cat] != undefined) ){
    		val = obj[day][cat];
    	}

    	return val;
    }

    public download(){
		var url = this.read_url;
        var tosend = {};

        this._login.getTeam().then(x => {
	        this.teamid = x;
	        tosend['start'] = this.f_datefrom;
	        tosend['end'] = this.f_dateto;
	        tosend['search'] = this.search;
	        tosend['tid'] = this.teamid;
	        tosend['export'] = 1;
	        
	        this.isDownloading = true;
	        this.srv.postBlobCall(url, tosend).subscribe(resp => {
	            this.isDownloading = false;
	            var myurl = URL.createObjectURL(resp);
	            //console.log(resp);
	            window.open(myurl);
	        });
	    });
    }

}
