import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { LoginService } from '../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-payrolquery',
  templateUrl: './payrolquery.component.html',
  styleUrls: ['./payrolquery.component.css']
})
export class PayrolqueryComponent extends BaseCrudComponent {

  	info:any;
	currdate:any;
    translations:any;
    download_url:any;

    listApprovers:any;
    sudo:any;
    showSudo:any;

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'payrol/list';
        this.download_url = 'payrol/list';
        this.update_url = '';

        this.info = false;
        this.currdate = '';

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

            this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    this.getApproversData();
                }
            });
        });
    }

    getListPost(){
        var qst = this.read_url;
        var from = '';
        var tosend = {};

        if( this.currdate == '' ){
        	Swal.fire('Debe seleccionar fecha', '', 'error');
        	return;
        }
        else{
        	from = this.currdate + '-01';
            tosend['start'] = from;
        }

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        this.info = false;

        this.srv.postCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if( x.status && (x.data['fields'] != undefined) && (x.data['fields']['name'] != undefined) ){
                this.info = x.data.fields;
            }
        });
    }

    public download(){
        var url = this.download_url;
        var from = '';
        var tosend = {download: 1};

        if( this.currdate == '' ){
            Swal.fire('Debe seleccionar fecha', '', 'error');
            return;
        }
        else{
            from = this.currdate + '-01';
            tosend['start'] = from;
        }

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.isDownloading = true;

        this.srv.postBlobCall(url, tosend).subscribe(resp => {
            this.isDownloading = false;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

}
