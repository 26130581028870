import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'user-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent extends BaseCrudComponent {
  	@Input() profile: any;

    translations:any;
    files:any;
    filetypes:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'employee/doc';
        this.create_url = 'employee/doc';
        this.update_url = 'employee/doc';
        this.delete_url = 'employee/doc/del';
        this.search_fields = [];
        this.files = [];
        this.filetypes = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              
              this.storage.get('common').then(c => {
              		this.filetypes = c['data']['file'];
              });

              super.ngOnInit();
              this.getList();
        });
    }

    openCreateModal(content:any){
        this.c_obj = {};
        super.openCreateModal(content);
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'name': new FormControl(fields.name, [Validators.required]),
            'doc_id': new FormControl(fields.doc_id, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;
        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        if( this.files.length <= 0 ){
        	Swal.fire(this.translations['no_file_selected'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        const file = this.files[0];
		const formData = new FormData();
    	formData.append('file', file);
    	formData.append('doc_id', form.doc_id);
    	formData.append('name', form.name);

    	if( form.desc != undefined ){
    		formData.append('desc', form.desc);
    	}

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.customPostCall(qst, formData).subscribe(resp => {
    		this.isCreating = false;
    		
    		if(resp.status){
    			this.c_obj = {};
    			this.files = [];
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
    		}
    		else{
    			//console.log(resp);
    			Swal.fire(resp.message, '', 'error');
    		}
    	});
    }

    handleInputChange(e, obj:any) {
	    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
	    console.log(file);
	    this.files = [];
	    this.files.push(file);
	    //var pattern = /image-*/;
	    /*var reader = new FileReader();

	    reader.onload = (_event) => { 
	      obj.img[0].src = reader.result;
	      obj.img[0].fname = file.name;
	    }

	    reader.readAsDataURL(file);*/
	}

	public showFile(id:any){
		var url = this.update_url + '/' + id;
        //window.open(url);

        this.srv.getBlobCall(url).subscribe(resp => {
    		//this.isCreating = false;
    		var myurl = URL.createObjectURL(resp);
    		//console.log(resp);
    		window.open(myurl);
    	});
	}

	private getFileTypeStr(id:any){
		var str = '';
		var resp:any;
		resp = this.filetypes.filter(f => {
			return f.id == id;
		})

		if( resp.length > 0 ){
			str = resp[0]['description'];
		}

		return str;
	}

}
