import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.css']
})
export class SitesComponent extends BaseCrudComponent {

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'site/All';
        this.update_url = 'site/Update';

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        super.ngOnInit();
        super.getList();
    }

}
