import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, of, throwError, from } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

//max time for api requests
const MAX_TIME = 60000;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
	url:any;
	public reload_pic = new BehaviorSubject(false);
	public team_changed = new BehaviorSubject(false);

  	constructor(
  		private _router: Router,
  		private http: HttpClient,
  		public storage: Storage
  	) {
  		this.url = Global.url;
  	}

  	public getCall(qst, params?:any) {
		let mydata:any = {message:'', data:[], status: false};
		var extra = '';

        if( params != undefined ){
            Object.keys(params).forEach(k => {
                extra += '&' + k + '=' + params[k];
            });
        }

		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept':  'application/json'});
	    			return this.http.get(this.url+qst+'?token='+token+extra, { headers })
	    				.pipe(
					  		mergeMap(data => {
		                      mydata = data;

		                      if( !mydata.status ){
		                      	  mydata.message = mydata.message.Error.msg;
		                      }

		                      return of(mydata);
		                    }),
		                    timeout(MAX_TIME),
		                    catchError(data => {
		                    	mydata.message = 'Error de conexión';
		                    	return of(mydata);
		                    })
					  	);
				})
			);
	}

	public postCall(qst, obj) {
		let mydata:any = {message:'', data:[], status: false};

		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept':  'application/json'});
					//console.log('before post');
	    			return this.http.post(this.url+qst+'?token='+token, obj, { headers })
	    				.pipe(
					  		mergeMap(data => {
		                      mydata = data;

		                      if( !mydata.status ){
		                      	  mydata.message = mydata.message.Error.msg;
		                      }

		                      return of(mydata);
		                    }),
		                    timeout(MAX_TIME),
		                    catchError(data => {
		                    	mydata.message = 'Error de conexión';
		                    	return of(mydata);
		                    })
					  	);
				})
			);
	}

	public customPostCall(qst, obj) {
		let mydata:any = {message:'', data:[], status: false};

		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept':  'application/json'});
					//console.log('before post');
	    			return this.http.post(this.url+qst+'?token='+token, obj, {})
	    				.pipe(
					  		mergeMap(data => {
		                      mydata = data;

		                      if( !mydata.status ){
		                      	  mydata.message = mydata.message.Error.msg;
		                      }

		                      return of(mydata);
		                    }),
		                    timeout(MAX_TIME),
		                    catchError(data => {
		                    	mydata.message = 'Error de conexión';
		                    	return of(mydata);
		                    })
					  	);
				})
			);
	}

	public persistData(key:string, val:any){
		return this.storage.set(key, val);
	}

	public getBlobCall(qst) {
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
	    			return this.http.get(this.url+qst+'?token='+token, { headers, responseType: 'blob' })
	    				.pipe(
					  		mergeMap(data => {
					  			//console.log(data);
					      		return of(data);
					      		//return of(true);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
					            return of(false);
					        })
					  );
				})
			);
	}

	public postBlobCall(qst, obj:any) {
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
	    			return this.http.post(this.url+qst+'?token='+token, obj, { headers, responseType: 'blob' })
	    				.pipe(
					  		mergeMap(data => {
					  			//console.log(data);
					      		return of(data);
					      		//return of(true);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
					            return of(false);
					        })
					  );
				})
			);
	}

	public externalPostCall(url, obj) {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept':  'application/json'});

		return this.http.post(url, obj, { headers })
	    				.pipe(
					  		mergeMap(data => {
		                      return of(data);
		                    }),
		                    timeout(MAX_TIME),
		                    catchError(data => {
		                    	return of(data);
		                    })
					  	);
	}

}
