import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { LoginService } from '../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import * as moment from 'moment-timezone';

moment.tz.setDefault('America/Guayaquil');

@Component({
  selector: 'app-flexhybrid',
  templateUrl: './flexhybrid.component.html',
  styleUrls: ['./flexhybrid.component.css']
})
export class FlexHybridComponent extends BaseCrudComponent {

    translations: any;
    loadingRequired: any;
    momentjs: any = moment;
    listApprovers: any;
    sudo: any;
    showSudo: any;

    days: any;
    days1: any;
    days2: any;
    schedules: any;
    processes: any;
    requirements: any;
    origins: any;
    reasons: any;
    modalidadHibrido: boolean = false;
    modalidaFlexible: boolean = false;
    isDownloading2 = 0;

    download_url:any;

    constructor(
      srv: ApiService,
      modalService: NgbModal,
      storage: Storage,
      router: Router,
      private route: ActivatedRoute,
      public translate: TranslateService,
      public auth: LoginService
    ) {
      super(srv, modalService, storage, router);

      this.read_url = 'remotework';
      this.create_url = 'remotework';
      this.update_url = 'remotework';
      this.delete_url = 'remotework/del';
      this.download_url = 'remotework/download';
      this.search_fields = ['id', 'position_name', 'status_str'];
      this.loadingRequired = false;

      this.days = [];
      this.days1 = [];
      this.days2 = [];
      this.schedules = [];
      this.processes = [];
      this.requirements = [];
      this.origins = [];
      this.reasons = [];


      this.listApprovers = [];
      this.sudo = null;
      this.showSudo = false;

      this.reload.subscribe(val => {
        if (val) {
          super.clearData();
          this.getList();
        }
      });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
        });
    }

    // Método para controlar la habilitación/deshabilitación de los combos de Día 1 y Día 2
    onHibridoChange() {
        if (!this.modalidadHibrido) {
            this.c_obj['day1_id'] = null;
            this.c_obj['day2_id'] = null;
        }
        else {
            this.days2 = [];
        }
    }

    onDay1Change() {
        const selectedDay1 = this.c_obj['day1_id'];

        // Actualiza days2 para excluir el día seleccionado en day1
        if (selectedDay1 === 'LUNES') {
            this.days2 = this.days.filter((day: any) => day.description !== 'VIERNES' && day.description !== 'LUNES');
        } else if (selectedDay1 === 'VIERNES') {
            this.days2 = this.days.filter((day: any) => day.description !== 'LUNES' && day.description !== 'VIERNES');
        } else {
            this.days2 = this.days.filter((day: any) => day.description !== selectedDay1);
        }

        // Si el valor seleccionado en day2 es inválido, reinícialo
        if (!this.c_obj['day2_id']) {
            this.c_obj['day2_id'] = null;
        }
    }

    // Método para controlar la habilitación/deshabilitación del combo de horario
    onFlexibleChange() {
        if (!this.modalidaFlexible) {
            this.c_obj['type_id'] = null;
        }
    }

    // Métodos para verificar si los combos deben estar deshabilitados
    isDayComboDisabled(): boolean {
        return !this.modalidadHibrido;
    }

    isScheduleComboDisabled(): boolean {
        return !this.modalidaFlexible;
    }

    openCreateModal(content: any) {
      this.c_obj = {};
      this.modalService.open(content, { centered: true, keyboard: false, backdrop: 'static' });

      this.loadingRequired = true;
      this.getActualsData().subscribe(x => {
        this.loadingRequired = false;
        this.days = x['days'];
        this.days1 = x['days'];
        // this.days2 = x['days'];
        this.schedules = x['schedules'];
        // this.c_obj['requirements'] = [];
        // this.requirements.forEach(x => {
        //   this.c_obj['requirements'].push({ id: x.id, name: x.description, sel: false });
        // });

        // this.c_obj['orig_date'] = this.momentjs().format('YYYY-MM-DD');
      });
    }

    public getActualsData() {
        var par_url = 'remotework/actuals';

        return this.srv.getCall(par_url).pipe(
            mergeMap(x => {
                let mydata: any = { status: false, message: '', data: [] };
                mydata = x;
                //console.log(data);

                if (mydata.status == true) {
                    return of(x.data.rows);
                }
                else {
                    Swal.fire(mydata.message, '', 'error');
                    return of(false);
                }
            }),
            //timeout(MAX_TIME),
            catchError(data => of(false))
        );
    }

    public createItem(content: any, form: any) {
        let to:any = {};

        if( !this.modalidadHibrido && !this.modalidaFlexible ){
            Swal.fire('Debe seleccionar al menos 1 modalidad', '', 'error');
        }

        if (this.modalidadHibrido) {
            if (this.c_obj['day1_id'] == undefined || this.c_obj['day1_id'] == null || this.c_obj['day2_id'] == undefined || this.c_obj['day2_id'] == null) {
                Swal.fire('Debe seleccionar dos días', '', 'error');
                return;
            }

            to['hybrid'] = 1;
            to['hybrid_val'] = this.c_obj['day1_id'] + ' | ' + this.c_obj['day2_id'];
        }

        if (this.modalidaFlexible) {
            if (this.c_obj['type_id'] == undefined || this.c_obj['type_id'] == null ) {
                Swal.fire('Debe seleccionar horario', '', 'error');
                return;
            }

            to['flex'] = 1;
            to['flex_val'] = this.c_obj['type_id'];
        }

        var qst = this.create_url;
        this.isCreating = true;
        
        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            
            if (x.status) {
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else {
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    onCancel() {
      console.log('Form cancelled');
      // Implement your cancel logic here
    }

    openUpdateModal(content:any, obj:any){
        let last_approval:any = {updated_at:'', responsible:{name:''}};
        if( Array.isArray(obj['approvals']) && (obj['approvals'].length > 0) ){
            let tmp = obj['approvals'][obj['approvals'].length - 1];
            last_approval = tmp;
        }

        obj['last_approval'] = last_approval;
        console.dir(obj);
        super.openUpdateModal(content, obj, 'lg');
    }

    public download(obj:any){
        var url = this.download_url;
        //this.isDownloading = true;
        obj['downloading'] = true;

        this.srv.postBlobCall(url, {download: 1, id:obj['id']}).subscribe((resp:any) => {
            obj['downloading'] = false;
            var myurl = URL.createObjectURL(resp);
            window.open(myurl);
        });
    }

}