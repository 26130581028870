import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent extends BaseCrudComponent {
	@ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;
	files  = [];
	isUploading = false;

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'customers';
        this.update_url = '';

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        super.ngOnInit();
        super.getList();
    }

    uploadFile(){
    	const fileUpload = this.fileUpload.nativeElement;

    	if( fileUpload.files.length > 0 ){
    		const file = fileUpload.files[0];
    		//console.log(file);
    		const formData = new FormData();
	    	formData.append('file', file);
	    	this.isUploading = true;

	    	this.srv.customPostCall('customer/upload', formData).subscribe(resp => {
	    		this.isUploading = false;
	    		//console.log(resp);
	    		if(resp.status){
	    			this.getList();
	    			Swal.fire('Archivo cargado', '', 'success');
	    		}
	    		else{
	    			//console.log(resp);
	    			Swal.fire(resp.message, '', 'error');
	    		}
	    	});
    	}
    }

    deleteItem(id){
    	this.delete_url = 'customer/del/'+id;
    	super.deleteItem({});
    }

}
