import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { LoginService } from '../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';


@Component({
  selector: 'app-subsidies',
  templateUrl: './subsidies.component.html',
  styleUrls: ['./subsidies.component.css']
})
export class SubsidiesComponent extends BaseCrudComponent {

  	translations:any;
    api_url:any;
    titles:any;
    centers:any;
    teams:any;
    days:any;
    loadingRequired:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'subsidy/list';
        //this.create_url = 'subsidy/upload';
        this.create_url = 'subsidy';
        this.delete_url = 'subsidy/del';
        this.search_fields = ['uname', 'ucode', 'ccosto_name', 'ccosto_id', 'type'];

        this.titles = [];
        this.centers = [];
        this.teams = [];
        this.days = [];

        this.loadingRequired = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'id_title': new FormControl(fields.id_title, [Validators.required]),
            'id_ccosto': new FormControl(fields.id_ccosto, [Validators.required]),
            'team_id': new FormControl(fields.team_id, [Validators.required]),
            'stype': new FormControl(fields.stype, [Validators.required]),
            'perc': new FormControl(fields.perc, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        let tmp = [];
        let name_title = '';
        tmp = this.titles.filter(x => x.id == form.id_title);
        //console.log(tmp);
        if( tmp.length > 0 ){
            name_title = tmp[0].description;
        }

        let name_ccosto = '';
        tmp = this.centers.filter(x => x.id == form.id_ccosto);
        //console.log(tmp);
        if( tmp.length > 0 ){
            name_ccosto = tmp[0].description;
        }

        form.name_title = name_title;
        form.name_ccosto = name_ccosto;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, form).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public getActualsData(){
        var par_url = 'subsidy/actuals';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        this.c_obj = {};
        this.modalService.open(content, { centered: true, keyboard: false, backdrop: 'static' });

        this.loadingRequired = true;
        this.getActualsData().subscribe(x => {
            this.loadingRequired = false;
            this.days = x['types'];
            this.centers = x['centers'];
            this.titles = x['titles'];
            this.teams = x['teams'];
        });
    }


}
