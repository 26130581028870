import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic2',
  templateUrl: './ic2.component.html',
  styleUrls: ['./ic2.component.css']
})
export class Ic2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
