import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { formatNumber } from '@angular/common';
import { ApiService } from '../../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../../services/global';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-comission-create',
  templateUrl: './comission-create.component.html',
  styleUrls: ['./comission-create.component.css']
})
export class ComissionCreateComponent extends BaseCrudComponent {

  	translations:any;
    listUsers:any;
    types:any;
    loadingRequired:any;
    loadingPrev:any;
    //url_comercio = 'https://des360.hivimar.com:8010/api/PayRoll/SpareParts';
    url_comercio = 'https://hivi.hivimar.com:8011/api/PayRoll/SpareParts';
    //url_flotas = 'https://des360.hivimar.com:8010/api/PayRoll/Fleets';
    url_flotas = 'https://hivi.hivimar.com:8011/api/PayRoll/Fleets';
    //url_lubricantes = 'https://des360.hivimar.com:8010/api/PayRoll/Lubricator';
    url_lubricantes = 'https://hivi.hivimar.com:8011/api/PayRoll/Lubricator';

    momentjs: any = moment;
    currdate:any;
    currtype:any;
    section_format = { name: '', items: [] };
    sec_item = { key:'', name:'' };
    fields:any;

    columns = [
        {id: 1, name: 'RESULTADO'},
        {id: 2, name: 'VALORES'}
    ];

    datatypes = [
        {id: 1, name: 'TEXTO'},
        {id: 2, name: 'MONEDA'},
        {id: 3, name: 'PORCENTAJE'}
    ];

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = '';
        this.create_url = 'admin/commission';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = [];
        this.listUsers = [];
        this.types = [];
        this.fields = [];
        this.loadingRequired = false;
        this.loadingPrev = false;
    }

    ngOnInit() {
    	this.currdate = this.momentjs().tz('America/Guayaquil').format('YYYY-MM');

        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
          	this.translations = translations;
          	super.ngOnInit();
          
          	this.loadingRequired = true;
	        this.getFiltersData().subscribe(x => {
	            this.loadingRequired = false;
	            if(x != false){
	        		this.types = x['types'];
	            }
	        });
        });
    }

    public getListPost(){
    	if( (this.currdate == undefined) || (this.currdate == null) ){
    		Swal.fire('Seleccione período', '', 'error');
            return;
    	}

    	if( (this.currtype == undefined) || (this.currtype == null) ){
    		Swal.fire('Seleccione tipo', '', 'error');
            return;
    	}

        this.loadingList = true;
        this.getSAPData().subscribe(x => {
        	this.loadingList = false;
        	//console.log(x);
        	if( (x != false) && (x.status == 'success') ){
        		this.listUsers = x.objeto;
        		this.parseFields(this.listUsers);
        	}
        });
    }

    public parseFields(rows:any){
    	if( Array.isArray(rows) && (rows.length > 0) ){
    		this.fields = Object.keys(rows[0]);
    		//console.log(this.fields);
    	}
    }

    public getFiltersData(){
        var par_url = 'admin/commission/actuals';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                          return of(x.data.rows);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getSAPData(){
    	var mdate = this.momentjs(this.currdate+'-01');
    	var month = mdate.month() + 1;
    	var year = mdate.year();
    	
    	if( this.currtype == 1 ){
    		var par_url = this.url_comercio;
    	}
    	else if( this.currtype == 2 ){
    		var par_url = this.url_flotas;
    	}
        else{
            var par_url = this.url_lubricantes;
        }
        
        var to = {_mes: month, _anio: year};

        return this.srv.externalPostCall(par_url, to).pipe(
                    mergeMap(x => {
						return of(x);
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        this.c_obj = {};
        this.c_obj.sections = [];
        this.c_obj.fields = [];
        this.c_obj.newsection = '';
        
        if( this.fields.length <= 0 ){
        	Swal.fire('No existe info', '', 'error');
        	return;
        }

        this.fields.forEach(f => {
        	this.c_obj.fields.push({key:f, section:-1, sel:false, isTotal:false, name:'', column:'', isTitle:false, dtype: 1});
        });

        //super.openCreateModal(content);
        super.openInfoModalBig(content, {});
    }

    onKeydown(event, obj) {
	    if (event.key === "Enter") {
	    	this.addSection(obj);
	  	}
	}

    addSection(obj:any){
    	if(obj.newsection != ''){
    		obj.sections.push({id:obj.sections.length, name:obj.newsection});
    		obj.newsection = '';
    	}
    }

    removeSection(needle, haystack){
    	if( Array.isArray(haystack) ){
    		haystack.splice(needle, 1);
    	}
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'user_id': new FormControl(fields.user_id, [Validators.required]),
            'doc_id': new FormControl(fields.doc_id, [Validators.required]),
            'responsible_id': new FormControl(fields.responsible_id, [Validators.required]),
            'approve_order': new FormControl(fields.approve_order, [Validators.required])/*,
            'is_required': new FormControl(fields.is_required, [Validators.required])*/
        });

        return mygroup.valid;
    }

    public createItem(content:any, obj){
        var counter = 0;
        var sel_secs = [];
        var tosend = [];
        
        this.c_obj['fields'].forEach(f => {
            f.section = parseInt(f.section);
        	if( f.sel && (sel_secs.indexOf(f.section) < 0) ){
        		sel_secs.push(f.section);
        	}
        });

        if( sel_secs.length <= 0 ){
        	Swal.fire('No hay campos seleccionados', '', 'error');
            return;
        }

        var sections = this.c_obj.sections;
        var fields = this.c_obj['fields'];
        var template = [];

        sel_secs.forEach(x => {
        	let sname = '';
        	let tmp = [];
        	
        	if( x > -1 ){
        		sname = sections.filter(s => {return x == s.id})[0]['name'];
        	}

        	fields.forEach(f => {
        		if( (f.section == x) && (f.sel) ){
        			tmp.push({key: f.key, name: f.name, total: f.isTotal, isTitle: f.isTitle, column: f.column, dtype:f.dtype});
        		}
        	});

        	template.push({name: sname, items: tmp});
        });
        /*console.log(template);
        console.log(sel_secs);
        console.log(fields);
        return;*/

        this.listUsers.forEach(u => {
        	let seller = (u['cedulaAgente'] != null) ? u['cedulaAgente'].trim() : '';
        	let inner = {id: seller, data:[]};

        	template.forEach(t => {
        		let inner_fields = [];

        		t.items.forEach(y => {
        			inner_fields.push({
        				//key: y.key,
        				name: (y.name == '') ? y.key : y.name,
        				total: y.total,
                        col: y.column,
        				val: this.formatVal(u, y),
                        isTitle: y.isTitle
        			});
        		});

        		inner.data.push({
        			name: t.name,
        			items: inner_fields
        		});
        	});

        	tosend.push(inner);
        });

        /*console.log(template);
        console.log(tosend);
        return;*/

        var to:any;
        to = {};
        to.data = tosend;
        to.tpl = template;
        to.period = this.currdate + '-01';
        to.type = this.currtype;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                //this.c_obj = {};
                //this.getList();
                this.closeModal(content);
                var submsg = 'Procesados: ' + x.data['found'] + ' | ' + 'No Procesados: ' + x.data['not_found']
                Swal.fire(this.translations.created, submsg, 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    moveUp(i, rows){
        console.log(i);
        if( i == 0 ){
            console.log('first item');
            return;
        }

        var newpos = i - 1;
        var old:any;
        old = rows[newpos];
        rows[newpos] = rows[i];
        rows[i] = old;
    }

    moveDown(i, rows){
        console.log(i);
        if( i >= (rows.length - 1) ){
            console.log('last item');
            return;
        }

        var newpos = i + 1;
        var old:any;
        old = rows[newpos];
        rows[newpos] = rows[i];
        rows[i] = old;
    }

    addTitle(i, rows){
        var obj = {key:'', section:-1, sel:true, isTotal:false, name:'', column:'', isTitle:true};
        rows.splice(i, 0, obj);
    }

    formatVal(haystack:any, y:any){
        var resp:any;
        resp = '';
        
        if( !y.isTitle ){
            resp = haystack[y.key];

            switch(y.dtype){
                case '2':
                    //currency
                    //resp = parseFloat(resp).toFixed(2);
                    resp = formatNumber(resp, 'en-US', '0.2-2');
                    resp = '$' + resp;
                break;
                case '3':
                    //percentage
                    //resp = parseFloat(resp).toFixed(2);
                    resp = formatNumber(resp, 'en-US', '0.2-2');
                    resp = resp + '%';
                break;
            }
        }

        return resp;
    }

    public loadPreviousSections(obj:any){
        var par_url = 'admin/commission/template';

        if( (obj['prevperiod'] == undefined) || (obj['prevperiod'] == null) ){
            Swal.fire('Seleccione período que desea cargar', '', 'error');
            return;
        }

        let to:any;
        to = {start: obj['prevperiod'] + '-01', type: this.currtype};
        this.loadingPrev = true;

        this.srv.postCall(par_url, to).subscribe(x => {
            this.loadingPrev = false;
            if(x.status){
                //console.log(x.data.rows);
                let rs = x.data.rows;
                
                obj.sections = [];
                let i = 0;

                //this.c_obj.fields.push({key:f, section:-1, sel:false, isTotal:false, name:'', column:'', isTitle:false, dtype: 1});
                var tmp_fields = {};
                var titles = [];

                rs.forEach(s => {
                    obj.sections.push({id:i, name:s['name']});

                    s.items.forEach(y => {
                        y.section = i;
                        if( y.key != null ){
                            tmp_fields[y.key] = y;
                        }
                        else{
                            //prefill fields for titles
                            y.sel = true;
                            y.isTotal = false;
                            titles.push(y);
                        }
                        
                    });

                    i++
                });

                obj.fields.forEach(f => {
                    if( tmp_fields[f.key] != undefined  ){
                        let tf = tmp_fields[f.key];

                        f.section = tf.section;
                        f.sel = true;
                        f.isTotal = tf.total;
                        f.name = tf.name;
                        f.column = tf.column;
                        f.dtype = tf.dtype;
                        f.isTitle = tf.isTitle;
                    }
                });

                titles.forEach(t => {
                    //obj.fields.unshift(t);
                    obj.fields.push(t);
                });

                //console.log(obj.fields);

            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }


}
