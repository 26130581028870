import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'user-contacts',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent extends BaseCrudComponent {

  	@Input() profile: any;

    translations:any;
    relations:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'employee/contact';
        this.create_url = 'employee/contact';
        this.update_url = 'employee/contact';
        this.delete_url = 'employee/contact/del';
        this.search_fields = [];
        this.relations = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;

              this.storage.get('common').then(c => {
              		this.relations = c['data']['fam'];
              });

              super.ngOnInit();
              this.getList();
        });
    }

    openCreateModal(content:any){
        this.c_obj = {};
        super.openCreateModal(content);
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'rel_id': new FormControl(fields.rel_id, [Validators.required]),
            'name': new FormControl(fields.name, [Validators.required]),
            'lastname': new FormControl(fields.lastname, [Validators.required]),
            'phone_mobile': new FormControl(fields.phone_mobile, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.update_url + '/' + form.id;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.status){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.message, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    private getRelationshipStr(id:any){
		var str = '';
		var resp:any;
		resp = this.relations.filter(f => {
			return f.id == id;
		})

		if( resp.length > 0 ){
			str = resp[0]['description'];
		}

		return str;
	}

}
