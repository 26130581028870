import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { LoginService } from '../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import * as moment from 'moment-timezone';
moment.tz.setDefault('America/Guayaquil');

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent extends BaseCrudComponent {

  	translations:any;
    loadingRequired:any;
    momentjs: any = moment;
    listApprovers:any;
    sudo:any;
    showSudo:any;

    types:any;
    regions:any;
    processes:any;
    requirements:any;
    origins:any;
    reasons:any;

    isDownloading2 = 0;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'staff';
        this.create_url = 'staff';
        this.update_url = 'staff';
        this.delete_url = 'staff/del';
        this.search_fields = ['id', 'position_name', 'status_str'];
        this.loadingRequired = false;

        this.types = [];
        this.regions = [];
        this.processes = [];
        this.requirements = [];
        this.origins = [];
        this.reasons = [];

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();

            this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    this.getApproversData();
                }
            });
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    public getActualsData(){
        var par_url = 'staff/actuals';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        this.c_obj = {};
        this.modalService.open(content, { centered: true, keyboard: false, backdrop: 'static' });

        this.loadingRequired = true;
        this.getActualsData().subscribe(x => {
            this.loadingRequired = false;
            this.types = x['types'];
	        this.regions = x['regions'];
	        this.processes = x['processes'];
	        this.requirements = x['requirements'];
	        this.origins = x['origins'];
	        this.reasons = x['reasons'];

	        this.c_obj['requirements'] = [];
	        this.requirements.forEach(x => {
	        	this.c_obj['requirements'].push({id: x.id, name: x.description, sel: false});
	        });
            
            this.c_obj['orig_date'] = this.momentjs().format('YYYY-MM-DD');
        });
    }

    openUpdateModal(content:any, obj:any){
    	this.u_obj = {};

        if( obj.status > 0 ){
        	obj.disabled = true;
        }
        else{
        	obj.disabled = false;
        }

        super.openUpdateModal(content, obj);

        this.loadingRequired = true;
        this.getActualsData().subscribe(x => {
            this.loadingRequired = false;
            this.types = x['types'];
	        this.regions = x['regions'];
	        this.processes = x['processes'];
	        this.requirements = x['requirements'];
	        this.origins = x['origins'];
	        this.reasons = x['reasons'];

	        this.u_obj['hivi_unit'] = this.u_obj['hivi_unit']['id'];
	        this.u_obj['to_replace_local'] = this.u_obj['replaced'];

	        var selected = this.u_obj['requirements'];
	        this.u_obj['requirements'] = [];
	        
	        this.requirements.forEach(x => {
	        	let isSelected = false;
	        	let checker = selected.filter(s => {return s.sysparam_id == x.id});
	        	if( checker.length > 0 ){
	        		isSelected = true;
	        	}
	        	
	        	this.u_obj['requirements'].push({id: x.id, name: x.description, sel: isSelected});
	        });
	        /*console.log(selected);
	        console.log(this.u_obj['requirements']);*/
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'orig_date': new FormControl(fields.orig_date, [Validators.required]),
            'num_vac': new FormControl(fields.num_vac, [Validators.required, Validators.min(1)]),
            'hivi_unit': new FormControl(fields.hivi_unit, [Validators.required]),
            'region_id': new FormControl(fields.region_id, [Validators.required]),
            'position_name': new FormControl(fields.position_name, [Validators.required]),
            'origin_id': new FormControl(fields.origin_id, [Validators.required]),
            'process_id': new FormControl(fields.process_id, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }
        
        if( !Array.isArray(form['requirements']) ){
        	Swal.fire('Debe seleccionar al menos 1 requisito', '', 'error');
            return;
        }

        var req_count = 0;
        form['requirements'].forEach(x => {
        	if( x.sel ){
        		req_count++;
        	}
        });

        if( req_count <= 0 ){
        	Swal.fire('Debe seleccionar al menos 1 requisito', '', 'error');
            return;
        }

        if(form['origin_id'] == 82){
        	//reemplazo
        	if( (form['to_replace_local'] == undefined) || (form['reason_id'] == undefined) ){
        		Swal.fire('Debe seleccionar colaborador a reemplazar y motivo de vacante', '', 'error');
            	return;
        	}

        	form['to_replace'] = form['to_replace_local']['id'];
        }

        if(form['origin_id'] == 83){
        	if( (form['new_position'] == undefined) || (form['origin_comment'] == undefined) ){
        		Swal.fire('Debe seleccionar tipo de posición y dar una justificación', '', 'error');
            	return;
        	}
        }

        var to:any;
        to = form;
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            to['uid'] = this.sudo.id;
        }

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }
        
        if( !Array.isArray(form['requirements']) ){
        	Swal.fire('Debe seleccionar al menos 1 requisito', '', 'error');
            return;
        }

        var req_count = 0;
        form['requirements'].forEach(x => {
        	if( x.sel ){
        		req_count++;
        	}
        });

        if( req_count <= 0 ){
        	Swal.fire('Debe seleccionar al menos 1 requisito', '', 'error');
            return;
        }

        if(form['origin_id'] == 82){
        	//reemplazo
        	if( (form['to_replace_local'] == undefined) || (form['reason_id'] == undefined) ){
        		Swal.fire('Debe seleccionar colaborador a reemplazar y motivo de vacante', '', 'error');
            	return;
        	}

        	form['to_replace'] = form['to_replace_local']['id'];
        }

        if(form['origin_id'] == 83){
        	if( (form['new_position'] == undefined) || (form['origin_comment'] == undefined) ){
        		Swal.fire('Debe seleccionar tipo de posición y dar una justificación', '', 'error');
            	return;
        	}
        }

        var to:any;
        to = form;
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            to['uid'] = this.sudo.id;
        }

        var qst = this.update_url + '/' + to.id;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.status){
                this.u_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.updated, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

    public downloadReport(obj:any){
        var url = 'staff/download/' + obj.id;

        if(this.isDownloading2){
            //console.log('req denied');
            return;
        }

        this.isDownloading2 = obj.id;

        this.srv.postBlobCall(url, {}).subscribe(resp => {
            this.isDownloading2 = 0;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

}
