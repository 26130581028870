import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/authentication/login.service';

@Component({
  selector: 'app-flexhybridmode',
  templateUrl: './flexhybridmode.component.html',
  styleUrls: ['./flexhybridmode.component.css']
})
export class FlexHybridModeComponent implements OnInit {

  	menu:any;
  	permissions = [];

	constructor(public auth: LoginService) { }

	ngOnInit() {
		this.menu = [	      
            {name:'Excluir cargos', desc:'Excluir cargos', icon:'fas fa-ban', url:'/uploadTitle', perm: 'ADMINS-REMOTE-CARGOS_EXCLUIR'},
            {name:'Calificaciones', desc:'Calificaciones', icon:'fas fa-star', url:'/score', perm: 'ADMINS-REMOTE-CALIFICACIONES'},
            {name:'Aprobaciones', desc:'Aprobación de Solicitudes', icon:'fas fa-tasks', url:'/remotework_approval', perm: 'ADMINS-APROBACION_MODALIDAD_TRABAJO'}
		];

		this.auth.getPermissions().then(x => {
            this.permissions = x;
        });
	}

	hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }

}
