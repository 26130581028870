import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-report-benefitsreq',
  templateUrl: './report-benefitsreq.component.html',
  styleUrls: ['./report-benefitsreq.component.css']
})
export class ReportBenefitsreqComponent extends BaseCrudComponent {

  	translations:any;
    momentjs: any = moment;

    period = '';
    status = '';
    atype = '';

    cb_status:any = [];
    cb_type:any = [];
    cb_period:any = [];

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public _login: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'admin/benefireq/report/list';
        this.create_url = '';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.clearData();
                //this.getList();
            }
        });
    }

    public clearData(){
        this.rows = [];
    }

    public clearFilters(){
        this.search = '';
        this.period = '';
        this.atype = '';
        this.status = '';
    }

    ngOnInit() {
        //this.f_datefrom = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');

        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;

            this.getFiltersData().subscribe(x => {
	            this.cb_type = x['types'];
	            this.cb_status = x['status'];
	            this.cb_period = x['periods'];
	       	});

            super.ngOnInit();
            //this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};
        this.clearData();

        tosend['status'] = this.status;
        tosend['type'] = this.atype;
        tosend['period'] = this.period;
        tosend['search'] = this.search;
        
        this.loadingList = true;
        this.srv.postCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rows = x.data.rows;
            }
        });
    }

    public download(){
		var url = this.read_url;
        var tosend = {};
        tosend['status'] = this.status;
        tosend['type'] = this.atype;
        tosend['period'] = this.period;
        tosend['search'] = this.search;

        tosend['export'] = 1;
        
        this.isDownloading = true;
        this.srv.postBlobCall(url, tosend).subscribe(resp => {
            this.isDownloading = false;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

    public getFiltersData(){
        var par_url = 'benefireq/filters';
        var tosend = {};

        return this.srv.getCall(par_url, tosend).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

}
