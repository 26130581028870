import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { LoginService } from '../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-mealapprove',
  templateUrl: './mealapprove.component.html',
  styleUrls: ['./mealapprove.component.css']
})
export class MealapproveComponent extends BaseCrudComponent {

    translations:any;
    loadingRequired:any;
    listApprovers:any;
    sudo:any;
    showSudo:any;
    balance:any;
    movements:any;
    total:any;

    teamid:any;
    teamsubscription: Subscription;
    reloadSub: Subscription;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'admin/mealbooking';
        this.create_url = 'admin/mealbooking';
        this.update_url = 'admin/mealbooking';
        this.delete_url = '';
        this.search_fields = ['vacation.id'];
        this.listApprovers = [];
        this.loadingRequired = false;
        this.balance = [];
        this.movements = [];
        this.total = 0;
        
        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;

        this.reloadSub = this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });

        this.teamsubscription = this.srv.team_changed.subscribe(val => {
            if( val ){
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
            
            this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    
                }
            });
        });
    }

    public getList(){
        var qst = this.read_url;

        this.auth.getTeam().then(x => {
            this.teamid = x;
            this.clearData();
            this.loadingList = true;

            this.srv.getCall(qst, {tid: this.teamid}).subscribe(x => {
                this.loadingList = false;
                
                if(x.status){
                    this.rawdata = x.data.rows;
                    this.rows = x.data.rows;
                    this.page = 1;
    
                    if( Array.isArray(this.rows) ){
                        this.pageTotal = this.rows.length;
                    }
    
                    this.filterAll();
                }
            });
        });
    }

    public approve(id:any) {
        var qst = 'admin/mealbooking/approve/' + id;
        var tosend = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        Swal.fire({
            title: '¿Aprobar menú?',
            text: '',
            icon: 'success',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'OK',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.srv.postCall(qst, tosend).toPromise().then(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Aprobado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            /*if (result.value) {
                this.srv.postCall(qst, {}).subscribe(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Aprobado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            }*/
        });
    }

    public reject(id:any) {
        var qst = 'admin/mealbooking/reject/' + id;
        var tosend = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        Swal.fire({
            title: '¿Rechazar menú?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'OK',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.srv.postCall(qst, tosend).toPromise().then(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Rechazado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            /*if (result.value) {
                this.srv.postCall(qst, {}).subscribe(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Rechazado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            }*/
        });
    }

    openUpdateModal(content:any, obj:any){
        super.openUpdateModal(content, obj);
        this.u_obj['days'] = [];
        let tmp:any = [];
        let key:string;

        if( obj['details'] && Array.isArray(obj['details']) ){
            obj['details'].forEach(x => {
                key = x['day_id'] + 'x';
                
                if( !tmp[key] ){
                    tmp[key] = {title: x['day_str'] + ' ' + x['due_date'], items: []};
                }

                tmp[key]['items'].push(x);
            });
        }

        //console.log(tmp);
        let keys = Object.keys(tmp);
        keys.forEach(k => {
            this.u_obj['days'].push(tmp[k]);
        });
    }

    ngOnDestroy() {
        //console.log('unsubscribing');
        this.reloadSub.unsubscribe();
        this.teamsubscription.unsubscribe();
    }

}
