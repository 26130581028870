import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-kitchenprocess',
  templateUrl: './kitchenprocess.component.html',
  styleUrls: ['./kitchenprocess.component.css']
})

export class KitchenprocessComponent extends BaseCrudComponent {

  	translations:any;
    momentjs: any = moment;
    files:any;
    teamid:any;
    teamsubscription: Subscription;
    
    date_str:string = '';
    obj:any = {};
    qrResultString:string = '';

    bookings:any;
    loadingRequired:boolean = false;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public _login: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'kitchen/list';
        this.create_url = '';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = [];
        this.bookings = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                //this.getList();
            }
        });

        this.teamsubscription = this.srv.team_changed.subscribe(val => {
        	if( val ){
        		super.clearData();
        	}
        });
    }

    ngOnInit() {
        this.f_datefrom = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');
        //this.f_dateto = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');

        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};
        this.obj = {};

        this._login.getTeam().then(x => {
	        this.teamid = x;
	        tosend['start'] = this.f_datefrom;
	        //tosend['end'] = this.f_dateto;
	        tosend['search'] = this.search;
	        tosend['tid'] = this.teamid;
	        
	        this.loadingList = true;
	        this.srv.postCall(qst, tosend).subscribe(x => {
	            this.loadingList = false;
	            
	            if(x.status){
	                //console.log(x.data);
	                this.date_str = x.data.date;
	                this.rows = [];

                    Object.keys(x.data.rows).forEach(k => {
                        let r = x.data.rows[k];
                        this.obj = r;
                        //this.rows.push(r);
                    });

                    if( !(this.obj['details']) && (x.data.user) ){
                        this.obj['name'] = x.data.user['name'];
                        this.obj['id'] = x.data.user['id'];
                    }

                    //console.log(this.obj);
	            }
	        });
	    });
    }

    dispatchOrder(obj:any){
        let qst = 'order/dispatch/' + obj.id;
        obj.loading = true;

        this.srv.postCall(qst, {}).subscribe(x => {
            obj.loading = false;
            //console.log(x);
            if(x.status){
                this.getList();
            }
        });
    }

    dispatchOrderItem(oid, det){
        let qst = 'order/dispatchitem/' + det.id;
        det.loading = true;

        this.srv.postCall(qst, {}).subscribe(x => {
            det.loading = false;
            //console.log(x);
            if(x.status){
                this.getList();
                /*if( response.data.data.finished ){
                    curr.info[oid].dispatched = 1;
                }*/
            }
        });
    }

    openScanModal(content:any){
        this.qrResultString = '';
        super.openUpdateModal(content, {});
    }

    public onCodeResult(resultString: string, content:any) {
        this.qrResultString = resultString;
        //console.log(this.qrResultString);
        this.search = this.qrResultString;
        //this.search = 'sysadmin';
        this.qrResultString = '';
        this.getList();
        this.closeModal(content);
    }

    public getBookingData(teamid:any, start:any, isDinner:boolean){
        var par_url = 'mealbooking/list';
        var tosend = {
        	start: start,
        	tid: teamid,
        };

        if( isDinner ){
            tosend['dinner'] = 1;
        }

        return this.srv.getCall(par_url, tosend).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openMealPicker(content:any, isDinner:boolean){
	    this.c_obj = {};
        var seldate_str = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');
        this.c_obj.seldate_str = seldate_str;
        this.c_obj.dinner = isDinner;
        super.openCreateModal(content);
        
        this.loadingRequired = true;

        this.getBookingData(this.teamid, seldate_str, isDinner).subscribe(x => {
            this.loadingRequired = false;

            if(x != false){
                this.bookings = [];
                var nrows = x;
        
                nrows.forEach((val,key) => {
                    var stock = (val.stock == undefined) ? 'Si' : ((val.stock == 0) ? 'Si' : (val.stock - val.used));
                    var price = (val.meal.price) ? val.meal.price : val.meal.category.price;
                    //var name = val.meal.name + ' ( ' + stock + ' )' + ' - ' + price + ' USD';
                    var name = val.meal.name + ' ( ' + val.meal.category.name + ' )';
                    //this time we use meal id
                    this.bookings.push({
                        title: name,
                        name: val.meal.name,
                        start: val.due_date, 
                        color: val.meal.category.color, 
                        cat: val.meal.category_id,
                        catname: val.meal.category.name,
                        id: val.meal.id
                    });
                });

                //console.log(this.bookings);

                this.updateDayMeals(seldate_str);
                //this.c_obj.seldate_str = seldate_str;

                if( this.c_obj.meals.length > 0 ){
                    this.c_obj.start = seldate_str;
                    //super.openCreateModal(content);
                }
            }
        });
	}

	updateDayMeals(dstart:any){
		var curr = this.c_obj;

        curr.meals = [];
        curr.order = null;
        curr.comment = '';
        curr.id = -1;

        curr.meals = this.bookings.filter(function(e){
            return (e.start == dstart);
        });
    }

    checkItem(obj:any){
    	obj.selected = !obj.selected;

        if( obj.selected ){
            obj.qty = 1;
        }
        else{
            obj.qty = 0;
        }
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        if( (form.meals == undefined) || (!Array.isArray(form.meals)) ){
        	Swal.fire('Data invalida', '', 'error');
            return;
        }

        var to:any;
        to = {};
        
        var rows = [];
        form.meals.forEach(( val , key ) => {
            if( val.selected ){
                rows.push({id: val.id, qty: val.qty, detail_id: val.detail_id});
            }
        });

        if( (rows.length <= 0) && (form.id == -1) ){
            Swal.fire('Seleccione al menos 1 plato', '', 'error');return;
        }

        to.start = form.start;
        to.tid = this.teamid;
        to.comment = form.comment;
        to.items = rows;

        if( form.id != -1 ){
            to.id = form.id;
        }

        //console.log(form);return;
        if( form.dinner ){
            to.dinner = 1;
        }
        
        //console.log(this.obj);return;
        to['uid'] = this.obj['id'];

        var qst = 'order/process';
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.updated, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

}
