import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { LoginService } from '../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-advance',
  templateUrl: './advance.component.html',
  styleUrls: ['./advance.component.css']
})
export class AdvanceComponent extends BaseCrudComponent {

  	translations:any;
    loadingRequired:any;
    listApprovers:any;
    sudo:any;
    showSudo:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'advance';
        this.create_url = 'advance';
        this.update_url = 'advance';
        this.delete_url = 'advance/del';
        this.search_fields = [];
        this.loadingRequired = false;

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
                this.translations = translations;
                super.ngOnInit();
                this.getList();

                this.auth.hasRole(['superadmin','admin']).then(r => {
                    this.showSudo = r;
                    if( r ){
                        this.getApproversData();
                    }
                });
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        //this.srv.postCall(qst, tosend).subscribe(x => {
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                    /*this.rows.forEach(x => {
                    	//used for search bar
                    	x.doctype_str = x.doctype.description;
                    	x.userto_str = x.userto.name;
                    	x.responsible_str = x.responsible.name;
                    });*/
                }

                this.filterAll();
            }
        });
    }

    public getActualsData(){
        var par_url = 'advance/actuals';
        var tosend = {};

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        return this.srv.getCall(par_url, tosend).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        this.c_obj = {};

        super.openCreateModal(content);
        this.loadingRequired = true;
        this.getActualsData().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.c_obj['max_amount'] = x['max_amount'];
            }
            else{
                this.c_obj['max_amount'] = 0;
            }
        });
    }

    openUpdateModal(content:any, obj:any){
        super.openUpdateModal(content, obj);
        /*this.loadingRequired = true;
        this.getFiltersData().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.listUsers = x['users'];
        		this.listDocs = x['docs'];
            }
        });*/
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'amount': new FormControl(fields.amount, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        let amount = parseFloat(form['amount']);
        let max_amount = parseFloat(form['max_amount']);
        
        if( amount > max_amount ){
            Swal.fire(this.translations['max_amount_error'], '', 'error');
            return;
        }

        if( amount <= 0 ){
            Swal.fire(this.translations['invalid_amount'], '', 'error');
            return;
        }

        var to:any;
        to = {};

        if( (this.sudo != undefined) && (this.sudo != null) ){
            to['uid'] = this.sudo.id;
        }
        
        //to.amount = form['amount'];
        to.amount = amount;

        if( form['comment'] != undefined ){
            to.comment = form['comment'];
        }

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

}
