import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-report-kitchen-delivered',
  templateUrl: './report-kitchen-delivered.component.html',
  styleUrls: ['./report-kitchen-delivered.component.css']
})
export class ReportKitchenDeliveredComponent extends BaseCrudComponent {

  	translations:any;
    momentjs: any = moment;
    files:any;
    teamid:any;
    teamsubscription: Subscription;
    date_str:string;
    categories:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public _login: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'dailyreport/list';
        this.create_url = '';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = [];
        this.date_str = '';
        this.categories = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });

        this.teamsubscription = this.srv.team_changed.subscribe(val => {
        	if( val ){
        		this.getList();
        	}
        });
    }

    ngOnInit() {
        this.f_datefrom = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');
        //this.f_dateto = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');

        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        this._login.getTeam().then(x => {
	        this.teamid = x;
	        tosend['start'] = this.f_datefrom;
	        //tosend['end'] = this.f_dateto;
	        //tosend['search'] = this.search;
	        tosend['tid'] = this.teamid;
	        
	        this.loadingList = true;
	        this.srv.postCall(qst, tosend).subscribe(x => {
	            this.loadingList = false;
	            
	            if(x.status){
	                //console.log(x.data);
	                this.date_str = x.data.date;
	                this.rows = [];
	                //this.categories = [];

	                Object.keys(x.data.rows).forEach(k => {
	                	let r = x.data.rows[k];
	                	this.rows.push(r);
	                });
	            }
	        });
	    });
    }

}
