import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../services/authentication/login.service';

import { Observable, from, of, concat, Subscription, Subject, interval } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { AgmBicyclingLayer, MapsAPILoader } from '@agm/core';

declare const google: any;
@Component({
  selector: 'app-remotework',
  templateUrl: './remotework.component.html',
  styleUrls: ['./remotework.component.css']
})
export class RemoteworkComponent extends BaseCrudComponent {

    translations:any;
    momentjs: any = moment;
    files:any;
    teamid:any;
    teamsubscription: Subscription;
    
    date_str:string = '';
    obj:any = {};
    actions:any = [];
    track_lat:any;
    track_lng:any;

    my_lat:any;
    my_lng:any;
    googleLoaded = false;

    loadingRequired:boolean = false;

    //webcam logic
    private trigger: Subject<void> = new Subject<void>();
    public webcamImage: WebcamImage = null;

    public time: string;
    private clockSubscription: Subscription;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public _login: LoginService,
        public mapsAPILoader: MapsAPILoader
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'remotework/list';
        this.create_url = '';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });

        // this.teamsubscription = this.srv.team_changed.subscribe(val => {
        //   if( val ){
        //     super.clearData();
        //   }
        // });
    }

    ngOnInit() {
        this.trackMe();
        this.f_datefrom = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');

        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });

        this.updateTime();

        // Set up an interval to update the time every second
        this.clockSubscription = interval(1000).subscribe(() => {
        this.updateTime();
        });

        this.mapsAPILoader.load().then((g) => {
            this.googleLoaded = true;
        });
    }

    ngOnDestroy() {
        // Clean up the subscription when the component is destroyed
        if (this.clockSubscription) {
            this.clockSubscription.unsubscribe();
        }
    }

    private updateTime() {
        // Use moment.js to format the current time
        //this.time = this.momentjs().format('HH:mm:ss');
        this.time = this.momentjs().tz('America/Guayaquil').format('HH:mm:ss');
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        this.loadingList = true;
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                //console.log(x.data);
                this.date_str = x.data.date;
                this.actions = x.data.rows;

                if(x.data['home_lat'] && x.data['home_lng']){
                    this.my_lat = x.data['home_lat'];
                    this.my_lng = x.data['home_lng'];
                }
            }
        });
    }

    openCameraModal(content:any, obj:boolean){
        this.c_obj = obj;
        this.c_obj['action_name'] = obj['name'];
        //console.log(this.c_obj);

        if( obj['done'] ){
            Swal.fire('Ya fue registrado', '', 'info');
        }
        else{
            super.openCreateModalLG(content);
        }
    }

    // Webcam snapshot trigger method
    public triggerSnapshot(): void {
        this.trigger.next();
    }

    // Webcam observable to trigger
    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    // Handle image capture
    public handleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;
        //console.log('after taking');
    }

    async registerAction(content:any, obj:any){
        await this.triggerSnapshot();
        // console.log('after snap');
        // console.log(this.webcamImage);

        if( !this.webcamImage && obj.photo ){
            Swal.fire('Espere a que se cargue la cámara', '', 'info');
            return;
        }
        
        if( !this.track_lat || !this.track_lng || !this.googleLoaded ){
        //if( true ){
            //Swal.fire('Espere a que se carguen las coordenadas', '', 'info');
            Swal.fire('Espere un momento mientras se prepara la info que debemos enviar', 'Vuelva a intentar en unos segundos por favor', 'info');
            return;
        }

        let near = this.isNearLocation(this.track_lat, this.track_lng, this.my_lat, this.my_lng);

        let qst = 'remotework/action/save';
        this.isCreating = true;

        const formData: FormData = new FormData();
        formData.append('action', obj['id']);
        let location = this.track_lat + ',' + this.track_lng;
        formData.append('location', location);
        formData.append('near', near);

        if( obj.photo ){
            formData.append('file', this.dataURItoBlob(this.webcamImage.imageAsDataUrl), 'photo.jpg');
        }

        this.srv.customPostCall(qst, formData).subscribe(x => {
            this.isCreating = false;
              //console.log(x);
              if(x.status){
                  this.getList();
                  this.modalService.dismissAll(); // Close the modal
              }
              else{
                  Swal.fire(x.message, '', 'error');
              }
        });
    }

    // Utility function to convert data URI to Blob
    private dataURItoBlob(dataURI: string): Blob {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

    trackMe() {
        if (navigator.geolocation) {
          //console.log('tracking');
            //this.isTracking = true;

            navigator.geolocation.getCurrentPosition((position) => {
                this.track_lat = position.coords.latitude;
                this.track_lng = position.coords.longitude;
                //console.log(this.track_lat);
            });

            navigator.geolocation.watchPosition((position) => {
                this.track_lat = position.coords.latitude;
                this.track_lng = position.coords.longitude;
                //console.log(this.track_lat);
                });
        } else {
            //alert("Geolocation is not supported by this browser.");
            Swal.fire('Geolocation is not supported by this browser.', '', 'info');
        }
    }

    isNearLocation(lat:any, long:any, base_lat:any, base_long:any){
        let str = '';

        if( !base_lat || !base_long ){
            str = 'N/A';
        }
        else{
            let my_pos = new google.maps.LatLng(lat, long);
            let customer_pos = new google.maps.LatLng(base_lat, base_long);
            let dist = google.maps.geometry.spherical.computeDistanceBetween(my_pos, customer_pos);

            let max_distance = 100;
            str = 'NO';
            if( (dist <= max_distance) ){
                str = 'SI';
            }
        }

        return str;
    }

}
