import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/authentication/login.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  	menu:any;
  	permissions = [];

	constructor(public auth: LoginService) { }

	ngOnInit() {
		this.menu = [
	        {name:'Reporte Anticipos', desc:'Reporte Anticipos', icon:'fas fa-file-alt', url:'/advance_report', perm: 'ADMINS-REPORTES-REPORTE_ANTICIPOS'},
	        {name:'Reporte Vacaciones', desc:'Reporte Vacaciones', icon:'fas fa-file-alt', url:'/vacation_report', perm: 'ADMINS-REPORTES-REPORTE_VACACIONES'},
            {name:'Acumulación de Beneficios', desc:'Reporte Acumulación de Beneficios', icon:'fas fa-file-alt', url:'/benefitreq_report', perm: 'ADMINS-REPORTES'},
            {name:'Reporte Marcaciones', desc:'Reporte Marcaciones', icon:'fas fa-file-alt', url:'/remotework_report', perm: 'ADMINS-REPORTES-REPORTE_MARCACIONES'}
		];

		this.auth.getPermissions().then(x => {
            this.permissions = x;
        });
	}

	hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }

}
