import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profit',
  templateUrl: './profit.component.html',
  styleUrls: ['./profit.component.css']
})
export class ProfitComponent extends BaseCrudComponent {

  	info:any;
	currdate:any;
    translations:any;
    download_url:any;

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'profit';
        this.download_url = 'profit';
        this.update_url = '';

        this.info = false;
        this.currdate = '';
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getListPost();
        });
    }

    getListPost(){
        var qst = this.read_url;
        var from = '';

        /*if( this.currdate == '' ){
        	Swal.fire('Debe seleccionar fecha', '', 'error');
        	return;
        }
        else{
        	from = this.currdate + '-01';
        }*/

        this.loadingList = true;
        this.info = false;

        //this.srv.postCall(qst, {start: from}).subscribe(x => {
        this.srv.postCall(qst, {}).subscribe(x => {
            this.loadingList = false;
            
            if( x.status && (x.data['fields'] != undefined) && (x.data['fields']['name'] != undefined) ){
                this.info = x.data.fields;
            }
        });
    }

    public download(){
        var url = this.download_url;
        var from = '';

        if( this.currdate == '' ){
            Swal.fire('Debe seleccionar fecha', '', 'error');
            return;
        }
        else{
            from = this.currdate + '-01';
        }

        this.isDownloading = true;

        this.srv.postBlobCall(url, {start: from, download: 1}).subscribe(resp => {
            this.isDownloading = false;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

}
