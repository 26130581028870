import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-form',
  templateUrl: './update-form.component.html',
  styleUrls: ['./update-form.component.css']
})
export class UpdateFormComponent extends BaseCrudComponent {

  	info:any;
	  currdate:any;
    files:any;
    isUploading = false;
    upload_url:any;
    cities:any;
    parishes:any;
    nationalities:any;
    countries:any;

    zoom = 15;
    track_lat:any;
    track_lng:any;

    MAX_EDU = 1;
    levels:any;
    carriers:any;
    titles:any;
    raw_titles:any;

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'employee/update';
        this.create_url = 'employee/update';
        this.upload_url = '';
        this.update_url = '';

        this.info = false;
        this.currdate = '';
        this.files = [];
        this.parishes = [];
        this.cities = [];
        this.nationalities = [];
        this.countries = [];

        this.levels = [];
        this.carriers = [];
        this.titles = [];
        this.raw_titles = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
    	this.trackMe();
        super.ngOnInit();
        //this.getFilters();

        this.storage.get('common').then(c => {
            this.levels = c['data']['edu'];
            this.carriers = c['data']['area'];
            this.raw_titles = c['data']['grade'];
            this.getList();
        });
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        //validate education
        if( form['EDUCATION'] && Array.isArray(form['EDUCATION']) && (form['EDUCATION'].length > 0) ){
            form['EDUCATION'].forEach(e => {
                /*validated = this.validateEducation(form['EDUCATION'][0]);
                if( !validated ){
                    Swal.fire('Campos requeridos en educaci&oacute;n', '', 'error');
                    return;
                }*/

                validated = this.validateEducation(e);
                if( !validated ){
                    Swal.fire('Campos requeridos en educaci&oacute;n', '', 'error');
                    return;
                }
            });
        }

        var to:any;
        to = form;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.getList();
                //this.closeModal(content);
                Swal.fire(this.translations.updated, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    /*public getFilters(){
        var qst = 'employee/filters';

        this.srv.getCall(qst).subscribe(x => {
            if(x.status){
                this.cities = x.data.rows.cities;
                //this.nationalities = x.data.rows.nationalities;
                this.countries = x.data.rows.countries;
            }
        });
    }*/

    public getFilters(){
        var qst = 'employee/filters';

        return this.srv.getCall(qst);
    }

    public filterParishes(id:any){
        this.parishes = [];

        if( id != '' ){
            let test = this.cities.filter(p => {
                return p.id == id;
            });

            if( test.length > 0 ){
            	this.parishes = test[0]['parishes'];
            }
        }
        else{
        	this.parishes = [];
        }
    }

    openLocationModal(content:any, obj:any){
        this.u_obj = obj;
        let coords:any;

        /*this.u_obj.lat = '';
		this.u_obj.lng = '';*/

		if( (this.u_obj.casa_coordenadas != undefined) && (this.u_obj.casa_coordenadas != '') ){
			coords = this.u_obj.casa_coordenadas.split(';');
			this.u_obj.lat = parseFloat(coords[0]);
            this.u_obj.lng = parseFloat(coords[1]);
		}
        else{
            this.u_obj.lat = this.track_lat;
            this.u_obj.lng = this.track_lng;
        }

        super.openCreateModalLG(content);
        //this.marker={"name":"", "lat":this.u_obj.lat, "lng":this.u_obj.long};
    }

    public placeMarker($event){
        this.u_obj.lat = $event.coords.lat;
        this.u_obj.lng = $event.coords.lng;
    }

    trackMe() {
  	    if (navigator.geolocation) {
  	    	//console.log('tracking');
  	      	//this.isTracking = true;

  	      	navigator.geolocation.getCurrentPosition((position) => {
	        	this.track_lat = position.coords.latitude;
	        	this.track_lng = position.coords.longitude;
	        	//console.log(this.track_lat);
  	      	});

  	      	navigator.geolocation.watchPosition((position) => {
	        	this.track_lat = position.coords.latitude;
	        	this.track_lng = position.coords.longitude;
	        	//console.log(this.track_lat);
  	      	});
  	    } else {
  	       alert("Geolocation is not supported by this browser.");
  	    }
  	}

  	setCurrentLocation(obj:any){
  		if( (this.track_lat != undefined) && (this.track_lat != '') ){
  			obj.lat = this.track_lat;
        	obj.lng = this.track_lng;
  		}
  	}

  	useLocation(content:any, obj:any){
  		let str = obj.lat + ';' + obj.lng;
  		this.rows['casa_coordenadas'] = str;
  		this.closeModal(content);
  	}

  	addDependant(){
  		if( !Array.isArray(this.rows['CARGAS']) ){
  			this.rows['CARGAS'] = [];
  		}

  		let obj:any = {};
  		obj.NOMBRES = '';
  		obj.APELLIDOS = '';
  		obj.PARENTESCO = '';
  		obj.NUM_DOCUMENTO = '';
  		obj.NACIONALIDAD = '';
  		obj.habilitado = 'S';
  		obj.actividad_economica = '';
  		obj.instruccion = '';
  		obj.FECHA_NAC = '';
  		obj.PAIS_NAC = '';
  		obj.GENERO = '';
  		obj.EDAD = '';
  		obj.sap = 0;
  		obj.TIPO_DOCUMENTO = 'Cedula de Ciudadania';

  		this.rows['CARGAS'].unshift(obj);
  	}

  	removeDependant(haystack:any, i:number){
  		haystack.splice(i, 1);
  	}

    public getList(){
        var qst = this.read_url;
        
        this.loadingList = true;

        this.getFilters().subscribe(f => {
            if(f.status){
                this.cities = f.data.rows.cities;
                //this.nationalities = f.data.rows.nationalities;
                this.countries = f.data.rows.countries;
            }

            this.srv.getCall(qst).subscribe(x => {
                this.loadingList = false;

                if(x.status){
                    this.rawdata = x.data.rows;
                    this.rows = x.data.rows;
                    this.page = 1;

                    if( Array.isArray(this.rows) ){
                        this.pageTotal = this.rows.length;
                    }

                    console.log(this.rows['canton']);
                    this.filterParishes(this.rows['canton']);

                    if( Array.isArray(this.rows['EDUCATION']) && (this.rows['EDUCATION'].length > 0) ){
                        this.filterTitles(this.rows['EDUCATION'][0]['edu_id']);
                    }
                }
                else{
                    Swal.fire(x.message, '', 'info');
                }
            });
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'parroquia': new FormControl(fields.parroquia, [Validators.required]),
            'canton': new FormControl(fields.canton, [Validators.required]),
            'sector': new FormControl(fields.sector, [Validators.required]),
            'casa_construccion': new FormControl(fields.casa_construccion, [Validators.required]),
            'casa_propiedad': new FormControl(fields.casa_propiedad, [Validators.required]),
            'CELULAR': new FormControl(fields.CELULAR, [Validators.required]),
            'email_personal': new FormControl(fields.email_personal, [Validators.required]),
            'contacto_emergencia': new FormControl(fields.contacto_emergencia, [Validators.required]),
            'numero_emergencia': new FormControl(fields.numero_emergencia, [Validators.required])
        });

        return mygroup.valid;
    }

    addEducation(){
      if( !Array.isArray(this.rows['EDUCATION']) ){
        this.rows['EDUCATION'] = [];
      }

      /*if( this.rows['EDUCATION'].length >= this.MAX_EDU ){
          return;
      }*/

      let obj:any = {};
      obj.inst_name = '';
      obj.edu_id = '';
      obj.disabled = false;

      this.rows['EDUCATION'].unshift(obj);
    }

    removeEducation(haystack:any, i:number){
      haystack.splice(i, 1);
    }

    public filterTitles(id:any){
        var resp:boolean;
        this.titles = [];

        if( id != '' ){
            let test = this.raw_titles.filter(s => {
                return s.parent_id == id;
            });

            this.titles = test;
        }
        else{
            this.titles = [];
        }
    }

    validateEducation(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'edu_id': new FormControl(fields.edu_id, [Validators.required]),
            'inst_name': new FormControl(fields.inst_name, [Validators.required]),
            'country_id': new FormControl(fields.country_id, [Validators.required]),
            'grade_id': new FormControl(fields.grade_id, [Validators.required]),
            //'area_id': new FormControl(fields.area_id, [Validators.required]),
            'start': new FormControl(fields.start, [Validators.required]),
            'end': new FormControl(fields.end, [Validators.required])
        });

        return mygroup.valid;
    }

    private getFilterStr(id:any, cat:any){
        var str = '';
        var resp:any;

        resp = this[cat].filter(f => {
          return f.id == id;
        })

        if( resp.length > 0 ){
          str = resp[0]['description'];
        }

        return str;
    }

}
