import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { Router, Params, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ReturnStatement } from '@angular/compiler';
import { Observable, from, of } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import * as moment from 'moment-timezone';

//max time for api requests
const MAX_TIME = 60000;
const TOKEN_KEY = 'token';
const REFRESHTOKEN_KEY = 'refresh';
const PERMISSIONS_KEY = 'permissions';
const TOKEN_EXPIRES_PERIOD = 20;

@Injectable({
  providedIn: 'root'
})

export class LoginService implements CanActivate {

  private url: any;
  private token: any;
  public cont: number;
  momentjs: any = moment;

  pages = [
      {id: 'admins', name: "ADMINS"},
      {id: 'users', name: "ADMINS-USUARIOS"},
      {id: 'roles', name: "ADMINS-ROLES"},
      {id: 'events', name: "ADMINS-COMUNICADOS"},
      {id: 'banners', name: "ADMINS-BANNERS"},
      {id: 'docapproval', name: "ADMINS-ESQUEMA_APROBACION_DOCUMENTOS"},
      {id: 'absence_approval', name: "ADMINS-APROBACION_PERMISOS"},
      {id: 'dependant_approval', name: "ADMINS-APROBACION_CARGAS_FAMILIARES"},
      {id: 'loan_approval', name: "ADMINS-APROBACION_PRESTAMOS"},
      {id: 'advance_approval', name: "ADMINS-APROBACION_ANTICIPOS"},
      {id: 'vacation_approval', name: "ADMINS-APROBACION_VACACIONES"},
      {id: '', name: "ADMINS-APROBACION_VIATICOS"},
      {id: 'staff', name: "ADMINS-REQUERIMIENTO_PERSONAL"},
      {id: 'staffapproval', name: "ADMINS-REQUERIMIENTO_PERSONAL_APROBACION"},
      {id: 'payroladmin', name: "ADMINS-ROLES_PAGO"},
      {id: 'mealhome', name: "ADMINS-ALIMENTACION"},
      {id: 'mealbooking', name: "ADMINS-ALIMENTACION-CREACION_MENU"},
      {id: 'mealcrud', name: "ADMINS-ALIMENTACION-CREACION_PLATOS"},
      {id: 'mealcategory', name: "ADMINS-ALIMENTACION-CREACION_CATEGORY"},
      {id: 'monthlyreport', name: "ADMINS-ALIMENTACION-REPORTES_MENSUAL"},
      {id: 'providerreport', name: "ADMINS-ALIMENTACION-REPORTES_PROVEEDOR"},
      {id: 'dailyreport', name: "ADMINS-ALIMENTACION-REPORTES_DETALLE"},
      {id: 'polls', name: "ADMINS-ENCUESTAS"},
      {id: 'pollreport', name: "ADMINS-ENCUESTAS_REPORT"},
      {id: 'kitchen', name: "ADMINS-COCINA"},
      {id: 'kitchenprocess', name: "ADMINS-COCINA-DESPACHO"},
      {id: 'kitchendelivered', name: "ADMINS-COCINA-REPORTE_DESPACHO"},
      {id: 'branches', name: "ADMINS-SUCURSALES"},
      {id: 'subsidies', name: "ADMINS-SUBSIDIOS"},
      {id: 'feeding', name: "SERVICIOS-ALIMENTACION"},
      {id: 'lunch', name: "SERVICIOS-ALIMENTACION-ALMUERZOS"},
      {id: 'dinner', name: "SERVICIOS-ALIMENTACION-MERIENDAS"},
      {id: 'certificate', name: "SERVICIOS-CERTIFICADO_LABORAL"},
      {id: 'payrol', name: "SERVICIOS-ROL_PAGOS"},
      {id: 'profile', name: "SERVICIOS-MI_PERFIL"},
      {id: 'absences', name: "SERVICIOS-PERMISOS"},
      {id: 'advances', name: "SERVICIOS-ANTICIPOS"},
      {id: 'extadvances', name: "SERVICIOS-ANTICIPOS_EXTRAORDINARIOS"},
      {id: 'vacations', name: "SERVICIOS-VACACIONES"},
      {id: 'vacations_create', name: "SERVICIOS-VACACIONES-CREAR"},
      {id: 'benefits', name: "SERVICIOS-BENEFICIOS"},
      {id: 'loans', name: "SERVICIOS-PRESTAMOS"},
      {id: '', name: "SERVICIOS-VIATICOS"},
      {id: 'orgchart', name: "SERVICIOS-ORGANIGRAMA"},
      {id: 'account', name: "SERVICIOS-ESTADO_CUENTA"},
      {id: 'commissions', name: "ADMINS-COMISIONES"},
      {id: 'comrol', name: "SERVICIOS-ROL_COMISIONES"},
      {id: 'employee_report', name: "ADMINS-REPORTE_EMPLEADOS"},
      {id: 'settings', name: "ADMINS-SETTINGS"},
      {id: 'reports', name: "ADMINS-REPORTES"},
      {id: 'advance_report', name: "ADMINS-REPORTES-REPORTE_ANTICIPOS"},
      {id: 'vacation_report', name: "ADMINS-REPORTES-REPORTE_VACACIONES"},
      {id: 'absence_query', name: "ADMINS-CONSULTA_PERMISOS"},
      {id: 'payrol_query', name: "ADMINS-CONSULTA_ROLES_PAGO"},
      {id: 'payrolspecial_query', name: "ADMINS-CONSULTA_NOMINAS_ESPECIALES"},
      {id: 'vacation_query', name: "ADMINS-CONSULTA_VACACIONES"},
      {id: 'advance_query', name: "ADMINS-CONSULTA_ANTICIPOS"},
      {id: 'advanceext_query', name: "ADMINS-CONSULTA_ANTICIPOS_EXTRA"},
      {id: 'loan_query', name: "ADMINS-CONSULTA_PRESTAMOS"},
      {id: 'my_collabs', name: "ADMINS-MIS_COLABORADORES"},
      {id: 'adminlunch', name: "ADMINS-ALIMENTACION-ALMUERZOS"},
      {id: 'admindinner', name: "ADMINS-ALIMENTACION-MERIENDAS"},
      {id: 'remote-work', name: "SERVICIOS-MARCACIONES"},
      {id: 'remotework_report', name: "ADMINS-REPORTES-REPORTE_MARCACIONES"}
    ];

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private storage: Storage,
    private route: ActivatedRoute
  ) {
      this.url = Global.url;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      var page = state.url;

      return this.storage.get(TOKEN_KEY).then(token => {
          if( (token == null) || (token == undefined) ){
              this._router.navigate(['/authentication/login']);
              return false;
          }
          else{
              return this.getPermissions().then(mods => {
                  let permissions = ['dashboard', 'home', 'collaborators'];
                  let ipage:any;
                  //console.log(mods);
                  //return true;

                  if( !Array.isArray(mods) ){
                      this.clearData().then(() => {
                          this._router.navigate(['/authentication/login']);
                      });
                      return false;
                  }

                  mods.forEach(i => {
                      ipage = this.getPageIdFromModule(i);
                      if( ipage != false ){
                          permissions.push(ipage);
                      }
                  });

                  //console.log(page);
                  //console.log(permissions);
                  page = page.replace("/", "");

                  let checker = permissions.filter(p => {
                        //return page.toLowerCase().includes(p.toLowerCase());
                        return page.toLowerCase() == p.toLowerCase();
                  });

                  //console.log(checker);

                  if( checker.length > 0 ){
                      return true;
                  }
                  else{
                      this._router.navigate(['/home']);
                      /*this.clearData().then(() => {
                          this._router.navigate(['/authentication/login']);
                      });*/

                      return false;
                  }
        });

          }
      });
  }

  getPageIdFromModule(md){
      let tmp = [];
      tmp = this.pages.filter(function(a){
                        return a.name == md;
                      });

      if( tmp.length > 0 ){
          return tmp[0].id;
      }

      return false;
  }

  getPermissions(){
      return this.storage.get(PERMISSIONS_KEY).then(p => {
          return this.getSystemInfo().then(sys => {
              return this.getTeam().then(t => {

                  let resp = [];
                  let team_perms = [];

                  if( (sys == null) || (sys == undefined) ){
                    return resp;
                  }

                  sys.teams.forEach(i => {
                      if( i.id == t ){
                          
                          i.permissions.forEach(ip => {
                              team_perms.push(ip);
                          });

                          team_perms = this.parsePermissions(team_perms);
                      }
                  });

                  resp = p.filter(x => {
                      return team_perms.indexOf(x) !== -1;
                  });
                  
                  return resp;
              });
          });
      });
  }

  private parsePermissions(rows:any){
        var perms = [];

        rows.forEach(x => {
            //let t = x.name;
            let p = x.split('-');
            let key = '';
            let i = 0;

            if( Array.isArray(p) ){
                p.forEach(z => {
                    if( i > 0 ){
                        key += '-' + z;
                    }
                    else{
                        key = z;
                    }

                    if( perms.indexOf(key) < 0 ){
                        perms.push(key);
                    }

                    i++;
                });
            }
        });

        return perms;
  }

  public login(data: any) {
      const params = JSON.stringify(data);
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this._http.post(`${this.url}auth/login`, params, { headers });
  }

  public validateUser(data: any) {
      const params = JSON.stringify(data);
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this._http.post(`${this.url}auth/validate`, params, { headers });
  }

  public forgot(data: any) {
      const params = JSON.stringify(data);
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this._http.post(`${this.url}password/email`, params, { headers });
  }

  public reset(data: any) {
      const params = JSON.stringify(data);
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this._http.post(`${this.url}password/reset`, params, { headers });
  }

  public firstLogin(data: any) {
      const params = JSON.stringify(data);
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this._http.post(`${this.url}password/first`, params, { headers });
  }

  public saveData(data: any) {
      this.cont = 0;
      var promises = [
          this.storage.set('userinfo', data['login']),
          this.storage.set('token', data['token_fks']),
          this.storage.set('team', data['login']['team_id'])
      ];

      return Promise.all(promises);
      //return this.storage.set('token', data['token_fks']);
  }

  public logout(){
      return this.storage.clear();
  }

  public getInfo(){
      return this.storage.get('userinfo');
  }

  public getSystemInfo(){
      return this.storage.get('sys');
  }

  public getTeam(){
      return this.storage.get('team');
  }

  public setTeam(val){
      return this.storage.set('team', val);
  }

  public clearData(){
      return this.storage.clear();
  }

  public hasRole(items:String[]){
      return this.storage.get('sys').then(s => {
          let roles = s.settings.allroles;
          let resp = false;

          roles.forEach(r => {
                if( items.indexOf(r.name) > -1 ){
                  resp = true;
                }
          });

          return resp;
      });
  }

  public hasPermission(str:string){
      return this.getPermissions().then(x => {
          let permissions = x;
          //console.log(permissions);
          
          if( permissions.indexOf(str) > -1 ){
              return true;
          }
          else{
              return false;
          }
      });
  }

}
