import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-docapproval',
  templateUrl: './docapproval.component.html',
  styleUrls: ['./docapproval.component.css']
})
export class DocapprovalComponent extends BaseCrudComponent {

  	@Input() profile: any;

    translations:any;
    listUsers:any;
    listDocs:any;
    loadingRequired:any;
    loadingApprovals:any;
    reassign_url:any;
    reassign_all = false;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'docapproval';
        this.create_url = 'docapproval';
        this.update_url = 'docapproval';
        this.reassign_url = 'docapproval/reassign';
        this.delete_url = 'docapproval/del';
        this.search_fields = ['userto_str', 'responsible_str', 'doctype_str'];
        this.listUsers = [];
        this.listDocs = [];
        this.loadingRequired = false;
        this.loadingApprovals = false;
        this.u_obj['items'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        this.loadingList = true;
        //this.srv.postCall(qst, tosend).subscribe(x => {
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                    this.rows.forEach(x => {
                    	//used for search bar
                    	x.doctype_str = x.doctype.description;
                    	x.userto_str = x.userto.name;
                    	x.responsible_str = x.responsible.name;
                    });
                }

                this.filterAll();
            }
        });
    }

    public getFiltersData(){
        var par_url = 'docapproval/filters';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                          return of(x.data.rows);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        this.c_obj = {};

        super.openCreateModal(content);
        this.loadingRequired = true;
        this.getFiltersData().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.listUsers = x['users'];
        		this.listDocs = x['docs'];
            }
        });
    }

    openUpdateModal(content:any, obj:any){
        obj['doc_id'] = "-1";
        obj['items'] = [];

        super.openUpdateModal(content, obj);
        this.loadingRequired = true;
        this.getFiltersData().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.listUsers = x['users'];
        		this.listDocs = x['docs'];
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'user_id': new FormControl(fields.user_id, [Validators.required]),
            'doc_id': new FormControl(fields.doc_id, [Validators.required]),
            'responsible_id': new FormControl(fields.responsible_id, [Validators.required]),
            'approve_order': new FormControl(fields.approve_order, [Validators.required])/*,
            'is_required': new FormControl(fields.is_required, [Validators.required])*/
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = {};
        to.user_id = form['user_id']['id'];
        to.responsible_id = form['responsible_id']['id'];
        to.doc_id = form['doc_id'];
        to.approve_order = form['approve_order'];
        //to.is_required = form['is_required'];
        to.is_required = 1;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public reassignItems(content:any, form:any){
        if( form == undefined ){
          form = {};
        }

        if( (form['responsible_from'] == undefined) || (form['responsible_from']['id'] == undefined) ){
            Swal.fire('Seleccione Aprobador Actual', '', 'error');
            return;
        }

        if( (form['responsible_to'] == undefined) || (form['responsible_to']['id'] == undefined) ){
            Swal.fire('Seleccione Nuevo Aprobador', '', 'error');
            return;
        }

        if( (form['items'] == undefined) || !Array.isArray(form['items']) ){
            Swal.fire('No hay registros seleccionados', '', 'error');
            return;
        }

        let rows = [];
        form['items'].forEach(i => {
            if( i.sel ){
                rows.push(i.id);
            }
        });

        if( rows.length <= 0 ){
            Swal.fire('No hay registros seleccionados', '', 'error');
            return;
        }

        var to:any;
        to = {};
        to['approv'] = form['responsible_to']['id'];
        to['items'] = rows;

        //console.log(to);

        var qst = this.reassign_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            //console.log(x);
            if(x.status){
                this.getList();
                this.closeModal(content);
                Swal.fire('Actualizados ' + x.data.processed + ' registro(s)', '', 'success');
            }//end success
            else{
                Swal.fire(x.message, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    searchUsers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listUsers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterUsers = (x: any) => x.name;

    public getListByResponsible(obj:any){
        var qst = this.read_url;

        if( (obj['responsible_from'] == undefined) || (obj['responsible_from']['id'] == undefined) ){
            Swal.fire('Seleccione Aprobador actual', '', 'error');
            return;
        }

        if( (obj['doc_id'] == undefined) ){
            Swal.fire('Seleccione Documento', '', 'error');
            return;
        }

        var tosend = {approv: obj['responsible_from']['id'], doc_id: obj['doc_id']};
        obj['items'] = [];

        if( (obj['user_to'] != undefined) && (obj['user_to']['id'] != undefined) ){
            tosend['userto'] = obj['user_to']['id'];
        }

        this.loadingApprovals = true;
        //this.srv.postCall(qst, tosend).subscribe(x => {
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingApprovals = false;
            
            if(x.status){
                let rows = x.data.rows;

                if( Array.isArray(rows) ){
                    rows.forEach(x => {
                        //x.sel = false;
                        x.sel = true;
                    });

                    obj['items'] = rows;
                }
            }
        });
    }

    public async selectAllItems(){
        console.log(this.reassign_all);
        //console.log(this.u_obj['items']);
        let val = this.reassign_all;
        this.loadingRequired = true;

        await this.u_obj['items'].forEach(x => {
            x.sel = val;
        });

        this.loadingRequired = false;
    }

}
