import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gpreport',
  templateUrl: './gpreport.component.html',
  styleUrls: ['./gpreport.component.css']
})
export class GpreportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
