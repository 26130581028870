import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent extends BaseCrudComponent {

    translations:any;
    listUsers:any;
    listDocs:any;
    listPerms:any;
    loadingRequired:any;
    roles:any;
    teams:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'admin/users/list';
        this.create_url = 'admin/role';
        this.update_url = 'admin/user';
        this.delete_url = 'admin/user/del';
        this.search_fields = ['name', 'email', 'ucode', 'wbu_ced'];
        this.roles = [];
        this.teams = [];
        this.loadingRequired = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        
        this.loadingList = true;
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;

            if(x.status){
                x.data.rows.forEach(r => {
                    r.role_id = 0;
                    if( Array.isArray(r.roles) && (r.roles.length > 0) ){
                        r.role_id = r.roles[0].id;
                    }
                });

                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
            }
            else{
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public getRolesFilterData(){
        var par_url = 'admin/role';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                          return of(x.data.rows);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getTeamsFilterData(){
        var par_url = 'team/list';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                          return of(x.data.rows);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        this.c_obj = {};
        this.c_obj.roles = [];
        this.c_obj.teams = [];

        super.openCreateModal(content);
        this.loadingRequired = true;
        this.getRolesFilterData().subscribe(x => {
            //this.loadingRequired = false;
            if(x != false){
                if( Array.isArray(x) ){
                    x.forEach(i => {
                        this.c_obj.roles.push(i);
                    });
                }
            }

            this.getTeamsFilterData().subscribe(x => {
                this.loadingRequired = false;
                if(x != false){
                    if( Array.isArray(x) ){
                        x.forEach(i => {
                            this.c_obj.teams.push(i);
                        });
                    }
                }
            });
        });
    }

    openUpdateModal(content:any, obj:any){
        this.roles = [];
        this.teams = [];
        super.openUpdateModal(content, obj);

        this.loadingRequired = true;
        this.getRolesFilterData().subscribe(x => {
            //this.loadingRequired = false;
            if(x != false){
                if( Array.isArray(x) ){
                    x.forEach(i => {
                        this.roles.push(i);
                    });
                }
            }

            this.getTeamsFilterData().subscribe(x => {
                this.loadingRequired = false;
                if(x != false){
                    if( Array.isArray(x) ){
                        x.forEach(i => {
                            this.teams.push(i);
                        });
                    }
                }
            });
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'name': new FormControl(fields.name, [Validators.required]),
            'display_name': new FormControl(fields.display_name, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = {};
        to.name = form['name'];
        to.display_name = form['display_name'];
        to.permissions = [];

        form.permList.forEach(x => {
            if( x.sel ){
                to.permissions.push({id: x.id, selected:x.sel});
            }
        });

        if( to.permissions.length <= 0 ){
            Swal.fire('No permisos seleccionados', '', 'error');
            return;
        }

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        /*validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }*/

        var to:any;
        to = {};
        to.user = form;
        to.role_id = form['role_id'];

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.status){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.message, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    sendQR(id){
        var qrbatch_url = 'profile/batch';
        var items = [];
        items.push({id: id});
        var to = {items: items};

        Swal.fire({
            title: '¿Esta seguro que desea enviar QR?',
            text: `Si acepta se enviara código QR al mail del usuario`,
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.value) {
                this.srv.postCall(qrbatch_url, to).subscribe(x => {
                    if(x.status){
                        Swal.fire('¡Enviado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            }
        });
    }

}
