import { Component, OnInit, ViewChild } from '@angular/core';
import { MapsService } from '../../../services/main/maps.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';

declare const google: any;

@Component({
  selector: 'app-map-google',
  templateUrl: './map-google.component.html',
  styleUrls: ['./map-google.component.scss']
})

export class MapgoogleComponent implements OnInit {
    @ViewChild('map3', { static: true }) map3;

    rows: any;
    curr: any;
    curr_polygon: any;
    lat: number = -2.1566569;
    lng: number = -79.9155977;
    zoom: number = 13;
    alertcolor = "#FF0000";
    lineWeight = 0.5;
    showSidebar = false;
    showCreate = false;
    openedWindow : number = 0;

    sellers: any;
    customers: any;
    customers_markers: any;
    public c_obj: any;
    public u_obj: any;
    isLoading = false;
    isDownloading = false;
    isUpdating = false;

    page:any;
    pageTotal:any;
    pageSize:any;

    constructor(
      private serv: MapsService,
      private modalService: NgbModal,
      private storage: Storage
    ) {
        this.rows = [];
        this.curr = {};
        this.curr_polygon = null;
        this.c_obj = {};
        this.u_obj = {};

        this.sellers = [];
        this.customers = [];

        //pagination
        this.page = 1;
        this.pageTotal = 0;
        this.pageSize = 20;
    }

    ngOnInit() {
        this.getZones();
    }

    public toggleSideBar(){
        this.showSidebar = !this.showSidebar;
    }

    openWindow(id) {
        this.openedWindow = id; // alternative: push to array of numbers
    }

    closeWindow(){
        this.openedWindow = 0;
    }

    isInfoWindowOpen(id) {
        return this.openedWindow == id; // alternative: check if id is in array
    }

    public getZones(){
        this.isLoading = true;
        this.serv.getAllZones().subscribe((rest) => {
            this.isLoading = false;
            if(rest.status){
                //console.log(rest.data.rows);
                this.rows = rest.data.rows;
                this.rows.forEach(r => {
                    r.paths = JSON.parse(r.coord_path);
                });

                this.sellers = rest.data.sellers;
                //this.customers = rest.data.customers;
                var customers = rest.data.customers;
                this.customers = [];

                customers.forEach(x => {
                    this.customers.push({
                        id: x.id,
                        doc_id: x.doc_id,
                        name: x.name,
                        lat: x.lat,
                        lng: x.long,
                        visit: parseFloat(x.visit_time),
                        period: parseInt(x.visit_freq_month),
                        amount: parseFloat(x.avg_sale)/*,
                        LatLng: new google.maps.LatLng(x.lat,x.long)*/
                    });
                });

                //console.log(this.customers);

                //fill and calculate all info to show
                this.rows.forEach(r => {
                    this.parseZone(r);
                });

                this.page = 1;
                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
            }
        });
    }

    public enableSidebar(){
        this.showSidebar = true;
    }

    public disableSidebar(){
        this.showSidebar = false;
        this.u_obj = {};
    }

    public enableCreateSidebar(){
        this.showCreate = true;
    }

    public disableCreateSidebar(){
        this.showCreate = false;
        this.clearCreateData();
    }

    public showZoneDetails(obj:any){
        this.u_obj = {};
        this.u_obj = obj;
        //this.parseZones(obj);
        this.enableSidebar();
    }

    private parseZones(rs:any){
        var tmp:any;
        tmp = {};
        tmp.id = rs.id;
        tmp.name = rs.name;

        tmp.seller_name = '';
        tmp.seller = this.searchSeller(rs.seller_id);
        if( tmp.seller.length > 0 ){
            tmp.seller_name = tmp.seller[0].name;
        }

        tmp.customers = [];

        rs.details.forEach(x => {
            x.customer = this.searchCustomer(x.customer_id);
            if( x.customer.length > 0 ){
                x.customer = x.customer[0];
                tmp.customers.push({
                    id: x.customer.id,
                    doc_id: x.customer.doc_id,
                    name: x.customer.name,
                    lat: x.customer.lat,
                    lng: x.customer.long,
                    visit: parseFloat(x.customer.visit_time),
                    period: parseInt(x.customer.visit_freq_month),
                    amount: parseFloat(x.customer.avg_sale),
                    order: x.visit_order
                });
            }
        });

        this.u_obj = tmp;
        this.calculateAverages(this.u_obj);
    }

    private parseZone(rs:any){
        rs.seller_name = '';
        rs.seller = this.searchSeller(rs.seller_id);
        if( rs.seller.length > 0 ){
            rs.seller_name = rs.seller[0].name;
        }

        rs.customers = [];

        rs.details.forEach(x => {
            x.customer = this.searchCustomer(x.customer_id);
            if( x.customer.length > 0 ){
                x.customer = x.customer[0];
                x.customer.order = x.visit_order;
                rs.customers.push(x.customer);
            }
        });

        this.calculateAverages(rs);
        //console.log(rs);
    }

    private searchCustomer(id){
        var resp:any;
        resp = this.customers.filter(x => { return x.id == id; });
        return resp;
    }

    private searchSeller(id){
        var resp:any;
        resp = this.sellers.filter(x => { return x.id == id; });
        return resp;
    }

    public showBasicInfo(ev, r){
        this.openWindow(r.id);
    }

    public clearCreateData(){
        if( this.curr_polygon != null ){
            this.curr_polygon.setMap(null);
        }

        this.c_obj = {};
        this.isLoading = false;
    }

    public onZoneCreated(ev:any){
        this.clearCreateData();
        this.curr_polygon = ev;
        this.showCreate = true;
        var path = [];
        this.c_obj.customers = [];
        this.c_obj.path = [];
        var tmp:any;
        var counter = 1;

        var coords = ev.getPath().getArray();
        coords.forEach(y => {
            this.c_obj.path.push({
                lat: y.lat(),
                lng: y.lng()
            });
        });

        this.customers.forEach(x => {
            tmp = new google.maps.LatLng(x.lat,x.lng);
            x.gobj = tmp;
            var isWithinPolygon = google.maps.geometry.poly.containsLocation(tmp, ev);
            //validate customer do not belong another zone
            if(isWithinPolygon){
                x.order = counter;
                this.c_obj.customers.push(x);
                counter++;
            }
        });

        if(this.c_obj.customers.length <= 0){
            this.disableCreateSidebar();
            return;
        }
        
        this.calculateAverages(this.c_obj);
        this.calculateTravelTime(this.c_obj);
    }

    calculateTravelTime(obj:any){
        obj.avg_route = 0;
        //this.c_obj = obj.avg_route = 0; //same as above
        var origins = [];
        var destinations = [];
        var rs:any;
        rs = [];
        var i = 0;

        obj.customers.forEach(c => {
            rs.push({
                order: c.order,
                gobj: c.gobj
            });
        });

        if( rs.length > 1 ){
            rs.sort((a, b) => (a.order > b.order) ? 1 : -1);

            /*for(i=0; i<rs.length-1; i++ ){
                origins.push(rs[i].gobj);
            }

            for(i=1; i<rs.length; i++ ){
                destinations.push(rs[i].gobj);
            }

            console.log(rs);
            console.log(origins);
            console.log(destinations);

            var service = new google.maps.DistanceMatrixService();
            service.getDistanceMatrix(
              {
                origins: origins,
                destinations: destinations,
                travelMode: 'DRIVING'
              }, this.setTravelTime);*/

            var service = new google.maps.DistanceMatrixService();
            var curr = this;
            for(i=0; i < rs.length - 1; i++){
                service.getDistanceMatrix(
                  {
                    origins: [rs[i].gobj],
                    destinations: [rs[i+1].gobj],
                    travelMode: 'DRIVING'
                  }, function(resp:any, status:any){curr.setTravelTime(resp, status, obj);});
            }
        }
    }

    setTravelTime(resp:any, status:any, rs:any){
        /*console.log(this);
        console.log(resp);*/
        console.log(status);
        var sum = 0;
        if( status == 'OK' ){
            sum = resp.rows[0]['elements'][0]['duration']['value'];
            /*sum = sum / 3600;
            sum = Math.round(sum);*/
        }
        console.log(sum);

        this.c_obj.avg_route += sum;
    }

    public removeCustomer(id){
        var i = 0;
        var rm:any;
        rm = null;

        if( this.c_obj.customers.length > 0 ){
            this.c_obj.customers.forEach(c => {
                if(c.id == id){
                    rm = i;
                }

                i++;
            });

            if( rm != null ){
                this.c_obj.customers.splice(rm, 1);
            }
        }

        this.calculateAverages(this.c_obj);
    }

    public calculateAverages(obj:any){
        var total_time = 0;
        var avg_amount = 0;
        //var avg_route = 0;
        var total_amount = 0;
        var i = 0;

        obj.customers.forEach(x => {
            total_time += x.visit * x.period;
            total_amount += x.amount;
            i++;
        });

        //avg_amount = total_amount / i;
        avg_amount = total_amount;
        avg_amount = Math.round(avg_amount);

        //obj.total_time = total_time / 60;
        obj.total_time = total_time;
        obj.avg_amount = avg_amount;
        //obj.avg_route = avg_route;
    }

    public createItem(obj:any){
        if( (obj.name == undefined) || (obj.name == '') ){
            Swal.fire('Ingrese nombre', '', 'warning');
            return;
        }

        /*if( (obj.seller == undefined) || (obj.seller == null) ){
            Swal.fire('Ingrese vendedor', '', 'warning');
            return;
        }*/

        if( (obj.customers == undefined) || (obj.customers.length <= 0) ){
            Swal.fire('No hay clientes seleccionados', '', 'warning');
            return;
        }

        this.isLoading = true;
        //console.log(obj);

        this.serv.saveZoneAPI(obj).subscribe((rest) => {
            this.isLoading = false;
            if(rest.status){
                this.getZones();
                this.disableCreateSidebar();
                Swal.fire('Zona creada', '', 'success');
            }
            else{
                Swal.fire(rest.message, '', 'error');
            }
        });

    }

    public updateItem(obj:any){
        if( (obj.seller_id == undefined) || (obj.seller_id == null) ){
            Swal.fire('Ingrese vendedor', '', 'warning');
            return;
        }

        this.isUpdating = true;

        this.serv.updateZoneAPI(obj.id, obj).subscribe((rest) => {
            this.isUpdating = false;
            if(rest.status){
                this.getZones();
                this.disableSidebar();
                Swal.fire('Zona actualizada', '', 'success');
            }
            else{
                Swal.fire(rest.message, '', 'error');
            }
        });

    }

    public deleteItem(id) {
        Swal.fire({
            title: '¿Esta seguro que desea eliminarlo?',
            text: `Si acepta se eliminará el registro`,
            icon: 'warning',
            showCancelButton: true,
            /*confirmButtonColor: '#f39000',
            cancelButtonColor: '#80a41b',*/
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar'
        }).then((result) => {
            if (result.value) {
                this.isLoading = true;
                
                this.serv.deleteZoneAPI(id).subscribe((rest) => {
                    this.isLoading = false;
                    if(rest.status){
                        this.getZones();
                        this.disableSidebar();
                        Swal.fire('Zona eliminada', '', 'success');
                    }
                    else{
                        Swal.fire(rest.message, '', 'error');
                    }
                });
            }
        });
    }

    public download(){
        this.isDownloading = true;
        this.serv.downloadsZonesAPI().subscribe((rest) => {
            this.isDownloading = false;
            //console.log(rest);
            
            if( rest.status ){
                /*var contentType = rest.data.headers._headers.get('content-type')[0];
                var blob = new Blob([data._body], { type: contentType });*/
                var blob = rest.data;
                var url = window.URL.createObjectURL(blob);
                window.open(url);
            }
        });
    }

}
