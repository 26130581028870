import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'user-personalinfo',
  templateUrl: './personalinfo.component.html',
  styleUrls: ['./personalinfo.component.css']
})
export class PersonalinfoComponent extends BaseCrudComponent {

  	@Input() profile: any;

    translations:any;
    sex:any;
    civil_status:any;
    countries:any;
    provinces:any;
    cities:any;
    hprovinces:any;
    hcities:any;
    hownership:any;
    htype:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'employee';
        this.create_url = 'employee';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = [];

        this.sex = [];
        this.civil_status = [];
        this.countries = [];
        this.provinces = [];
        this.cities = [];

        this.hprovinces = [];
        this.hcities = [];
        this.hownership = [];
        this.htype = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;

              this.storage.get('common').then(c => {
              		this.sex = c['data']['sex'];
              		this.civil_status = c['data']['civil'];
              		this.hownership = c['data']['home_own'];
              		this.htype = c['data']['home_type'];
              		this.countries = c['countries'];
              });

              super.ngOnInit();
              this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        this.loadingList = true;
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;

            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterProvinces(this.rows.nationality);
    			this.filterCities(this.rows.birth_state);
    			this.filterHProvinces(this.rows.home_country);
    			this.filterHCities(this.rows.home_state);
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            /*'edu_id': new FormControl(fields.edu_id, [Validators.required]),
            'inst_name': new FormControl(fields.inst_name, [Validators.required]),
            'country_id': new FormControl(fields.country_id, [Validators.required])*/
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        //validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.getList();
                //this.closeModal(content);
                Swal.fire(this.translations.updated, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public filterProvinces(id:any){
        var resp:boolean;
        this.provinces = [];
        this.cities = [];

        if( id != '' ){
            let test = this.countries.filter(c => {
                return c.id == id;
            });

            if( test.length > 0 ){
            	this.provinces = test[0]['provinces'];
            }
        }
        else{
        	this.provinces = [];
        }
    }

    public filterCities(id:any){
        var resp:boolean;
        this.cities = [];

        if( id != '' ){
            let test = this.provinces.filter(p => {
                return p.id == id;
            });

            if( test.length > 0 ){
            	this.cities = test[0]['cities'];
            }
        }
        else{
        	this.cities = [];
        }
    }

    public filterHProvinces(id:any){
        var resp:boolean;
        this.hprovinces = [];
        this.hcities = [];

        if( id != '' ){
            let test = this.countries.filter(c => {
                return c.id == id;
            });

            if( test.length > 0 ){
            	this.hprovinces = test[0]['provinces'];
            }
        }
        else{
        	this.hprovinces = [];
        }
    }

    public filterHCities(id:any){
        var resp:boolean;
        this.hcities = [];

        if( id != '' ){
            let test = this.hprovinces.filter(p => {
                return p.id == id;
            });

            if( test.length > 0 ){
            	this.hcities = test[0]['cities'];
            }
        }
        else{
        	this.hcities = [];
        }
    }

}
