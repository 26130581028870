import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { LoginService } from '../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import * as moment from 'moment-timezone';

moment.tz.setDefault('America/Guayaquil');

@Component({
  selector: 'app-hevent',
  templateUrl: './hevent.component.html',
  styleUrls: ['./hevent.component.css']
})
export class HeventComponent extends BaseCrudComponent {

  	translations:any;
    loadingRequired:any;
    types:any;
    byhour_id:any;
    momentjs: any = moment;
    listApprovers:any;
    sudo:any;
    showSudo:any;

    files:any;
    filetypes:any;
    img_exts:any;
    api_url:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'admin/event';
        this.create_url = 'admin/event';
        this.update_url = 'admin/event';
        this.delete_url = 'admin/event/del';
        this.search_fields = [];
        this.loadingRequired = false;
        this.types = [];
        this.byhour_id = 0;

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;

        this.files = [];
        this.filetypes = [];

        this.img_exts = ['png', 'jpg', 'jpeg', 'mp4'];
        this.api_url = Global.url;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();

            this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    this.getApproversData();
                }
            });
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    /*public getActualsData(){
        var par_url = 'absence/actuals';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }*/

    openCreateModal(content:any){
        this.c_obj = {};
        this.files = [];

        super.openCreateModal(content);
        this.c_obj['due_date'] = this.momentjs().format('YYYY-MM-DD');
        this.c_obj['publish_from'] = this.momentjs().format('YYYY-MM-DD');
        /*this.loadingRequired = true;
        this.getActualsData().subscribe(x => {
            this.loadingRequired = false;
            this.types = x['types'];
            this.byhour_id = x['absence_per_hour_id'];
            this.c_obj['start_date'] = this.momentjs().format('YYYY-MM-DD');
        });*/
    }

    openUpdateModal(content:any, obj:any){
    	this.files = [];
        super.openUpdateModal(content, obj);
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'title': new FormControl(fields.title, [Validators.required]),
            'due_date': new FormControl(fields.due_date, [Validators.required]),
            'publish_from': new FormControl(fields.publish_from, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            to['uid'] = this.sudo.id;
        }

        to.title = form['title'];
        to.due_date = form.due_date;
        to.publish_from = form.publish_from;

        if( form.publish_to != undefined ){
        	to.publish_to = form.publish_to;
        }

        if( form.description != undefined ){
        	to.description = form.description;
        }

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;

            if(x.status){
                var batch = [];

                if( this.files.length > 0 ){
                	this.files.forEach(i => {
			        	batch.push(this.createDetailItem(i, x.data.id));
			        });

	                this.isCreating = true;

			        concat(...batch).subscribe({
			        	complete: () => {
			        		this.isCreating = false;
			        		console.log('Complete');
			        		this.c_obj = {};
			                this.getList();
			                this.closeModal(content);
			                Swal.fire(this.translations.created, '', 'success');
			        	}
			        });
                }
                else{
                	this.c_obj = {};
	                this.getList();
	                this.closeModal(content);
	                Swal.fire(this.translations.created, '', 'success');
                }

            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            to['uid'] = this.sudo.id;
        }

        to.id = form['id'];
        to.title = form['title'];
        to.due_date = form.due_date;
        to.publish_from = form.publish_from;

        if( form.publish_to != undefined ){
        	to.publish_to = form.publish_to;
        }

        if( form.description != undefined ){
        	to.description = form.description;
        }

        var qst = this.update_url + '/' + form['id'];
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.status){
                var batch = [];

	            if( this.files.length > 0 ){
	            	this.files.forEach(i => {
			        	batch.push(this.createDetailItem(i, form['id']));
			        });

	                this.isUpdating = true;

			        concat(...batch).subscribe({
			        	complete: () => {
			        		this.isUpdating = false;
			        		console.log('Complete');
			        		this.u_obj = {};
			                this.getList();
			                this.closeModal(content);
			                Swal.fire(this.translations.updated, '', 'success');
			        	}
			        });
	            }
	            else{
	            	this.u_obj = {};
	                this.getList();
	                this.closeModal(content);
	                Swal.fire(this.translations.updated, '', 'success');
	            }
            }//end success
            else{
                Swal.fire(x.message, '', 'error');
            }

        });
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

    handleInputChange(e, obj:any) {
	    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
	    //console.log(file);
	    //this.files = [];
	    this.files.push(file);
	    //var pattern = /image-*/;
	    /*var reader = new FileReader();

	    reader.onload = (_event) => { 
	      obj.img[0].src = reader.result;
	      obj.img[0].fname = file.name;
	    }

	    reader.readAsDataURL(file);*/
	}

	public showFile(id:any){
		var url = 'event/doc/' + id;
        //window.open(url);

        this.srv.getBlobCall(url).subscribe(resp => {
    		//this.isCreating = false;
    		var myurl = URL.createObjectURL(resp);
    		//console.log(resp);
    		window.open(myurl);
    	});
	}

	public createDetailItem(obj:any, id:any){
        const file = obj;
		const formData = new FormData();
    	formData.append('file', file);
    	formData.append('event_id', id);

    	var qst = 'admin/event/media';

    	return this.srv.customPostCall(qst, formData).pipe(
                    mergeMap(resp => {
                        if( resp.status ){
				          	return of(true);
				        }
				        else{
				          	return of(false);
				        }
	                }),
	                //timeout(MAX_TIME),
	                catchError(data => of(false))
                );
    }

    removeFile(obj:any){
    	var i = 0;
    	var count = 0;

    	this.files.forEach(x => {
    		if( x.name == obj.name ){
    			i = count;
    		}

    		count++;
    	});

    	this.files.splice(i, 1);
    }

    public deleteFile(obj: any) {
        var qst = 'admin/event/media/del/' + obj.id;
        
        Swal.fire({
            title: this.translations.delete_item_title,
            text: '',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText: this.translations.cancel,
            confirmButtonText: this.translations.delete,
            preConfirm: () => {
                this.srv.postCall(qst, {}).subscribe(x => {
                    let msg = x.msg;

                    if(x.status){
                    	var i = 0;
				    	var count = 0;

				    	this.u_obj.media.forEach(x => {
				    		if( x.id == obj.id ){
				    			i = count;
				    		}

				    		count++;
				    	});

				    	this.u_obj.media.splice(i, 1);
                        Swal.fire(this.translations.deleted, msg, 'success');
                    }
                    else{
                        Swal.fire(msg, '', 'error');
                    }
                });
            }
        }).then((result) => {
        });
    }

}
