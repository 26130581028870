import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { LoginService } from '../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import * as moment from 'moment-timezone';

moment.tz.setDefault('America/Guayaquil');

@Component({
  selector: 'app-benefitreq',
  templateUrl: './benefitreq.component.html',
  styleUrls: ['./benefitreq.component.css']
})
export class BenefitreqComponent extends BaseCrudComponent {

  	translations:any;
    loadingRequired:any;
    types:any;
    options:any;
    periods:any;
    momentjs: any = moment;
    listApprovers:any;
    sudo:any;
    showSudo:any;

    download_url:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'benefireq';
        this.create_url = 'benefireq';
        this.update_url = 'benefireq';
        this.delete_url = 'benefireq/del';
        this.download_url = 'benefireq/download';
        this.search_fields = [];
        this.loadingRequired = false;
        this.types = [];
        this.options = [];
        this.periods = [];

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();

            this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    this.getApproversData();
                }
            });

        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    public getFiltersData(){
        var par_url = 'benefireq/filters';
        var tosend = {};

        return this.srv.getCall(par_url, tosend).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        this.c_obj = {};

        super.openCreateModal(content);
        this.loadingRequired = true;
        this.getFiltersData().subscribe(x => {
            this.loadingRequired = false;
            this.types = x['types'];
            this.options = x['options'];
            this.periods = x['periods'];
        });
    }

    openUpdateModal(content:any, obj:any){
        let last_approval:any = {updated_at:'', responsible:{name:''}};
        if( Array.isArray(obj['approvals']) && (obj['approvals'].length > 0) ){
            let tmp = obj['approvals'][obj['approvals'].length - 1];
            last_approval = tmp;
        }

        obj['last_approval'] = last_approval;
        //console.dir(obj);
        super.openUpdateModal(content, obj, 'lg');
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'type_id': new FormControl(fields.type_id, [Validators.required]),
            'option_id': new FormControl(fields.option_id, [Validators.required]),
            'period': new FormControl(fields.period, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            to['uid'] = this.sudo.id;
        }

        to.type_id = form['type_id'];
        to.option_id = form['option_id'];
        to.period = form['period'];
        /*to.start_date = form.start_date;

        if( (form.end_date != undefined) && (form.end_date != '') ){
        	to.end_date = form.end_date;
        }
        else{
            to.end_date = to.start_date;
        }*/

        /*if( !end_date.isSameOrAfter(start_date) ){
            Swal.fire('Fecha final debe ser mayor a la inicial', '', 'error');
            return;
        }

        if( !start_date.isSameOrAfter(first_date) ){
            Swal.fire('Las solicitudes solo pueden ser a partir del primer día del mes en curso', '', 'error');
            return;
        }

        if( form.description != undefined ){
        	to.description = form.description;
        }*/
        
        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

    public download(obj:any){
        var url = this.download_url;

        this.isDownloading = true;

        this.srv.postBlobCall(url, {download: 1, id:obj['id']}).subscribe(resp => {
            this.isDownloading = false;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

}
