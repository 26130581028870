import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ApiService } from '../../services/main/api.service';
import { LoginService } from '../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import * as moment from 'moment-timezone';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent extends BaseCrudComponent {
  	public psconfig: PerfectScrollbarConfigInterface = {};

	translations:any;
    momentjs: any = moment;
    birthdays:any;
    anniversaries:any;
    month_str:any;
    base_url:any;
    photo_url:any;
    now:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'dashboard/reminders';

        this.birthdays = [];
        this.anniversaries = [];
        this.month_str = '';
        this.base_url = Global.url;
        this.photo_url = Global.url + 'profile/doc/';
        this.now = Date.now();

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
            	this.month_str = x.data.month;
            	x.data.rows.forEach(y => {
            		if( y.btype == 1 ){
            			this.anniversaries.push(y);
            		}
            		else{
            			this.birthdays.push(y);
            		}
            	});
            }
        });
    }

}
