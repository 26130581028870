import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/authentication/login.service';

@Component({
  selector: 'app-colaborators',
  templateUrl: './colaborators.component.html',
  styleUrls: ['./colaborators.component.css']
})
export class ColaboratorsComponent implements OnInit {

  	menu:any;
  	permissions = [];
  	userinfo:any;

	constructor(public auth: LoginService) { }

	ngOnInit() {
		this.menu = [
      		{name:'Actualización de Datos', desc:'Ficha socioeconómica', icon:'fas fa-user-edit', url:'/update-form', perm:'SERVICIOS-MI_PERFIL', img:''},
      		{name:'Acumulación de Beneficios', desc:'Carta de acumulación de beneficios', icon:'fas fa-hospital-user', url:'/benefit-req', perm:'SERVICIOS-MI_PERFIL', img:''},
			{name:'Alimentación', desc:'Reserva almuerzos y meriendas', icon:'fas fa-utensils', url:'/feeding', perm: 'SERVICIOS-ALIMENTACION', img:''},
			{name:'Certificado laboral', desc:'Consulta e imprime tu certificado laboral', icon:'fas fa-certificate', url:'/certificate', perm: 'SERVICIOS-CERTIFICADO_LABORAL', img:''},
			{name:'Rol de pago', desc:'Consulta tus roles actual y pasados', icon:'fas fa-money-check-alt', url:'/payrol', perm:'SERVICIOS-ROL_PAGOS', img:''},
      		{name:'Nóminas Especiales', desc:'Consulta nóminas especiales', icon:'fas fa-money-check-alt', url:'/d3', perm:'SERVICIOS-ROL_PAGOS', img:''},
			{name:'Estado de cuenta', desc:'Consulta estado cuenta', icon:'fas fa-file-invoice-dollar', url:'/account', perm:'SERVICIOS-ESTADO_CUENTA', img:''},
			{name:'Mi perfil', desc:'Pefil de usuario', icon:'fas fa-qrcode', url:'/profile', perm:'SERVICIOS-MI_PERFIL', img:''},
			{name:'Permisos', desc:'Permisos', icon:'fas fa-hospital-user', url:'/absences', perm:'SERVICIOS-PERMISOS', img:''},
			{name:'Anticipos', desc:'Anticipos', icon:'fas fa-money-bill-alt', url:'/advances', perm:'SERVICIOS-ANTICIPOS', img:''},
			{name:'Anticipos Extraordinarios', desc:'Anticipos de décimo tercero y cuarto', icon:'fas fa-comment-dollar', url:'/extadvances', perm:'SERVICIOS-ANTICIPOS_EXTRAORDINARIOS', img:'ext_adv.png'},
			{name:'Vacaciones', desc:'Vacaciones', icon:'fas fa-umbrella-beach', url:'/vacations', perm:'SERVICIOS-VACACIONES', img:''},
			{name:'Mis Consumos', desc:'Consulta Beneficios', icon:'fas fa-credit-card', url:'/benefits', perm:'SERVICIOS-BENEFICIOS', img:''},
			{name:'Prestamos', desc:'Prestamos', icon:'fas fa-hand-holding-usd', url:'/loans', perm:'SERVICIOS-PRESTAMOS', img:''},
			//{name:'Viáticos', desc:'Viáticos', icon:'fas fa-coins', url:'', perm:'SERVICIOS-VIATICOS'},
			//{name:'Organigrama', desc:'Organigrama', icon:'fas fa-sitemap', url:'/orgchart', perm:'SERVICIOS-ORGANIGRAMA', img:''}
			/*{name:'Seguro Médico', desc:'Seguro Médico', icon:'fas fa-medkit', url:'/medinsurance', perm:'SERVICIOS-SEGURO_MEDICO'}*/
			{name:'Rol de Comisiones', desc:'Rol de Comisiones', icon:'fas fa-coins', url:'/comrol', perm:'SERVICIOS-ROL_COMISIONES', img:''},
      		{name:'Descuentos', desc:'Descuentos en utilidades 2021', icon:'fas fa-money-check-alt', url:'/profit', perm:'SERVICIOS-ROL_PAGOS', img:''},
			{name:'Marcación Teletrabajo', desc:'Marcación Teletrabajo', icon:'fas fa-laptop', url:'/remote-work', perm:'SERVICIOS-MARCACIONES', img:''}
		];

		this.auth.getPermissions().then(x => {
            this.permissions = x;
        });

        this.auth.getInfo().then(y => {
            this.userinfo = y;
        });
	}

	hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
        	//temporal to allow loans only for super admins for test purpose
        	if( (str == 'SERVICIOS-PRESTAMOS') && (this.userinfo.role != 'superadmin') ){
        		return false;
        	}

            return true;
        }
        else{
            return false;
        }
    }

    goToInsuranceLink(){
    	var url = 'https://www.saludsapersonas.com/portalclientes/Account/Login';
        window.open(url, '_blank');
    }

    goToItLink(){
      var url = 'https://apps.powerapps.com/play/e/default-6f85e1ca-8653-4548-8b55-1113ca13fa7a/a/9b9beb8f-6070-4427-b081-6201606d9207?tenantId=6f85e1ca-8653-4548-8b55-1113ca13fa7a&source=portal';
        window.open(url, '_blank');
    }

}
