import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { Router } from '@angular/router';
//import { Observable } from 'rxjs/Observable';
import { Observable, from, of } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

const MAX_TIME = 60000;

@Injectable({
  providedIn: 'root'
})

export class MapsService {
	private url: any;
  	private token: any;

  	constructor(
  		private _router: Router,
  		private http: HttpClient,
  		private storage: Storage
  	) {
  		this.url = Global.url;
  	}

	public refreshToken(){
      	return this.storage.get('token');
  	}

  	public saveZoneAPI(obj){
    	var url = this.url + 'zone';

	    return from(this.refreshToken()).pipe(
	        mergeMap( 
	          token => {
	            const httpOptions = {
	              headers: new HttpHeaders({
	                'Accept':  'application/json',
	                'Content-Type':  'application/json'
	                }) 
	              };

	            let postData = obj;
	            let mydata:any = {message:'', data:[], status: false};

	            return this.http.post(url+'?token='+token, postData, httpOptions)
	              .pipe(
	                  mergeMap(data => {
	                      mydata = data;

	                      if( !mydata.status ){
	                      	  mydata.message = mydata.message.Error.msg;
	                      }

	                      return of(mydata);
	                    }),
	                    timeout(MAX_TIME),
	                    catchError(data => {
	                    	mydata.message = 'Error de sistema';
	                    	return of(mydata);
	                    })
	              );
	          }
	        )
	    );
  	}

  	public updateZoneAPI(id, obj){
    	var url = this.url + 'zone/' + id;

	    return from(this.refreshToken()).pipe(
	        mergeMap( 
	          token => {
	            const httpOptions = {
	              headers: new HttpHeaders({
	                'Accept':  'application/json',
	                'Content-Type':  'application/json'
	                }) 
	              };

	            let postData = obj;
	            let mydata:any = {message:'', data:[], status: false};

	            return this.http.post(url+'?token='+token, postData, httpOptions)
	              .pipe(
	                  mergeMap(data => {
	                      mydata = data;

	                      if( !mydata.status ){
	                      	  mydata.message = mydata.message.Error.msg;
	                      }

	                      return of(mydata);
	                    }),
	                    timeout(MAX_TIME),
	                    catchError(data => {
	                    	mydata.message = 'Error de sistema';
	                    	return of(mydata);
	                    })
	              );
	          }
	        )
	    );
  	}

  	public getAllZones(){
    	var url = this.url + 'zones';

	    return from(this.refreshToken()).pipe(
	        mergeMap( 
	          token => {
	            const httpOptions = {
	              headers: new HttpHeaders({
	                'Accept':  'application/json',
	                'Content-Type':  'application/json'
	                }) 
	              };

	            let mydata:any = {message:'', data:[], status: false};

	            return this.http.get(url+'?token='+token, httpOptions)
	              .pipe(
	                  mergeMap(data => {
	                      mydata = data;

	                      if( !mydata.status ){
	                      	  mydata.message = mydata.message.Error.msg;
	                      }

	                      return of(mydata);
	                    }),
	                    timeout(MAX_TIME),
	                    catchError(data => {
	                    	mydata.message = 'Error de sistema';
	                    	return of(mydata);
	                    })
	              );
	          }
	        )
	    );
  	}

  	public deleteZoneAPI(id){
    	var url = this.url + 'zone/del/' + id;

	    return from(this.refreshToken()).pipe(
	        mergeMap( 
	          token => {
	            const httpOptions = {
	              headers: new HttpHeaders({
	                'Accept':  'application/json',
	                'Content-Type':  'application/json'
	                }) 
	              };

	            let postData = {};
	            let mydata:any = {message:'', data:[], status: false};

	            return this.http.post(url+'?token='+token, postData, httpOptions)
	              .pipe(
	                  mergeMap(data => {
	                      mydata = data;

	                      if( !mydata.status ){
	                      	  mydata.message = mydata.message.Error.msg;
	                      }

	                      return of(mydata);
	                    }),
	                    timeout(MAX_TIME),
	                    catchError(data => {
	                    	mydata.message = 'Error de sistema';
	                    	return of(mydata);
	                    })
	              );
	          }
	        )
	    );
  	}

  	public downloadsZonesAPI(){
    	var url = this.url + 'zones/download';

	    return from(this.refreshToken()).pipe(
	        mergeMap( 
	          token => {
	            /*const httpOptions = {
	              	headers: new HttpHeaders({
		                'Accept':  'application/json',
		                'Content-Type':  'application/json'
	                }) 
	            };*/

	            const headers = new HttpHeaders({
				    'Content-Type': 'application/json',
				    'Accept': 'application/json'
				});

	            let postData = {export:true};
	            let mydata:any = {message:'', data:null, status: false};

	            //return this.http.post(url+'?token='+token, postData, httpOptions)
	            return this.http.post(url+'?token='+token, postData, {headers: headers, responseType: 'blob' as 'json'})
	              .pipe(
	                  mergeMap(data => {
	                      mydata.status = true;
	                      mydata.data = data;
	                      return of(mydata);
	                    }),
	                    timeout(MAX_TIME),
	                    catchError(data => {
	                    	mydata.message = 'Error de sistema';
	                    	return of(mydata);
	                    })
	              );
	          }
	        )
	    );
  	}

}
