import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { LoginService } from '../../../services/authentication/login.service';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-mealcategory',
  templateUrl: './mealcategory.component.html',
  styleUrls: ['./mealcategory.component.css']
})
export class MealcategoryComponent extends BaseCrudComponent {

  translations:any;
  listPerms:any;
  loadingRequired:any;
  categories:any;

  teamid:any;
  teamsubscription: Subscription;

  constructor(
      srv: ApiService,
      modalService: NgbModal,
      storage: Storage,
      router: Router,
      private route: ActivatedRoute,
      public translate: TranslateService,
      public _login: LoginService
    ) {
      super(srv, modalService, storage, router);

      this.read_url = 'meal/categoriesteam';
      this.create_url = 'meal/new';
      this.update_url = 'meal/category';
      this.delete_url = '';
      this.search_fields = ['name', 'description', 'price'];
      this.categories = [];
      this.loadingRequired = false;

      this.reload.subscribe(val => {
          if( val ){
              super.clearData();
              this.getList();
          }
      });

      this.teamsubscription = this.srv.team_changed.subscribe(val => {
        if( val ){
          this.getList();
        }
      });
  }

  ngOnInit() {
      this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
      });
  }

  public getFiltersData(){
      var par_url = 'meal/cat';

      return this.srv.getCall(par_url).pipe(
                  mergeMap(x => {
                      let mydata:any = {status: false, message:'', data:[]};
                      mydata = x;
                      //console.log(data);
                      
                      if(mydata.status == true ){
                        return of(x.data.rows);
                      }
                      else{
                        return of(false);
                      }
                    }),
                    //timeout(MAX_TIME),
                    catchError(data => of(false))
              );
  }

  public getList(){
      var qst = this.read_url;
      var tosend = {};

      this._login.getTeam().then(x => {
          this.teamid = x;
          tosend['tid'] = this.teamid;
          
          this.loadingList = true;
          this.srv.getCall(qst, tosend).subscribe(x => {
              this.loadingList = false;
              
              if(x.status){
                  this.rawdata = x.data.rows;
                  this.rows = x.data.rows;
                  this.page = 1;

                  if( Array.isArray(this.rows) ){
                      this.pageTotal = this.rows.length;
                  }
              }
              else{
                  Swal.fire(x.message, '', 'info');
              }
          });
      });
  }

  openCreateModal(content:any){
      this.c_obj = {};
      this.categories = [];
      super.openCreateModal(content);

      this.loadingRequired = true;
      this.getFiltersData().subscribe(x => {
          this.loadingRequired = false;
          if(x != false){
              if( Array.isArray(x) ){
                x.forEach(i => {
                  this.categories.push(i);
                });
              }
          }
      });
  }

  openUpdateModal(content:any, obj:any){
    this.categories = [];
    super.openUpdateModal(content, obj);

      this.loadingRequired = true;
      this.getFiltersData().subscribe(x => {
          this.loadingRequired = false;
          if(x != false){
              if( Array.isArray(x) ){
                x.forEach(i => {
                  this.categories.push(i);
                });
              }
          }
      });
  }

  validateCreateInfo(fields:any){
      let mygroup:any = {};
      mygroup = new FormGroup({
          // 'name': new FormControl(fields.name, [Validators.required]),
          // 'category_id': new FormControl(fields.category_id, [Validators.required])
          'price': new FormControl(fields.price, [Validators.required])
      });

      return mygroup.valid;
  }

  public createItem(content:any, form:any){
      var validated = true;

      if( form == undefined ){
        form = {};
      }

      validated = this.validateCreateInfo(form);
      if( !validated ){
          Swal.fire(this.translations['msg_all_required'], '', 'error');
          return;
      }

      var qst = this.create_url;
      this.isCreating = true;

      this.srv.postCall(qst, form).subscribe(x => {
          this.isCreating = false;
          if(x.status){
              this.c_obj = {};
              this.getList();
              this.closeModal(content);
              Swal.fire(this.translations.created, '', 'success');
          }//end success
          else{
              //Swal.fire(this.translations[x.msg], '', 'error');
              Swal.fire(x.message, '', 'error');
          }
      });
  }

  public updateItem(content:any, form:any){
      var validated = true;

      if( form == undefined ){
        form = {};
      }

      validated = this.validateCreateInfo(form);
      if( !validated ){
          Swal.fire(this.translations['msg_all_required'], '', 'error');
          return;
      }
      
      this._login.getTeam().then(x => {
          var to:any;
          to = {};
          to.id = form['id'];
          to.price = form['price'];
          to.tid = x;
          
          var qst = this.update_url + '/' + form.id;
          this.isUpdating = true;

          this.srv.postCall(qst, to).subscribe(x => {
              this.isUpdating = false;
              if(x.status){
                  this.getList();
                  this.closeModal(content);
                  Swal.fire(this.translations['updated'], '', 'success');
              }//end success
              else{
                  Swal.fire(x.message, '', 'error');
                //this.closeModal(content);
              }
          });
      });
  }

}
