import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';

@Component({
  selector: 'app-companycontacts',
  templateUrl: './companycontacts.component.html',
  styleUrls: ['./companycontacts.component.css']
})
export class CompanycontactsComponent extends BaseCrudComponent {
	id:any;

	constructor(
	    srv: ApiService,
	    modalService: NgbModal,
	    storage: Storage,
  		router: Router,
  		private route: ActivatedRoute,
	  ) {
	    super(srv, modalService, storage, router);
	    this.id = route.snapshot.params.id;

	    this.read_url = 'Company/CompanyByUser';
	    this.update_url = 'Company/Update';
	    this.delete_url = 'Company/Update';

	    this.reload.subscribe(val => {
	        if( val ){
	            super.clearData();
	            this.getList();
	        }
	    });
    }

    ngOnInit() {
        super.ngOnInit();
        this.regularForm = new FormGroup({
            /*'ParName': new FormControl(null, [Validators.required]),
            'Partype': new FormControl(null, [Validators.required]),
            'ParIdProvider': new FormControl(null, [Validators.required]),
            'ParKeyProvider': new FormControl(null, [Validators.required])*/
        }, {updateOn: 'blur'});

        this.getList();
    }

    public getList(){
        var qst = this.read_url;
        this.srv.getCall(qst).subscribe(x => {
            if(x.success){
                var rs = x.val;

                rs.forEach(x => {
                  	if( x.CompId == this.id ){
                  		this.rows = x;
                  	}
                });
            }
        });
    }

    public createItem(content:any, form:any){
        if( form == undefined ){
          form = {};
        }

        var to:any;
        const copy = { ...this.rows };//company object

        if( (form.ContFirstName == undefined) || (form.ContFirstName == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        if( (form.ContLastName == undefined) || (form.ContLastName == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        if( (form.ContEmail == undefined) || (form.ContEmail == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        if( (form.ContPhone == undefined) || (form.ContPhone == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        if( (form.ContPosition == undefined) || (form.ContPosition == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        to = form;
        to.ContCompanyId = this.rows.CompAquasymId;

        /*var obj = {
        	CompAquasymId: this.rows.CompAquasymId,
        	Contact: []
        }
        
        this.rows.Contact.forEach(c => {
        	obj.Contact.push(c);
        });*/
        var obj = this.rows;

        obj.Contact.push(to);

        var qst = this.update_url;
        this.srv.postCall(qst, obj).subscribe(x => {
            if(x.success){
                this.c_obj = {};
                this.getList();
        		this.closeModal(content);
            }//end success
            else{
            	this.closeModal(content);
            }
        });
    }

    public updateItem(content:any, form:any){
        if( form == undefined ){
          form = {};
        }

        var to:any;
        const copy = { ...this.rows };//company object

        if( (form.ContFirstName == undefined) || (form.ContFirstName == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        if( (form.ContLastName == undefined) || (form.ContLastName == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        if( (form.ContEmail == undefined) || (form.ContEmail == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        if( (form.ContPhone == undefined) || (form.ContPhone == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        if( (form.ContPosition == undefined) || (form.ContPosition == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        to = form;
        //to.ContCompanyId = this.rows.CompAquasymId;

        /*var obj = {
        	CompAquasymId: this.rows.CompAquasymId,
        	Contact: []
        }
        
        this.rows.Contact.forEach(c => {
        	if(c.ContId == to.ContId){
        		obj.Contact.push(to);
        	}
        	else{
        		obj.Contact.push(c);
        	}        	
        });*/

        var contacts = [];
        this.rows.Contact.forEach(c => {
            if(c.ContId != to.ContId){
                contacts.push(c);
            }
            else{
                contacts.push(to);
            }
        });

        this.rows.Contact = contacts;
        var obj = this.rows;
        
        var qst = this.update_url;
        this.srv.postCall(qst, obj).subscribe(x => {
            if(x.success){
                this.getList();
        		this.closeModal(content);
            }//end success
            else{
            	this.closeModal(content);
            }
        });
    }

    public deleteItem(form:any){
        var to = form;
        const copy = { ...this.rows };//company object

        /*var obj = {
        	CompAquasymId: this.rows.CompAquasymId,
        	Contact: []
        }
        
        this.rows.Contact.forEach(c => {
        	if(c.ContId != to.ContId){
        		obj.Contact.push(c);
        	}        	
        });*/

        var contacts = [];
        this.rows.Contact.forEach(c => {
            if(c.ContId != to.ContId){
                contacts.push(c);
            }           
        });

        this.rows.Contact = contacts;
        var obj = this.rows;

        super.deleteItem(obj);
    }

}
