import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-profitadmin',
  templateUrl: './profitadmin.component.html',
  styleUrls: ['./profitadmin.component.css']
})
export class ProfitadminComponent extends BaseCrudComponent {

  	translations:any;
    listPerms:any;
    loadingRequired:any;
    momentjs: any = moment;
    files:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'admin/profit';
        this.create_url = 'admin/profit/upload';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = ['name', 'code', 'docid'];
        this.listPerms = [];
        this.loadingRequired = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.f_datefrom = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');

        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};
        //tosend['start'] = this.f_datefrom;
        
        this.loadingList = true;
        this.srv.postCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    handleInputChange(e, obj:any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        //console.log(file);
        this.files = [];
        this.files.push(file);
    }

    openCreateModal(content:any){
        this.c_obj = {};
        this.files = [];
        //this.c_obj['start'] = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');
        super.openCreateModal(content);
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            //'start': new FormControl(fields.start, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        let formData = this.getFormData(form);

        if( this.files.length > 0 ){
            const file = this.files[0];
            formData.append('file', file);
        }
        else{
        	Swal.fire('Debe seleccionar archivo', '', 'error');
            return;
        }

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.customPostCall(qst, formData).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.files = [];
                this.getList();
                this.closeModal(content);
                Swal.fire('Registros importados', '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    private getFormData(obj:any){
        let fields = [
            //'start'
        ];
        const formData = new FormData();
        //console.log(formData);
        
        fields.forEach(x => {
            if( (obj[x] !== undefined) && (obj[x] !== null) && (obj[x] !== '') ){
                formData.append(x, obj[x]);
            }
        });

        //console.log(formData);
        return formData;
    }

}
