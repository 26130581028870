import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/authentication/login.service';

@Component({
  selector: 'app-mealhome',
  templateUrl: './mealhome.component.html',
  styleUrls: ['./mealhome.component.css']
})
export class MealhomeComponent implements OnInit {

  	menu:any;
  	permissions = [];

	constructor(public auth: LoginService) { }

	ngOnInit() {
		this.menu = [
			{name:'Costos de categorías', desc:'Costos de categorías', icon:'fas fa-dollar-sign', url:'/mealcategory', perm: 'ADMINS-ALIMENTACION-CREACION_CATEGORY'},
			{name:'Creación de platos', desc:'Creación de platos', icon:'fas fa-hamburger', url:'/mealcrud', perm: 'ADMINS-ALIMENTACION-CREACION_PLATOS'},
			{name:'Creación de menús', desc:'Creación de menú', icon:'fas fa-calendar-plus', url:'/mealbooking', perm: 'ADMINS-ALIMENTACION-CREACION_MENU'},
            {name:'Almuerzos', desc:'Reserva almuerzos y breaks', icon:'fas fa-sun', url:'/adminlunch', perm: 'ADMINS-ALIMENTACION-ALMUERZOS'},
            {name:'Meriendas', desc:'Reserva meriendas', icon:'fas fa-moon', url:'/admindinner', perm: 'ADMINS-ALIMENTACION-MERIENDAS'},
            {name:'Reporte Mensual', desc:'Reporte Mensual', icon:'fas fa-file-alt', url:'/monthlyreport', perm: 'ADMINS-ALIMENTACION-REPORTES_MENSUAL'},
            {name:'Reporte Diario', desc:'Reporte Diario', icon:'fas fa-file-alt', url:'/dailyreport', perm: 'ADMINS-ALIMENTACION-REPORTES_DETALLE'},
            {name:'Reporte Proveedor', desc:'Reporte Proveedor', icon:'fas fa-file-alt', url:'/providerreport', perm: 'ADMINS-ALIMENTACION-REPORTES_PROVEEDOR'}
		];

		this.auth.getPermissions().then(x => {
            this.permissions = x;
        });
	}

	hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }

}
