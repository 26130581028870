import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { LoginService } from '../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import * as moment from 'moment-timezone';

moment.tz.setDefault('America/Guayaquil');

@Component({
  selector: 'app-loanquery',
  templateUrl: './loanquery.component.html',
  styleUrls: ['./loanquery.component.css']
})
export class LoanqueryComponent extends BaseCrudComponent {

  	translations:any;
    loadingRequired:any;
    types:any;
    byhour_id:any;
    momentjs: any = moment;
    listApprovers:any;
    sudo:any;
    showSudo:any;
    simulate_url:any;
    files:any;
    download_url:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'loan';
        this.create_url = 'loan';
        this.simulate_url = 'loan/simulate';
        this.download_url = 'loan/doc';
        this.update_url = '';
        this.delete_url = 'loan/del';
        this.search_fields = ['atype.description', 'amount', 'quotes'];
        this.loadingRequired = false;
        this.types = [];
        this.byhour_id = 0;
        this.files = [];

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();

            this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    this.getApproversData();
                }
            });
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    public getActualsData(){
        var par_url = 'loan/actuals';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        this.c_obj = {};
        this.c_obj.details = [];

        super.openCreateModal(content);
        this.loadingRequired = true;
        this.getActualsData().subscribe(x => {
            this.loadingRequired = false;
            this.types = x['types'];
            this.c_obj['start_date'] = this.momentjs().format('YYYY-MM-DD');
        });
    }

    openUpdateModal(content:any, obj:any){
        super.openUpdateModal(content, obj);
        this.loadingRequired = true;
        this.getActualsData().subscribe(x => {
            this.loadingRequired = false;
            this.types = x['types'];
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'start_date': new FormControl(fields.start_date, [Validators.required]),
            'type_id': new FormControl(fields.type_id, [Validators.required]),
            'amount': new FormControl(fields.amount, [Validators.required]),
            'quotes': new FormControl(fields.quotes, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        const formData = new FormData();
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            formData.append('uid', this.sudo.id);
        }

        formData.append('type_id', form['type_id']);
        formData.append('start_date', form.start_date);
        formData.append('amount', form.amount);
        formData.append('quotes', form.quotes);
        formData.append('monthly', form.monthly);
        formData.append('d13', form.d13);
        formData.append('d14', form.d14);
        formData.append('comment', form.comment);

        if( this.files.length > 0 ){
            const file = this.files[0];
            formData.append('file', file);
        }

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.customPostCall(qst, formData).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public simulate(form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            to['uid'] = this.sudo.id;
        }

        to.type_id = form['type_id'];
        to.start_date = form.start_date;
        to.amount = form['amount'];
        to.quotes = form['quotes'];

        var qst = this.simulate_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj.details = x.data.details;
                Swal.fire('Simulación exitosa', '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public clearSimulation(){
    	this.c_obj = {};
    	this.c_obj.details = [];
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    handleInputChange(e, obj:any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        //console.log(file);
        this.files = [];
        this.files.push(file);
    }

    public showFile(id:any){
        var url = this.download_url + '/' + id;
        //window.open(url);

        this.srv.getBlobCall(url).subscribe(resp => {
            //this.isCreating = false;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

    public downloadPDF(obj){

    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

}
