import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'user-payrolinfo',
  templateUrl: './payrolinfo.component.html',
  styleUrls: ['./payrolinfo.component.css']
})
export class PayrolinfoComponent implements OnInit {

  	@Input() rows: any;
	
	constructor() { }

	ngOnInit() {
	}

}
