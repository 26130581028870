import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent extends BaseCrudComponent {

  	info:any;
	currdate:any;
	enddate:any;
    translations:any;
    download_url:any;

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'accstatus/list';
        this.download_url = 'accstatus/list';
        this.update_url = '';

        this.info = [];
        this.currdate = '';
        this.enddate = '';
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
        });
    }

    getListPost(){
        var qst = this.read_url;
        var from = '';
        var obj:any;
        obj = {};

        if( this.currdate != '' ){
            obj['start'] = this.currdate;
        }

        if( this.enddate != '' ){
        	obj['end'] = this.enddate;
        }

        this.loadingList = true;
        this.info = [];

        this.srv.postCall(qst, obj).subscribe(x => {
            this.loadingList = false;
            
            if( x.status && (x.data['fields'] != undefined) ){
                //this.info = x.data.fields;
                x.data.fields.forEach(z => {
                	//console.log(z);
                	let counter = 0;
                	z.details.forEach(y => {
                		this.info.push({
                			desc: (counter == 0) ? z.desc : '',
                			month: y.start,
                			amount: y.amount,
                			paid: y.paid,
                			balance: y.balance,
                			isTotal: false
                		});

                		counter++;
                	});

                	this.info.push({
                			desc: '',
                			month: 'TOTAL',
                			amount: z.total_amount,
                			paid: z.total_paid,
                			balance: z.total_balance,
                			isTotal: true
                		});
                });
            }
        });
    }

    public download(){
        var url = this.download_url;
        var from = '';
        var obj:any;

        if( this.currdate == '' ){
        	Swal.fire('Debe seleccionar fecha desde', '', 'error');
        	return;
        }
        else{
        	from = this.currdate;
        }

        obj = {start: from, download: 1};

        if( this.enddate != '' ){
        	obj['end'] = this.enddate;
        }

        this.isDownloading = true;

        this.srv.postBlobCall(url, obj).subscribe(resp => {
            this.isDownloading = false;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

}
