import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { LoginService } from '../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import * as moment from 'moment-timezone';

moment.tz.setDefault('America/Guayaquil');

@Component({
  selector: 'app-gpsri',
  templateUrl: './gpsri.component.html',
  styleUrls: ['./gpsri.component.css']
})
export class GpsriComponent extends BaseCrudComponent {

    translations:any;
    loadingRequired:any;
    types:any;
    byhour_id:any;
    momentjs: any = moment;
    listApprovers:any;
    sudo:any;
    showSudo:any;
    total:any;
    balance:any;
    movements:any;
    canCreate = false;
    
    years:any = [];

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'sri/gp/all';
        this.create_url = 'sri/gp/save';
        this.update_url = 'sri/gp/save';
        this.delete_url = '';
        this.search_fields = [];
        this.loadingRequired = false;
        this.types = [];
        this.byhour_id = 0;
        this.total = 0;
        this.balance = [];
        this.movements = [];

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();

            this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    this.getApproversData();
                }
            });

            this.canCreate = true;
        });

        const d = new Date();
        let year = d.getFullYear();

        for( let i = year; i >= 2024; i-- ){
            this.years.push(i);
        }
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    public getActualsData(){
        var par_url = 'sri/gp/new/period';
        var tosend = {};

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        return this.srv.getCall(par_url, tosend).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getSavedData(id:number){
      var par_url = 'sri/gp/period/' + id;
      var tosend = {};

      if( (this.sudo != undefined) && (this.sudo != null) ){
          tosend['uid'] = this.sudo.id;
      }

      return this.srv.getCall(par_url, tosend).pipe(
                  mergeMap(x => {
                      let mydata:any = {status: false, message:'', data:[]};
                      mydata = x;
                      //console.log(data);
                      
                      if(mydata.status == true ){
                          return of(x.data.rows);
                      }
                      else{
                          Swal.fire(mydata.message, '', 'error');
                          return of(false);
                      }
                    }),
                    //timeout(MAX_TIME),
                    catchError(data => of(false))
              );
  }

    openCreateModal(content:any){
        this.c_obj = {};

        super.openCreateModal(content);
        this.loadingRequired = true;
        this.getActualsData().subscribe(x => {
            this.loadingRequired = false;
            //console.log(x);
            const d = new Date();
            let year = d.getFullYear();
            this.c_obj['period'] = year;
            
            if(x){
                this.c_obj['income_main'] = x.income_main;
                this.c_obj['income_second'] = x.income_second;
                this.c_obj['dependants'] = x.dependants;
                this.c_obj['deps_count'] = 0;
                this.c_obj['deps_dsct'] = 0;
                this.updateIncomeValues(this.c_obj);
            }
        });
    }

    openUpdateModal(content:any, obj:any){
        super.openUpdateModal(content, obj);
        this.u_obj = {};

        this.loadingRequired = true;
        this.getSavedData(obj.id).subscribe(x => {
            this.loadingRequired = false;
            //console.log(x);
            if(x){
                this.u_obj = x;
                this.u_obj['dependants'] = x['actual_deps'];
                
                this.u_obj['deps_count'] = x['dependants_qty'];
                this.u_obj['deps_dsct'] = x['total_discount'];
                this.updateIncomeValues(this.u_obj);
            }
            else{
                Swal.fire('Error al cargar info de formulario', '', 'error');
                return;
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'period': new FormControl(fields.period, [Validators.required]),
            'income_main': new FormControl(fields.income_main, [Validators.required]),
            'income_second': new FormControl(fields.income_second, [Validators.required]),
            'income_forecast': new FormControl(fields.income_forecast, [Validators.required]),
            'bill_home': new FormControl(fields.bill_home, [Validators.required]),
            'bill_education': new FormControl(fields.bill_education, [Validators.required]),
            'bill_health': new FormControl(fields.bill_health, [Validators.required]),
            'bill_dress': new FormControl(fields.bill_dress, [Validators.required]),
            'bill_food': new FormControl(fields.bill_food, [Validators.required]),
            'bill_tourism': new FormControl(fields.bill_tourism, [Validators.required]),
            'bill_forecast': new FormControl(fields.bill_forecast, [Validators.required])
        });

        return mygroup.valid;
    }

    validateDependant(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'TIPO': new FormControl(fields.TIPO, [Validators.required]),
            'NOMBRES_COMPLETOS': new FormControl(fields.NOMBRES_COMPLETOS, [Validators.required]),
            'NUMERO_DOCUMENTO': new FormControl(fields.NUMERO_DOCUMENTO, [Validators.required]),
            'CUMPLEANIOS': new FormControl(fields.CUMPLEANIOS, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        /*validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }*/

        if( form['ec_flag'] && !form['file'] ){
            Swal.fire('Debe adjuntar documento de enfermedad catastrófica', '', 'error');
            return;
        }

        //validate dependants
        let files = [];
        let deps = [];
        let deps_err = '';

        if( form['dependants'] && Array.isArray(form['dependants']) && (form['dependants'].length > 0) ){
            form['dependants'].forEach(e => {
                if( e['checked'] ){
                    validated = this.validateDependant(e);

                    if( !validated ){
                        //Swal.fire('Complete los campos requeridos en cargas seleccionadas', '', 'error');
                        deps_err = 'Complete los campos requeridos en cargas seleccionadas';
                        return;
                    }

                    if(e.reqfile && !e.file){
                        //Swal.fire('Debe adicionar archivo para la carga', '', 'error');
                        deps_err = 'Debe adicionar archivo para la carga';
                        return;
                    }

                    deps.push(e);
                }
            });

            if( deps_err != '' ){
                Swal.fire(deps_err, '', 'error');
                return;
            }
        }

        const formData = new FormData();
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            formData.append('uid', this.sudo.id);
        }
        
        formData.append('period', form['period']);
        formData.append('income_main', form['income_main']);
        formData.append('income_second', form['income_second']);
        formData.append('income_forecast', form['income_forecast']);
        formData.append('bill_home', form['bill_home']);
        formData.append('bill_education', form['bill_education']);
        formData.append('bill_health', form['bill_health']);
        formData.append('bill_dress', form['bill_dress']);
        formData.append('bill_food', form['bill_food']);
        formData.append('bill_tourism', form['bill_tourism']);
        formData.append('bill_forecast', form['bill_forecast']);
        
        //enfermedad catastrofica
        formData.append('ec_flag', form['ec_flag']);
        if(form['file']){
            formData.append('ec_file', form['file']);
        }

        if( deps.length > 0 ){
            let pre = '';
            let counter = 1;

            deps.forEach(d => {
                pre = 'dep' + counter + '_';
                //const file = this.files[0];
                formData.append(pre + 'name', d['NOMBRES_COMPLETOS']);
                formData.append(pre + 'doc', d['NUMERO_DOCUMENTO']);
                formData.append(pre + 'type', d['type_str']);
                formData.append(pre + 'dob', d['CUMPLEANIOS']);

                if( d['reqfile'] ){
                    formData.append(pre + 'reqfile', d['reqfile']);
                    formData.append(pre + 'file', d['file']);
                }

                counter++;
            });

            formData.append('deps_count', form['deps_count']);
            formData.append('deps_dsct', form['deps_dsct']);
        }

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.customPostCall(qst, formData).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    handleInputChange(e, obj:any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        obj['file'] = file;
        // var reader = new FileReader();

        // reader.onload = (_event) => { 
        //   obj.img = reader.result;
        // }

        // reader.readAsDataURL(file);
    }

    updateDepsValues(obj:any){
        obj['deps_count'] = 0;
        obj['deps_dsct'] = 0;

        if( Array.isArray(obj['dependants']) ){
            let counter = 0;
            obj['dependants'].forEach(e => {
                if( e['checked'] ){
                    counter++;
                }
            });

            obj['deps_count'] = counter;
            //obj['deps_dsct'] = 0;
        }
    }

    updateIncomeValues(obj:any){
        obj['income_forecast'] = 0;
        let fields = ['income_main', 'income_second'];
        let total = 0;

        fields.forEach(x => {
            if(obj[x]){
                total += parseFloat(obj[x]);
            }
        });

        obj['income_forecast'] = total;
    }

    updateBillValues(obj:any){
      obj['bill_forecast'] = 0;
      let fields = ['bill_home', 'bill_education', 'bill_health', 'bill_dress', 'bill_food', 'bill_tourism'];
      let total = 0;

      fields.forEach(x => {
          if(obj[x]){
              total += parseFloat(obj[x]);
          }
      });

      obj['bill_forecast'] = total;
  }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

}