import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { LoginService } from '../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-d3',
  templateUrl: './d3.component.html',
  styleUrls: ['./d3.component.css']
})
export class D3Component extends BaseCrudComponent {
	info:any;
	currdate:any;
    currtype:any;
    curr_title:any;
    translations:any;
    download_url:any;
    years:any = [];

    listApprovers:any;
    sudo:any;
    showSudo:any;

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'd3/list';
        this.download_url = 'd3/list';
        this.update_url = '';

        this.info = false;
        this.currdate = '';
        this.currtype = '';
        this.curr_title = '';

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

                this.auth.hasRole(['superadmin','admin']).then(r => {
                    this.showSudo = r;
                    if( r ){
                        this.getApproversData();
                    }
                });
        });

        const d = new Date();
        let year = d.getFullYear();

        for( let i = year; i >= 2021; i-- ){
            this.years.push(i);
        }
    }

    getListPost(){
        var qst = this.read_url;
        var from = '';
        var ntype = 0;
        this.info = false;

        if( this.currtype == '' ){
            Swal.fire('Debe seleccionar tipo', '', 'error');
            return;
        }

        if( this.currdate == '' ){
        	Swal.fire('Debe seleccionar periodo', '', 'error');
        	return;
        }

        //console.log(this.currdate);return;
        if( this.currtype == '1'){
            //decimo tercero
            from = this.currdate + '-12-01';
            ntype = 1;
            this.curr_title = 'DECIMO TERCERO';
        }
        if( this.currtype == '2'){
            //decimo cuarto costa/insular -> marzo
            from = this.currdate + '-03-01';
            ntype = 2;
            this.curr_title = 'DECIMO CUARTO';
        }
        else if( this.currtype == '3'){
            //decimo cuarto sierra/oriente -> agosto
            from = this.currdate + '-08-01';
            ntype = 2;
            this.curr_title = 'DECIMO CUARTO';
        }
        else if( this.currtype == '4'){
            //utilidades
            from = this.currdate + '-04-01';
            ntype = 3;
            this.curr_title = 'UTILIDADES';
        }
        else if( this.currtype == '5'){
            //quincena
            from = this.currdate + '-15';
            ntype = 4;
            this.curr_title = 'QUINCENA';
        }
        
        var tosend = {};
        tosend['start'] = from;
        tosend['type'] = ntype;

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;

        this.srv.postCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if( x.status && (x.data['fields'] != undefined) && (x.data['fields']['name'] != undefined) ){
                this.info = x.data.fields;
            }
        });
    }

    public download(){
        var url = this.download_url;
        var from = '';
        var ntype = 0;
        this.info = false;

        if( this.currtype == '' ){
            Swal.fire('Debe seleccionar tipo', '', 'error');
            return;
        }

        if( this.currdate == '' ){
            Swal.fire('Debe seleccionar periodo', '', 'error');
            return;
        }
        
        if( this.currtype == '1'){
            //decimo tercero
            from = this.currdate + '-12-01';
            ntype = 1;
            this.curr_title = 'DECIMO TERCERO';
        }
        if( this.currtype == '2'){
            //decimo cuarto costa/insular -> marzo
            from = this.currdate + '-03-01';
            ntype = 2;
            this.curr_title = 'DECIMO CUARTO';
        }
        else if( this.currtype == '3'){
            //decimo cuarto sierra/oriente -> agosto
            from = this.currdate + '-08-01';
            ntype = 2;
            this.curr_title = 'DECIMO CUARTO';
        }
        else if( this.currtype == '4'){
            //utilidades
            from = this.currdate + '-12-01';
            ntype = 3;
            this.curr_title = 'UTILIDADES';
        }
        else if( this.currtype == '5'){
            //quincena
            from = this.currdate + '-15';
            ntype = 4;
            this.curr_title = 'QUINCENA';
        }

        var tosend = {};
        tosend['start'] = from;
        tosend['type'] = ntype;
        tosend['download'] = 1;

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.isDownloading = true;

        this.srv.postBlobCall(url, tosend).subscribe(resp => {
            this.isDownloading = false;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

}