import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { LoginService } from '../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import * as moment from 'moment-timezone';

moment.tz.setDefault('America/Guayaquil');

@Component({
  selector: 'app-absence',
  templateUrl: './absence.component.html',
  styleUrls: ['./absence.component.css']
})
export class AbsenceComponent extends BaseCrudComponent {

  	translations:any;
    loadingRequired:any;
    types:any;
    byhour_id:any;
    momentjs: any = moment;
    listApprovers:any;
    sudo:any;
    showSudo:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'absence';
        this.create_url = 'absence';
        this.update_url = 'absence';
        this.delete_url = 'absence/del';
        this.search_fields = [];
        this.loadingRequired = false;
        this.types = [];
        this.byhour_id = 0;

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();

            this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    this.getApproversData();
                }
            });
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    public getActualsData(){
        var par_url = 'absence/actuals';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        this.c_obj = {};

        super.openCreateModal(content);
        this.loadingRequired = true;
        this.getActualsData().subscribe(x => {
            this.loadingRequired = false;
            this.types = x['types'];
            this.byhour_id = x['absence_per_hour_id'];
            //this.start_date = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');
            this.c_obj['start_date'] = this.momentjs().format('YYYY-MM-DD');
        });
    }

    openUpdateModal(content:any, obj:any){
        super.openUpdateModal(content, obj);
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'start_date': new FormControl(fields.start_date, [Validators.required]),
            'type_id': new FormControl(fields.type_id, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            to['uid'] = this.sudo.id;
        }

        to.type_id = form['type_id'];
        to.start_date = form.start_date;

        if( form.end_date != undefined ){
        	to.end_date = form.end_date;
        }

        if( form.description != undefined ){
        	to.description = form.description;
        }

        if( form['type_id'] == this.byhour_id ){
        	if( (form.hour_from == undefined) || (form.hour_to == undefined) ){
        		Swal.fire('Debe seleccionar hora inicio y fin', '', 'error');
            	return;
        	}

        	to.hour_from = form.hour_from.hour.toString().padStart(2, '0') + ':' + form.hour_from.minute.toString().padStart(2, '0');
        	to.hour_to = form.hour_to.hour.toString().padStart(2, '0') + ':' + form.hour_to.minute.toString().padStart(2, '0');
        }

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

}
