import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/authentication/login.service';

@Component({
  selector: 'app-srihome',
  templateUrl: './srihome.component.html',
  styleUrls: ['./srihome.component.css']
})
export class SrihomeComponent implements OnInit {

    menu:any;
    permissions = [];

    constructor(public auth: LoginService) { }

    ngOnInit() {
      this.menu = [
          {name:'Aprobación GP', desc:'Aprobación de Gastos Personales', icon:'fas fa-tasks', url:'/sri-gpapprove', perm: 'ADMINS-SRI-APROBACION_GASTOS_PERSONALES'}
      ];

      this.auth.getPermissions().then(x => {
              this.permissions = x;
          });
    }

    hasPermission(str:string){
          if( this.permissions.indexOf(str) > -1 ){
              return true;
          }
          else{
              return false;
          }
      }

}
