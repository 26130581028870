import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends BaseCrudComponent {

  	info:any;
	currdate:any;
    files:any;
    isUploading = false;
    upload_url:any;

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'profile';
        this.upload_url = 'employee/profilepic';
        this.update_url = '';

        this.info = false;
        this.currdate = '';
        this.files = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.getList();
    }

    handleInputChange(e, obj:any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        //console.log(file);
        /*this.files = [];
        this.files.push(file);*/
        //var pattern = /image-*/;
        /*var reader = new FileReader();

        reader.onload = (_event) => { 
          obj.img[0].src = reader.result;
          obj.img[0].fname = file.name;
        }

        reader.readAsDataURL(file);*/

        const formData = new FormData();
        formData.append('file', file);

        var qst = this.upload_url;
        this.isUploading = true;

        this.srv.customPostCall(qst, formData).subscribe(resp => {
            this.isUploading = false;
            
            if(resp.status){
                this.files = [];
                this.getList();
                this.srv.reload_pic.next(true);
                /*this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');*/
            }
            else{
              //console.log(resp);
              //Swal.fire(resp.message, '', 'error');
            }
          });
    }

}
