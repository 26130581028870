import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'user-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
})
export class HealthComponent extends BaseCrudComponent {

	@Input() profile: any;
	translations:any;

	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'employee';
        this.create_url = 'employee';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            /*'edu_id': new FormControl(fields.edu_id, [Validators.required]),
            'inst_name': new FormControl(fields.inst_name, [Validators.required]),
            'country_id': new FormControl(fields.country_id, [Validators.required])*/
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        //validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.status){
                this.getList();
                //this.closeModal(content);
                Swal.fire(this.translations.updated, '', 'success');
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.message, '', 'error');
            }
        });
    }

}
