import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.css']
})
export class PollComponent extends BaseCrudComponent {

  	translations:any;
    loadingRequired:any;
    teamid:any;
    teamsubscription: Subscription;
    newquestion:string;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public _login: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'poll/list';
        this.create_url = 'poll/new';
        this.update_url = 'team';
        this.delete_url = 'poll/del';
        this.search_fields = ['code', 'title', 'url'];
        this.loadingRequired = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });

        this.teamsubscription = this.srv.team_changed.subscribe(val => {
        	if( val ){
        		this.getList();
        	}
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        this._login.getTeam().then(x => {
	        this.teamid = x;
	        tosend['tid'] = this.teamid;
	        
	        this.loadingList = true;
	        this.srv.getCall(qst, tosend).subscribe(x => {
	            this.loadingList = false;
	            
	            if(x.status){
	            	this.rawdata = x.data.rows;
	                this.rows = x.data.rows;
	                this.page = 1;

	                if( Array.isArray(this.rows) ){
	                    this.pageTotal = this.rows.length;
	                }
	            }
	        });
	    });
    }

    public getDetailData(id:number, tid:number){
        var par_url = 'polldet/list';

        return this.srv.postCall(par_url, {id: id, tid: tid}).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                          return of(x.data.poll);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        this.c_obj = {};

        super.openCreateModal(content);
        this.loadingRequired = true;
    }

    openUpdateModal(content:any, obj:any){
    	this.newquestion = '';
    	super.openUpdateModal(content, obj);

        this.loadingRequired = true;
        this.getDetailData(obj.id, obj.team_id).subscribe(x => {
            this.loadingRequired = false;
            console.log(x);
            if(x != false){
            	this.u_obj = x;
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'title': new FormControl(fields.title, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        this._login.getTeam().then(x => {
	        this.teamid = x;
	        var to:any;
	        to = form;
	        to.tid = this.teamid;

	        var qst = this.create_url;
	        this.isCreating = true;

	        this.srv.postCall(qst, to).subscribe(x => {
	            this.isCreating = false;
	            if(x.status){
	                this.c_obj = {};
	                this.getList();
	                this.closeModal(content);
	                Swal.fire(this.translations.created, '', 'success');
	            }//end success
	            else{
	                //Swal.fire(this.translations[x.msg], '', 'error');
	                Swal.fire('Error al crear', '', 'error');
	            }
	        });
	    });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = {};
        to.id = form['id'];
        to.name = form['description'];
        
        if( form['address'] != undefined ){
            to.addr = form['address'];
        }

        to.items = [];

        form.permList.forEach(x => {
        	if( x.sel ){
        		to.items.push({id: x.id, selected:x.sel});
        	}
        });

        if( to.items.length <= 0 ){
        	Swal.fire('No permisos seleccionados', '', 'error');
            return;
        }

        var qst = this.update_url + '/' + form.id;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.status){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.message, '', 'error');
              //this.closeModal(content);
            }
        });
    }

}
