import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { MapgoogleComponent } from './maps/map-google/map-google.component';
import { CustomersComponent } from './customers/customers.component';
import { SellersComponent } from './sellers/sellers.component';
import { UsersComponent } from './admins/users/users.component';
import { HomeComponent } from './home/home.component';
import { PayrolComponent } from './payrol/payrol.component';
import { ProfileComponent } from './profile/profile.component';
import { ColaboratorsComponent } from './colaborators/colaborators.component';
import { AdminsComponent } from './admins/admins.component';
import { DocapprovalComponent } from './admins/docapproval/docapproval.component';
import { AdvanceComponent } from './advance/advance.component';
import { AdvanceextComponent } from './advanceext/advanceext.component';
import { AdvanceapproveComponent } from './admins/advanceapprove/advanceapprove.component';
import { BenefitComponent } from './benefit/benefit.component';
import { FeedhomeComponent } from './feeding/feedhome/feedhome.component';
import { LunchComponent } from './feeding/lunch/lunch.component';
import { DinnerComponent } from './feeding/dinner/dinner.component';
import { PermissionapproveComponent } from './admins/permissionapprove/permissionapprove.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { MealhomeComponent } from './admins/mealhome/mealhome.component';
import { MealComponent } from './admins/meal/meal.component';
import { MealcategoryComponent } from './admins/mealcategory/mealcategory.component';
import { MealbookingComponent } from './admins/mealbooking/mealbooking.component';
import { MealapproveComponent } from './admins/mealapprove/mealapprove.component';
import { CertificateComponent } from './certificate/certificate.component';
import { RoleComponent } from './admins/role/role.component';
import { AbsenceComponent } from './absence/absence.component';
import { AbsenceapproveComponent } from './admins/absenceapprove/absenceapprove.component';
import { BranchesComponent } from './admins/branches/branches.component';
import { LoanComponent } from './loan/loan.component';
import { LoanapproveComponent } from './admins/loanapprove/loanapprove.component';
import { PayroladminComponent } from './admins/payroladmin/payroladmin.component';
import { KitchenComponent } from './admins/kitchen/kitchen.component';
import { ReportMonthlyComponent } from './admins/report-monthly/report-monthly.component';
import { ReportDailyComponent } from './admins/report-daily/report-daily.component';
import { ReportProviderComponent } from './admins/report-provider/report-provider.component';
import { ReportKitchenDeliveredComponent } from './admins/report-kitchen-delivered/report-kitchen-delivered.component';
import { ReportKitchenPendingComponent } from './admins/report-kitchen-pending/report-kitchen-pending.component';
import { KitchenprocessComponent } from './admins/kitchenprocess/kitchenprocess.component';
import { PollComponent } from './admins/poll/poll.component';
import { PollreportComponent } from './admins/pollreport/pollreport.component';

import { StaffapprovalComponent } from './admins/staffapproval/staffapproval.component';
import { StaffComponent } from './admins/staff/staff.component';

import { VacationComponent } from './vacation/vacation.component';
import { VacationapproveComponent } from './admins/vacationapprove/vacationapprove.component';

import { HeventComponent } from './admins/hevent/hevent.component';
import { AccountComponent } from './account/account.component';
import { OrgchartComponent } from './orgchart/orgchart.component';
import { BannerComponent } from './admins/banner/banner.component';
import { CommissionsComponent } from './admins/commissions/commissions.component';
import { ComissionCreateComponent } from './admins/commissions/comission-create/comission-create.component';
import { ComrolComponent } from './comrol/comrol.component';
import { SubsidiesComponent } from './admins/subsidies/subsidies.component';
import { DependantapproveComponent } from './admins/dependantapprove/dependantapprove.component';
import { ReportEmployeeComponent } from './admins/report-employee/report-employee.component';
import { ReportsComponent } from './admins/reports/reports.component';
import { ReportAdvanceComponent } from './admins/report-advance/report-advance.component';
import { ReportVacationComponent } from './admins/report-vacation/report-vacation.component';

import { ProfitComponent } from './profit/profit.component';
import { ProfitadminComponent } from './admins/profitadmin/profitadmin.component';

import { SettingsComponent } from './admins/settings/settings.component';
import { D3Component } from './d3/d3.component';

import { PermissionqueryComponent } from './admins/permissionquery/permissionquery.component';
import { PayrolqueryComponent } from './admins/payrolquery/payrolquery.component';
import { PayrolspecialqueryComponent } from './admins/payrolspecialquery/payrolspecialquery.component';
import { VacationqueryComponent } from './admins/vacationquery/vacationquery.component';
import { AdvancequeryComponent } from './admins/advancequery/advancequery.component';
import { AdvanceextqueryComponent } from './admins/advanceextquery/advanceextquery.component';
import { LoanqueryComponent } from './admins/loanquery/loanquery.component';
import { MycollabsComponent } from './admins/mycollabs/mycollabs.component';
import { AdminLunchComponent } from './admins/lunch/lunch.component';
import { AdminDinnerComponent } from './admins/dinner/dinner.component';
import { UpdateFormComponent } from './update-form/update-form.component';

import { BenefitreqComponent } from './benefitreq/benefitreq.component';
import { BenefitreqapproveComponent } from './admins/benefitreqapprove/benefitreqapprove.component';
import { ReportBenefitsreqComponent } from './admins/report-benefitsreq/report-benefitsreq.component';

import { RemoteworkComponent } from './remotework/remotework.component';
import { ReportRemoteworkComponent } from './admins/report-remotework/report-remotework.component';

import { LoginService } from '../services/authentication/login.service';
import { UploadTitleComponent } from './admins/flexhybridmode/uploadTitle/uploadTitle.component';
import { FlexHybridModeComponent } from './admins/flexhybridmode/flexhybridmode.component';
import { ScoreComponent } from './admins/flexhybridmode/score/score.component';
import { RemoteworkapproveComponent } from './admins/flexhybridmode/remoteworkapprove/remoteworkapprove.component';
import { FlexHybridComponent } from './flexhybrid/flexhybrid.component';

import { GpsriComponent } from './gpsri/gpsri.component';
import { GpapproveComponent } from './admins/sri/gpapprove/gpapprove.component';
import { GpreportComponent } from './admins/sri/gpreport/gpreport.component';
import { GpconfigComponent } from './admins/sri/gpconfig/gpconfig.component';

import { SrihomeComponent } from './admins/srihome/srihome.component';

export const Ic2routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [LoginService],
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'Dashboard',
          greet: true,
          urls: [
            /*{ title: 'Mapas', url: '' },
            { title: 'Mapas' }*/
          ]
        }
      },
      {
        path: 'collaborators',
        component: ColaboratorsComponent,
        canActivate: [LoginService],
        data: {
          title: 'Mis Servicios',
          urls: [
            /*{ title: 'Mapas', url: '' },
            { title: 'Mapas' }*/
          ]
        }
      },
      {
        path: 'admins',
        component: AdminsComponent,
        canActivate: [LoginService],
        data: {
          title: 'Sección administrativa',
          urls: [
            /*{ title: 'Mapas', url: '' },
            { title: 'Mapas' }*/
          ]
        }
      },
      {
        path: 'feeding',
        component: FeedhomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'Alimentación',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Alimentación'}
          ]
        }
      },
      {
        path: 'lunch',
        component: LunchComponent,
        canActivate: [LoginService],
        data: {
          title: 'Almuerzos',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Alimentación', url: '/feeding' },
            { title: 'Almuerzos'}
          ]
        }
      },
      {
        path: 'dinner',
        component: DinnerComponent,
        canActivate: [LoginService],
        data: {
          title: 'Meriendas',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Alimentación', url: '/feeding' },
            { title: 'Meriendas'}
          ]
        }
      },
      {
        path: 'mealhome',
        component: MealhomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'Menú alimentación',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú alimentación'}
          ]
        }
      },
      {
        path: 'mealcrud',
        component: MealComponent,
        canActivate: [LoginService],
        data: {
          title: 'Crear Plato',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú alimentación', url: '/mealhome' },
            { title: 'Crear Plato'}
          ]
        }
      },
      {
        path: 'mealbooking',
        component: MealbookingComponent,
        canActivate: [LoginService],
        data: {
          title: 'Crear Menú',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú alimentación', url: '/mealhome' },
            { title: 'Crear Menú'}
          ]
        }
      },
      {
        path: 'mealapprove',
        component: MealapproveComponent,
        canActivate: [LoginService],
        data: {
          title: 'Aprobar Menú',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú alimentación', url: '/mealhome' },
            { title: 'Aprobar Menú'}
          ]
        }
      },
      {
        path: 'mealcategory',
        component: MealcategoryComponent,
        canActivate: [LoginService],
        data: {
          title: 'Costo Categoría de Plato',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú alimentación', url: '/mealhome' },
            { title: 'Costo Categoría de Plato'}
          ]
        }
      },
      {
        path: 'docapproval',
        component: DocapprovalComponent,
        canActivate: [LoginService],
        data: {
          title: 'Esquema de aprobaciones',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Esquema de aprobaciones'}
          ]
        }
      },
      {
        path: 'advances',
        component: AdvanceComponent,
        canActivate: [LoginService],
        data: {
          title: 'Anticipos',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Anticipos'}
          ]
        }
      },
      {
        path: 'extadvances',
        component: AdvanceextComponent,
        canActivate: [LoginService],
        data: {
          title: 'Anticipos extraordinarios',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Anticipos extraordinarios'}
          ]
        }
      },
      {
        path: 'advance_approval',
        component: AdvanceapproveComponent,
        canActivate: [LoginService],
        data: {
          title: 'Aprobación de anticipos',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Aprobación de anticipos'}
          ]
        }
      },
      {
        path: 'benefits',
        component: BenefitComponent,
        canActivate: [LoginService],
        data: {
          title: 'Mis Consumos',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Mis Consumos'}
          ]
        }
      },
      {
        path: 'payrol',
        component: PayrolComponent,
        canActivate: [LoginService],
        data: {
          title: 'Roles de pago',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Roles de pago'}
          ]
        }
      },
      {
        path: 'profit',
        component: ProfitComponent,
        //canActivate: [LoginService],
        data: {
          title: 'Descuento Utilidades 2021',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Descuento Utilidades 2021'}
          ]
        }
      },
      {
        path: 'certificate',
        component: CertificateComponent,
        canActivate: [LoginService],
        data: {
          title: 'Certificado laboral',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Certificado laboral'}
          ]
        }
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [LoginService],
        data: {
          title: 'Mi perfil',
          urls: [
            /*{ title: 'Mapas', url: '' },
            { title: 'Mapas' }*/
          ]
        }
      },
      {
        path: 'maps',
        component: MapgoogleComponent,
        canActivate: [LoginService],
        data: {
          title: 'Zonas',
          urls: [
            /*{ title: 'Mapas', url: '' },
            { title: 'Mapas' }*/
          ]
        }
      },
      {
        path: 'customers',
        component: CustomersComponent,
        canActivate: [LoginService],
        data: {
          title: 'Clientes',
          urls: []
        }
      },
      {
        path: 'sellers',
        component: SellersComponent,
        canActivate: [LoginService],
        data: {
          title: 'Vendedores',
          urls: []
        }
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [LoginService],
        data: {
          title: 'Usuarios',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Usuarios'}
          ]
        }
      },
      {
        path: 'roles',
        component: RoleComponent,
        canActivate: [LoginService],
        data: {
          title: 'Roles de usuario',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Roles de usuario'}
          ]
        }
      },
      {
        path: 'branches',
        component: BranchesComponent,
        canActivate: [LoginService],
        data: {
          title: 'Sucursales',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Sucursales'}
          ]
        }
      },
      {
        path: 'absences',
        component: AbsenceComponent,
        canActivate: [LoginService],
        data: {
          title: 'Permisos',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Permisos'}
          ]
        }
      },
      {
        path: 'absence_approval',
        component: AbsenceapproveComponent,
        canActivate: [LoginService],
        data: {
          title: 'Aprobación de permisos',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Aprobación de permisos'}
          ]
        }
      },
      {
        path: 'loans',
        component: LoanComponent,
        canActivate: [LoginService],
        data: {
          title: 'Préstamos',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Préstamos'}
          ]
        }
      },
      {
        path: 'loan_approval',
        component: LoanapproveComponent,
        canActivate: [LoginService],
        data: {
          title: 'Aprobación de préstamos',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Aprobación de préstamos'}
          ]
        }
      },
      {
        path: 'profitadmin',
        component: ProfitadminComponent,
        //canActivate: [LoginService],
        data: {
          title: 'Mantenimiento Descuento Utilidades',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Mantenimiento Descuento Utilidades'}
          ]
        }
      },
      {
        path: 'payroladmin',
        component: PayroladminComponent,
        canActivate: [LoginService],
        data: {
          title: 'Mantenimiento Roles de Pago',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Mantenimiento Roles de Pago'}
          ]
        }
      },
      {
        path: 'kitchen',
        component: KitchenComponent,
        canActivate: [LoginService],
        data: {
          title: 'Menú Cocina',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Cocina'}
          ]
        }
      },
      {
        path: 'monthlyreport',
        component: ReportMonthlyComponent,
        canActivate: [LoginService],
        data: {
          title: 'Reporte Almuerzos Mensual',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Alimentación', url: '/mealhome' },
            { title: 'Reporte Almuerzos Mensual'}
          ]
        }
      },
      {
        path: 'dailyreport',
        component: ReportDailyComponent,
        canActivate: [LoginService],
        data: {
          title: 'Reporte Almuerzos Diario',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Alimentación', url: '/mealhome' },
            { title: 'Reporte Almuerzos Diario'}
          ]
        }
      },
      {
        path: 'providerreport',
        component: ReportProviderComponent,
        canActivate: [LoginService],
        data: {
          title: 'Reporte Almuerzos Proveedor',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Alimentación', url: '/mealhome' },
            { title: 'Reporte Almuerzos Proveedor'}
          ]
        }
      },
      {
        path: 'kitchendelivered',
        component: ReportKitchenDeliveredComponent,
        canActivate: [LoginService],
        data: {
          title: 'Reporte Almuerzos Despachados',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Cocina', url: '/kitchen' },
            { title: 'Reporte Almuerzos Despachados'}
          ]
        }
      },
      {
        path: 'kitchenpending',
        component: ReportKitchenPendingComponent,
        canActivate: [LoginService],
        data: {
          title: 'Reporte por Empleado y Estado',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Cocina', url: '/kitchen' },
            { title: 'Reporte por Empleado y Estado'}
          ]
        }
      },
      {
        path: 'kitchenprocess',
        component: KitchenprocessComponent,
        canActivate: [LoginService],
        data: {
          title: 'Consulta Pedido Cocina',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Cocina', url: '/kitchen' },
            { title: 'Consulta Pedido Cocina'}
          ]
        }
      },
      {
        path: 'polls',
        component: PollComponent,
        canActivate: [LoginService],
        data: {
          title: 'Encuestas',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Encuestas'}
          ]
        }
      },
      {
        path: 'pollreport',
        component: PollreportComponent,
        canActivate: [LoginService],
        data: {
          title: 'Reporte de Encuesta',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Encuestas', url: '/polls' },
            { title: 'Reporte de Encuesta'}
          ]
        }
      },
      {
        path: 'staff',
        component: StaffComponent,
        canActivate: [LoginService],
        data: {
          title: 'Nuevo personal',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Nuevo personal'}
          ]
        }
      },
      {
        path: 'staffapproval',
        component: StaffapprovalComponent,
        canActivate: [LoginService],
        data: {
          title: 'Aprobación solicitud de personal',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Aprobación solicitud de personal'}
          ]
        }
      },
      {
        path: 'vacations',
        component: VacationComponent,
        canActivate: [LoginService],
        data: {
          title: 'Vacaciones',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Vacaciones'}
          ]
        }
      },
      {
        path: 'vacation_approval',
        component: VacationapproveComponent,
        canActivate: [LoginService],
        data: {
          title: 'Aprobación de vacaciones',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Aprobación de vacaciones'}
          ]
        }
      },
      {
        path: 'events',
        component: HeventComponent,
        canActivate: [LoginService],
        data: {
          title: 'Comunicados',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Comunicados'}
          ]
        }
      },
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [LoginService],
        data: {
          title: 'Estado de Cuenta',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Estado de Cuenta'}
          ]
        }
      },
      {
        path: 'orgchart',
        component: OrgchartComponent,
        canActivate: [LoginService],
        data: {
          title: 'Organigrama',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Organigrama'}
          ]
        }
      },
      {
        path: 'banners',
        component: BannerComponent,
        canActivate: [LoginService],
        data: {
          title: 'Banners',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Banners'}
          ]
        }
      },
      {
        path: 'commissions',
        component: CommissionsComponent,
        canActivate: [LoginService],
        data: {
          title: 'Admin Comisiones',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Admin Comisiones'}
          ]
        }
      },
      {
        path: 'commission_create',
        component: ComissionCreateComponent,
        //canActivate: [LoginService],
        data: {
          title: 'Crear Comisión',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Admin Comisiones', url: '/commissions' },
            { title: 'Crear Comisión'}
          ]
        }
      },
      {
        path: 'comrol',
        component: ComrolComponent,
        canActivate: [LoginService],
        data: {
          title: 'Rol de Comisiones',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Rol de Comisiones'}
          ]
        }
      },
      {
        path: 'subsidies',
        component: SubsidiesComponent,
        canActivate: [LoginService],
        data: {
          title: 'Subsidios',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Subsidios'}
          ]
        }
      },
      {
        path: 'dependant_approval',
        component: DependantapproveComponent,
        canActivate: [LoginService],
        data: {
          title: 'Aprobación de cargas familiares',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Aprobación de cargas familiares'}
          ]
        }
      },
      {
        path: 'employee_report',
        component: ReportEmployeeComponent,
        canActivate: [LoginService],
        data: {
          title: 'Reporte de colaboradores',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Reporte de colaboradores'}
          ]
        }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [LoginService],
        data: {
          title: 'Configuración de Sistema',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Configuración de Sistema'}
          ]
        }
      },
      {
        path: 'd3',
        component: D3Component,
        //canActivate: [LoginService],
        data: {
          title: 'Nóminas Especiales',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Nóminas Especiales'}
          ]
        }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [LoginService],
        data: {
          title: 'Menú Reportes',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Reportes'}
          ]
        }
      },
      {
        path: 'advance_report',
        component: ReportAdvanceComponent,
        canActivate: [LoginService],
        data: {
          title: 'Reporte de anticipos',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Reportes', url: '/reports' },
            { title: 'Reporte de anticipos'}
          ]
        }
      },
      {
        path: 'vacation_report',
        component: ReportVacationComponent,
        canActivate: [LoginService],
        data: {
          title: 'Reporte de vacaciones',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Reportes', url: '/reports' },
            { title: 'Reporte de vacaciones'}
          ]
        }
      },
      {
        path: 'absence_query',
        component: PermissionqueryComponent,
        canActivate: [LoginService],
        data: {
          title: 'Consultar Permisos',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Consultar Permisos'}
          ]
        }
      },
      {
        path: 'payrol_query',
        component: PayrolqueryComponent,
        canActivate: [LoginService],
        data: {
          title: 'Consulta Roles de pago',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Consulta Roles de pago'}
          ]
        }
      },
      {
        path: 'payrolspecial_query',
        component: PayrolspecialqueryComponent,
        canActivate: [LoginService],
        data: {
          title: 'Consulta Nómina Especial',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Consulta Nómina Especial'}
          ]
        }
      },
      {
        path: 'vacation_query',
        component: VacationqueryComponent,
        canActivate: [LoginService],
        data: {
          title: 'Consulta Vacaciones',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Consulta Vacaciones'}
          ]
        }
      },
      {
        path: 'advance_query',
        component: AdvancequeryComponent,
        canActivate: [LoginService],
        data: {
          title: 'Consultar Anticipos',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Consultar Anticipos'}
          ]
        }
      },
      {
        path: 'advanceext_query',
        component: AdvanceextqueryComponent,
        canActivate: [LoginService],
        data: {
          title: 'Consultar Anticipos Ext.',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Consultar Anticipos Ext.'}
          ]
        }
      },
      {
        path: 'loan_query',
        component: LoanqueryComponent,
        canActivate: [LoginService],
        data: {
          title: 'Consultar Prestamos',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Consultar Prestamos'}
          ]
        }
      },
      {
        path: 'my_collabs',
        component: MycollabsComponent,
        canActivate: [LoginService],
        data: {
          title: 'Mis colaboradores',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Mis colaboradores'}
          ]
        }
      },
      {
        path: 'adminlunch',
        component: AdminLunchComponent,
        canActivate: [LoginService],
        data: {
          title: 'Almuerzos',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Alimentación', url: '/mealhome' },
            { title: 'Almuerzos'}
          ]
        }
      },
      {
        path: 'admindinner',
        component: AdminDinnerComponent,
        canActivate: [LoginService],
        data: {
          title: 'Meriendas',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Alimentación', url: '/mealhome' },
            { title: 'Meriendas'}
          ]
        }
      },
      {
        path: 'update-form',
        component: UpdateFormComponent,
        //canActivate: [LoginService],
        data: {
          title: 'Actualización de Datos',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Actualización de Datos'}
          ]
        }
      },
      {
        path: 'benefit-req',
        component: BenefitreqComponent,
        //canActivate: [LoginService],
        data: {
          title: 'Acumulación de Beneficios',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Acumulación de Beneficios'}
          ]
        }
      },
      {
        path: 'benefit-req-approval',
        component: BenefitreqapproveComponent,
        //canActivate: [LoginService],
        data: {
          title: 'Aprobación de acumulación de Beneficios',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Aprobación de acumulación de Beneficios'}
          ]
        }
      },
      {
        path: 'benefitreq_report',
        component: ReportBenefitsreqComponent,
        //canActivate: [LoginService],
        data: {
          title: 'Reporte de acumulación de Beneficios',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Reportes', url: '/reports' },
            { title: 'Reporte de acumulación de Beneficios'}
          ]
        }
      },
      {
        path: 'remote-work',
        component: RemoteworkComponent,
        canActivate: [LoginService],
        data: {
          title: 'Marcación Teletrabajo',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Marcación Teletrabajo'}
          ]
        }
      },
      {
        path: 'remotework_report',
        component: ReportRemoteworkComponent,
        canActivate: [LoginService],
        data: {
          title: 'Reporte de Marcaciones',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Reportes', url: '/reports' },
            { title: 'Reporte de Marcaciones'}
          ]
        }
      },
      {
        path: 'sri-gp',
        component: GpsriComponent,
        canActivate: [LoginService],
        data: {
          title: 'Formulario Gastos Personales',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Formulario Gastos Personales'}
          ]
        }
      },
      {
        path: 'sri-admin',
        component: SrihomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'Menú SRI',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú SRI'}
          ]
        }
      },
      {
        path: 'sri-gpapprove',
        component: GpapproveComponent,
        canActivate: [LoginService],
        data: {
          title: 'Aprobación de Gastos Personales',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú SRI', url: '/sri-admin' },
            { title: 'Aprobación de Gastos Personales'}
          ]
        }
      },
      {
        path: 'flexhybridmode',
        component: FlexHybridModeComponent,
        canActivate: [LoginService],
        data: {
          title: 'Menú Remoto',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Menú Remoto'}
          ]
        }
      },      
      {
        path: 'uploadTitle',
        component: UploadTitleComponent,
        canActivate: [LoginService],
        data: {
          title: 'Cargos a excluir',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Remoto', url: '/flexhybridmode' },
            { title: 'Formulario Cargos a excluir'}
          ]
        }
      },      
      {
        path: 'score',
        component: ScoreComponent,
        canActivate: [LoginService],
        data: {
          title: 'Calificaciones',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Remoto', url: '/flexhybridmode' },
            { title: 'Formulario de Calificaciones'}
          ]
        }
      },      
      {
        path: 'remotework_approval',
        component: RemoteworkapproveComponent,
        canActivate: [LoginService],
        data: {
          title: 'Aprobación de trabajo remoto',
          urls: [
            { title: 'Sección administrativa', url: '/admins' },
            { title: 'Remoto', url: '/flexhybridmode' },
            { title: 'Aprobación de trabajo remoto'}
          ]
        }
      },
      {
        path: 'flexhybrid',
        component: FlexHybridComponent,
        canActivate: [LoginService],
        data: {
          title: 'Formulario Modalidad de Trabajo',
          urls: [
            { title: 'Mis servicios', url: '/collaborators' },
            { title: 'Formulario Modalidad de Trabajo'}
          ]
        }
      }
      //end
    ]
  },
];
