import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../../services/global';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../../services/authentication/login.service';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.css']
})
export class ScoreComponent extends BaseCrudComponent {

    translations:any;
    files:any;
    filetypes:any;
    img_exts:any;
    api_url:any;
    file_url:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'score/list';
        this.create_url = 'score/upload';
        this.delete_url = '';
        this.search_fields = ['code', 'name'];

        this.files = [];
        this.filetypes = [];

        this.img_exts = ['png', 'jpg', 'jpeg'];
        this.api_url = Global.url;
        this.file_url = this.api_url + 'banner/';

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;//test
        var tosend = {};

        this.loadingList = true;
        
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }
        });
    }

    openCreateModal(content:any){
        this.c_obj = {};
        this.c_obj.img = '';
        this.files = [];

        super.openCreateModal(content);
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            /*'desc': new FormControl(fields.desc, [Validators.required]),
            'order_num': new FormControl(fields.order_num, [Validators.required])*/
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        if( this.files.length == 0 ){
        	Swal.fire('No archivo seleccionado', '', 'error');
            return;
        }

        const file = this.files[0];
		const formData = new FormData();
    	formData.append('file', file);
    	

        var qst = this.create_url;
        this.isCreating = true;

    	this.srv.customPostCall(qst, formData).subscribe(x => {
            this.isCreating = false;

            if(x.status){
            	this.c_obj = {};
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations.created, '', 'success');
            }//end success
            else{
                Swal.fire(x.message, '', 'error');
            }
        });
    }

    handleInputChange(e, obj:any) {
	    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
	    //console.log(file);
	    this.files = [];
	    this.files.push(file);
	    /*var reader = new FileReader();
	    reader.readAsDataURL(file);*/
	}


}
