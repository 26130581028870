import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';

@Component({
  selector: 'app-globalparams',
  templateUrl: './globalparams.component.html',
  styleUrls: ['./globalparams.component.css']
})
export class GlobalparamsComponent extends BaseCrudComponent {
    params:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router
      ) {
        super(srv, modalService, storage, router);
        this.create_url = 'Parameter/InsertParameter';
        this.read_url = 'parameter/GlobalParams';
        this.update_url = 'Parameter/InsertParameter';
        this.delete_url = 'parameter/DeleteGlobalParameter';

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.regularForm = new FormGroup({
            /*'ParName': new FormControl(null, [Validators.required]),
            'Partype': new FormControl(null, [Validators.required]),
            'ParIdProvider': new FormControl(null, [Validators.required]),
            'ParKeyProvider': new FormControl(null, [Validators.required])*/
        }, {updateOn: 'blur'});

        this.getList();
    }

    public getList(){
        var qst = this.read_url;
        this.srv.getCall(qst).subscribe(x => {
            if(x.success){
                var rs = x.val;

                rs.forEach(x => {
                  //console.log(x);
                  if(x.Parameter.Partype == 0){
                    x.Parameter.ParTypeStr = 'Parametro';
                  }
                  else{
                    x.Parameter.ParTypeStr = 'Alarma';
                  }

                  var dep_str = [];
                  x.Parameter.Dependence.forEach(y => {
                        //dep_str.push(y.DepName);
                        dep_str.push(y.Parameter1.ParName);
                  });

                  x.dependencies = dep_str.join(' | ');
                });

                this.rows = rs;
            }
        });
    }

    public createItem(content:any, form:any){
        if( form == undefined ){
          form = {};
        }

        var to:any;

        if( (form.ParName == undefined) || (form.ParName == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        to = {};
        to.ParId = 0;
        to.ParName = form.ParName;
        to.Partype = form.Partype;
        to.ParIndicatorType = 2;
        to.ParIdProvider = form.ParIdProvider;
        to.ParKeyProvider = form.ParKeyProvider;
        to.ParameterSetting = [{
            ParSMin: 0,
            ParSMax: 0,
            ParSType: 0,
            ParSItemId: 0,
            ParSStatus: 0
        }];

        super.createItem(content, to);
    }

    public updateItem(content:any, form:any){
        if( form == undefined ){
          form = {};
        }

        var to:any;
        var deps = [];

        if( (form.Parameter.ParName == undefined) || (form.Parameter.ParName == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        if( (form.ParSStatus == undefined) || (form.ParSStatus == '') ){
            Swal.fire('Ingrese todos los campos requeridos', 'msg', 'success');
            return;
        }

        var vm = this;
        vm.params.forEach(y => {
            if( y.Parameter.selected ){
                deps.push({
                    id: (y.Parameter.id != undefined) ? y.Parameter.id : 0,
                    DepidDependence: y.Parameter.ParId,
                    DepidParameter: form.Parameter.ParId
                });
            }
        });

        to = {};
        to.ParId = form.Parameter.ParId;
        to.ParName = form.Parameter.ParName;
        to.Partype = form.Parameter.Partype;
        to.ParIndicatorType = form.Parameter.ParIndicatorType;
        to.ParFieldKey = form.Parameter.ParFieldKey;
            to.ParIdProvider = form.Parameter.ParIdProvider;
            to.ParKeyProvider = form.Parameter.ParKeyProvider;

            to.ParameterSetting = [{
                ParSMin: (form.ParSmin != undefined)?form.ParSmin:'',
                ParSMax: (form.ParSmax != undefined)?form.ParSmax:'',
                ParSType: 0,
                ParSItemId: (form.ParSItemId != undefined)?form.ParSItemId:0,
                ParSStatus: (form.ParSStatus != undefined)?form.ParSStatus:0
            }];
        
        to.Dependence = deps;

        super.updateItem(content, to);
    }

    public openUpdateModal(content: any, row: any) {
        var vm = this;
        vm.params = [];
        var all = this.rows;

        if( all.length > 0 ){
            all.forEach(x => {
              if( x.Parameter.ParId != row.Parameter.ParId ){
                vm.params.push(x);
              }
            });
        }

        row.Parameter.Dependence.forEach(x => {
            vm.params.forEach(y => {
                if( y.Parameter.ParId == x.DepidDependence ){
                    y.Parameter.selected = true;
                    y.Parameter.id = x.id;
                }
                else{
                    //y.Parameter.selected = false;
                }
            });
        });

        super.openUpdateModal(content, row);
    }

}
