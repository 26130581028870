import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { LoginService } from '../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-loanapprove',
  templateUrl: './loanapprove.component.html',
  styleUrls: ['./loanapprove.component.css']
})
export class LoanapproveComponent extends BaseCrudComponent {

  	translations:any;
    loadingRequired:any;
    listApprovers:any;
    sudo:any;
    showSudo:any;
    download_url:any;
    details:any;
    dtypes = [
        {id:1, name:'ANTICIPO'},
        {id:2, name:'PRESTAMO'}
    ];

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'admin/loan';
        this.create_url = '';
        this.update_url = 'admin/loan';
        this.delete_url = '';
        this.search_fields = ['loan.id'];
        this.listApprovers = [];
        this.loadingRequired = false;
        this.download_url = 'loan/doc';
        
        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;
        this.details = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
            this.getApproversData();
            
            this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    this.getApproversData();
                }
            });
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                    /*this.rows.forEach(x => {
                    	//used for search bar
                    	x.doctype_str = x.doctype.description;
                    	x.userto_str = x.userto.name;
                    	x.responsible_str = x.responsible.name;
                    });*/
                }

                this.filterAll();
            }
        });
    }

    public approve(id:any, content) {
        var qst = 'admin/loan/approve/' + id;
        var tosend = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        Swal.fire({
            title: '¿Aprobar préstamo?',
            text: '',
            icon: 'success',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'OK',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.srv.postCall(qst, tosend).toPromise().then(x => {
                    if(x.status){
                        this.reload.next(true);
                        this.closeModal(content);
                        Swal.fire('Aprobado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            /*if (result.value) {
                this.srv.postCall(qst, {}).subscribe(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Aprobado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            }*/
        });
    }

    public reject(id:any, content) {
        var qst = 'admin/loan/reject/' + id;
        var tosend = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        Swal.fire({
            title: '¿Rechazar préstamo?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'OK',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.srv.postCall(qst, tosend).toPromise().then(x => {
                    if(x.status){
                        this.reload.next(true);
                        this.closeModal(content);
                        Swal.fire('Rechazado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            /*if (result.value) {
                this.srv.postCall(qst, {}).subscribe(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Rechazado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            }*/
        });
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    openUpdateModal(content:any, obj:any){
        this.details = obj['loan']['details'];
        obj['loan']['approval_id'] = obj['id'];
        super.openUpdateModal(content, obj['loan']);
    }

    public showFile(id:any){
        var url = this.download_url + '/' + id;

        this.srv.getBlobCall(url).subscribe(resp => {
            var myurl = URL.createObjectURL(resp);
            window.open(myurl);
        });
    }

    public updateItem(content:any, form:any){
        if( form == undefined ){
          form = {};
        }

        form.details = this.details;

        var qst = this.update_url + '/' + form.id;
        this.isUpdating = true;

        this.srv.postCall(qst, form).subscribe(x => {
            this.isUpdating = false;
            if(x.status){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.message, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    public addDetail(){
        var tmp = {dtype:1, start_date:null, amount:null, quotes:null, monthly_amount:null, pending_quotes:null, comment:null};
        this.details.push(tmp);
    }

    public removeDetail(i){
        this.details.splice(i, 1);
    }

    public downloadPDF(obj){

    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

}
