import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../../services/main/api.service';
import { LoginService } from '../../../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../../services/global';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-gpapprove',
  templateUrl: './gpapprove.component.html',
  styleUrls: ['./gpapprove.component.css']
})
export class GpapproveComponent extends BaseCrudComponent {

    translations:any;
    loadingRequired:any;
    listApprovers:any;
    sudo:any;
    showSudo:any;
    balance:any;
    movements:any;
    total:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'admin/srigp';
        this.create_url = 'admin/srigp';
        this.update_url = 'admin/srigp';
        this.delete_url = '';
        this.search_fields = ['vacation.id'];
        this.listApprovers = [];
        this.loadingRequired = false;
        this.balance = [];
        this.movements = [];
        this.total = 0;
        
        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
            this.getApproversData();
            
            this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    this.getApproversData();
                }
            });
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        this.srv.getCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                    /*this.rows.forEach(x => {
                      //used for search bar
                      x.doctype_str = x.doctype.description;
                      x.userto_str = x.userto.name;
                      x.responsible_str = x.responsible.name;
                    });*/
                }

                this.filterAll();
            }
        });
    }

    public approve(id:any) {
        var qst = 'admin/srigp/approve/' + id;
        var tosend = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        Swal.fire({
            title: '¿Aprobar formulario de gastos personales?',
            text: '',
            icon: 'success',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'OK',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.srv.postCall(qst, tosend).toPromise().then(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Aprobado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            /*if (result.value) {
                this.srv.postCall(qst, {}).subscribe(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Aprobado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            }*/
        });
    }

    public reject(id:any) {
        var qst = 'admin/srigp/reject/' + id;
        var tosend = {};
        
        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        Swal.fire({
            title: '¿Rechazar formulario de gastos personales?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'OK',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.srv.postCall(qst, tosend).toPromise().then(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Rechazado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            /*if (result.value) {
                this.srv.postCall(qst, {}).subscribe(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('Rechazado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'error');
                    }
                });
            }*/
        });
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

    openUpdateModal(content:any, obj:any){
        super.openUpdateModal(content, obj);

        this.loadingRequired = true;
        this.getActualsData(obj.vacation.owner.id).subscribe(x => {
            this.loadingRequired = false;
            this.u_obj['total'] = x['total'];
            this.u_obj['balance'] = x['balance'];
            this.u_obj['movements'] = x['kardex'];
        });
    }

    public getActualsData(id:any){
        var par_url = 'admin/remotework/actuals/' + id;

        return this.srv.getCall(par_url).pipe(
                    mergeMap(x => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = x;
                        //console.log(data);
                        
                        if(mydata.status == true ){
                            return of(x.data.rows);
                        }
                        else{
                            Swal.fire(mydata.message, '', 'error');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

}
