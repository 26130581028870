import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { Global } from '../../services/global';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, of, throwError, from, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-base-crud',
  templateUrl: './base-crud.component.html',
  styleUrls: ['./base-crud.component.css']
})

export class BaseCrudComponent implements OnInit {
    public rows: any;
    public rawdata: any;
    public create_url: any;
    public read_url: any;
    public update_url: any;
    public delete_url: any;
    public regularForm: FormGroup;
    public reload = new BehaviorSubject(false);

    public c_obj: any;
    public u_obj: any;
    public i_obj: any;

    public loadingList = false;
    public isCreating = false;
    public isUpdating = false;
    public isDownloading = false;

    page:any;
    pageTotal:any;
    pageSize:any;

    search:any;
    orderKey:any;
    orderDesc:any;
    search_fields:any;

    f1_key:string;
    f2_key:string;
    f3_key:string;

    f1_val:string;
    f2_val:string;
    f3_val:string;

    translations:any;

    //filter to send
    public showFilters = false;
    public f_datefrom:any;
    public f_dateto:any;
    public f_custom1:any;

	constructor(
      public srv: ApiService,
      public modalService: NgbModal,
      public storage: Storage,
      public router: Router,
      public translate?: TranslateService
    ) {
        this.rows = [];
        this.rawdata = [];
        this.create_url = '';
        this.read_url = '';
        this.update_url = '';
        this.delete_url = '';

        //pagination
        this.page = 1;
        this.pageTotal = 0;
        this.pageSize = 15;

        this.c_obj = {};
        this.u_obj = {};
        this.i_obj = {};

        this.search = '';
        this.orderKey = null;
        this.orderDesc = true;
        //should reference  only to string fields
        this.search_fields = [];
        this.f1_key = 'CompName';
        this.f2_key = 'SitName';
        this.f3_key = 'status';
        this.f1_val = '';
        this.f2_val = '';
        this.f3_val = '';

        this.f_datefrom = null;
        this.f_dateto = null;
        this.f_custom1 = null;
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            //console.log(translations);
        });
    }

    public clearData(){
        this.rows = [];
        this.c_obj = {};
        this.u_obj = {};
    }

    public getList(){
        var qst = this.read_url;
        
        this.loadingList = true;
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;

            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
            }
            else{
                Swal.fire(x.message, '', 'info');
            }
        });
    }

    public getListPost(fields:any){
        var qst = this.read_url;
        this.loadingList = true;

        this.srv.postCall(qst, fields).subscribe(x => {
            this.loadingList = false;
            
            if(x.status){
                this.rawdata = x.data.rows;
                this.rows = x.data.rows;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
            }
            else{
                Swal.fire(x.message, '', 'info');
            }
        });
    }

    translateMessage(str:any){
        let msg = '';

        if( this.translations[str+''] != undefined ){
            msg = this.translations[str+''];
        }

        return msg;
    }

    public createItem(content:any, fields:any){
        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, fields).subscribe(x => {
            this.isCreating = false;

            if(x.status){
                this.reload.next(true);
                this.closeModal(content);
                Swal.fire('¡Creado!', '', 'success');
            }
            else{
                Swal.fire(x.message, '', 'info');
            }
        });
    }

    public updateItem(content:any, fields:any){
        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, fields).subscribe(x => {
            this.isUpdating = false;

            if(x.status){
                this.reload.next(true);
                this.closeModal(content);
                Swal.fire('¡Actualizado!', '', 'success');
            }
            else{
                Swal.fire(x.message, '', 'info');
            }
        });
    }

    public deleteItem(obj: any, id?:any) {
        var qst = this.delete_url;

        if( id != undefined ){
            qst += '/' + id;
        }

        Swal.fire({
            title: '¿Esta seguro que desea eliminarlo?',
            text: `Si acepta se eliminará el registro`,
            icon: 'warning',
            showCancelButton: true,
            /*confirmButtonColor: '#f39000',
            cancelButtonColor: '#80a41b',*/
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar'
        }).then((result) => {
            if (result.value) {
                this.srv.postCall(qst, obj).subscribe(x => {
                    if(x.status){
                        this.reload.next(true);
                        Swal.fire('¡Eliminado!', '', 'success');
                    }
                    else{
                        Swal.fire(x.message, '', 'info');
                    }
                });
            }
        });
    }

    public openCreateModal(content: any) {
        this.modalService.open(content, { centered: true });
    }

    public openCreateModalLG(content: any) {
        this.modalService.open(content, { size: 'lg', centered: true });
    }    

    public openUpdateModal(content: any, obj: any, size?:any) {
        let newobj = JSON.parse(JSON.stringify(obj));
        /*const copy = { ...obj };
        this.u_obj = copy;*/
        this.u_obj = newobj;

        if( size == undefined ){
            this.modalService.open(content, { centered: true });
        }
        else{
            this.modalService.open(content, { centered: true, size: size });
        }
        
    }

    public closeModal(content: any) {
        this.modalService.dismissAll(content);
    }

    public goTo(url, params){
        this.router.navigate([url], {state: params});
    }

    public filterList(fields:any){
        //this.search = event.target.value;
        var resp:boolean;
        if( !Array.isArray(fields) ){
            return;
        }

        if( this.search == '' ){
            this.rows = this.rawdata;
        }
        else{
            let test = this.rawdata.filter(s => {
                resp = false;
                fields.forEach(f => {
                    resp = resp || s[f].toLowerCase().includes(this.search.toLowerCase());
                });
                return resp;
            });

            this.rows = test;
        }

        this.pageTotal = this.rows.length;
    }

    public filterAll(){
        var fields = this.search_fields;
        var resp:boolean;
        var dummy:any;

        if( !Array.isArray(fields) ){
            return;
        }

        if( this.search == '' ){
            this.rows = this.rawdata;
        }
        else{
            let test = this.rawdata.filter(s => {
                resp = false;
                fields.forEach(f => {
                    if( f.indexOf('.') > -1 ){
                        let parts = f.split('.');
                        let f1 = parts[0];
                        let f2 = parts[1];
                        //console.log(parts);

                        if( (s[f1] !== null) && (s[f1] !== undefined) ){
                            resp = resp || String(s[f1][f2]).toLowerCase().includes(this.search.toLowerCase());
                        }
                    }
                    else if( (s[f] !== null) && (s[f] !== undefined) ){
                        resp = resp || String(s[f]).toLowerCase().includes(this.search.toLowerCase());
                    }
                });
                return resp;
            });

            this.rows = test;
        }

        if( this.f1_val != '' ){
            /*console.log('btw');
            console.log(this.rows);
            console.log(this.f1_val);*/
            dummy = this.rows.filter(s => {
                return s[this.f1_key] == this.f1_val;
            });

            this.rows = dummy;
        }

        if( this.f2_val != '' ){
            dummy = this.rows.filter(s => {
                return s[this.f2_key] == this.f2_val;
            });

            this.rows = dummy;
        }

        if( this.f3_val != '' ){
            dummy = this.rows.filter(s => {
                return s[this.f3_key] == this.f3_val;
            });

            this.rows = dummy;
        }

        this.pageTotal = this.rows.length;
    }

    public onKeyUpSearch(ev:any){
        var str = ev.target.value;
        if( (str == '') && (this.rows.length != this.rawdata.length) ){
            //console.log('works');
            this.rows = this.rawdata;
            this.pageTotal = this.rows.length;
        }
    }

    public copyObject(obj:any){
        var to:any;
        to = {};
        var keys = [];
        
        keys = Object.keys(obj);
        if( keys.length > 0 ){
            keys.forEach(x => {
                to[x] = obj[x];
            });
        }

        return to;
    }

    public openInfoModalBig(content: any, obj: any) {
        const copy = { ...obj };
        this.i_obj = copy;
        this.modalService.open(content, { size: 'lg', centered: true});
    }

    public setOrder(str:any){
        this.orderKey = str;
        this.orderDesc = !this.orderDesc;
    }

    public clearSentFilters(){
        this.f_datefrom = null;
        this.f_dateto = null;
        this.f_custom1 = null;
    }

    public getISODate(d:any){
        let fulliso:any;
        //ngbDate count month from 1 but js Date from 0
        fulliso = new Date(d['year'], parseInt(d['month'])-1, d['day']);
        fulliso = fulliso.toISOString().substring(0, 10);
        return fulliso;
    }

}
