import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/authentication/login.service';

@Component({
  selector: 'app-feedhome',
  templateUrl: './feedhome.component.html',
  styleUrls: ['./feedhome.component.css']
})
export class FeedhomeComponent implements OnInit {

  	menu:any;
    permissions = [];

  	constructor(public auth: LoginService) { }

  	ngOnInit() {
  		this.menu = [
  			{name:'Almuerzos', desc:'Reserva almuerzos y breaks', icon:'fas fa-sun', url:'/lunch', perm: 'SERVICIOS-ALIMENTACION-ALMUERZOS'},
  			{name:'Meriendas', desc:'Reserva meriendas', icon:'fas fa-moon', url:'/dinner', perm: 'SERVICIOS-ALIMENTACION-MERIENDAS'}
  		];

  		this.auth.getPermissions().then(x => {
            this.permissions = x;
        });
  	  }

  	hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }

}
