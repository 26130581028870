import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';

import { Observable, from, of, concat, Subscription } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-report-employee',
  templateUrl: './report-employee.component.html',
  styleUrls: ['./report-employee.component.css']
})
export class ReportEmployeeComponent extends BaseCrudComponent {

  	translations:any;
    momentjs: any = moment;
    teamid:any;
    teamsubscription: Subscription;

    sel_personal = 0;
    sel_contacts = 0;
    sel_dependants = 0;
    sel_education = 0;
    sel_experience = 0;

    tab_personal = 0;
    tab_contacts = 0;
    tab_dependants = 0;
    tab_education = 0;
    tab_experience = 0;

    dob = null;
    sex = '';
    status = '';
    cb_status = [];
    cb_sex = [];

    fields_personal = [
        {key: 'sex', desc: 'Sexo'},
        {key: 'civil_status', desc: 'Est. Civil'},
        {key: 'dob', desc: 'F. Nacimiento'},
        {key: 'nationality', desc: 'Nacionalidad'},
        {key: 'birth_state', desc: 'Provincia Nac.'},
        {key: 'birth_city', desc: 'Ciudad Nac.'},
        {key: 'home_country', desc: 'País Res.'},
        {key: 'home_state', desc: 'Provincia Res.'},
        {key: 'home_city', desc: 'Ciudad Res.'},
        {key: 'home_type', desc: 'Tipo de Casa'},
        {key: 'home_ownership', desc: 'Tenencia'},
        {key: 'home_number', desc: 'No Casa'},
        {key: 'home_phone', desc: 'Telef. Casa'},
        {key: 'home_ref', desc: 'Ref. Casa'},
        {key: 'home_sector', desc: 'Sector'},
        {key: 'home_location', desc: 'Ubicada en'},
        {key: 'home_address', desc: 'Dirección'},
        {key: 'home_level', desc: 'Piso'},
        {key: 'cellphone', desc: 'Telef. Móvil'},
        {key: 'zipcode', desc: 'Cod. Postal'}
    ];

    fields_contacts = [
        {key: 'rel_id', desc: 'Relación'},
        {key: 'name', desc: 'Nombre'},
        {key: 'lastname', desc: 'Apellido'},
        {key: 'address', desc: 'Dirección'},
        {key: 'phone_home', desc: 'Teléfono'},
        {key: 'phone_mobile', desc: 'Móvil'},
        {key: 'email', desc: 'Email'}
    ];

    fields_dependants = [
        {key: 'fam_id', desc: 'Relación'},
        {key: 'doc_id', desc: 'Tipo Documento'},
        {key: 'doc_val', desc: 'Documento'},
        {key: 'first_name', desc: 'Nombre'},
        {key: 'last_name', desc: 'Apellido'},
        {key: 'dob', desc: 'F. Nacimiento'},
        {key: 'nationality_id', desc: 'Nacionalidad'},
        {key: 'province_id', desc: 'Provincia Nac.'},
        {key: 'city_id', desc: 'Ciudad Nac.'},
        {key: 'status', desc: 'Status'}
    ];

    fields_education = [
        {key: 'inst_name', desc: 'Institución'},
        {key: 'edu_id', desc: 'Educación'},
        {key: 'grade_id', desc: 'Título'},
        {key: 'area_id', desc: 'Carrera'},
        {key: 'country_id', desc: 'País'},
        {key: 'start_date', desc: 'F. Inicio'},
        {key: 'end_date', desc: 'F. Fin'},
        {key: 'status', desc: 'Status'}
    ];

    fields_experience = [
        {key: 'company', desc: 'Compañía'},
        {key: 'title', desc: 'Título'},
        {key: 'country', desc: 'País'},
        {key: 'start_date', desc: 'F. Inicio'},
        {key: 'end_date', desc: 'F. Fin'}
    ];

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public _login: LoginService
      ) {
        super(srv, modalService, storage, router);

        this.read_url = 'employeereport/list';
        this.create_url = '';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.clearData();
                //this.getList();
            }
        });

        this.teamsubscription = this.srv.team_changed.subscribe(val => {
        	if( val ){
        		//this.getList();
                this.clearData();
        	}
        });
    }

    public clearData(){
        this.rows = [];
        this.tab_personal = 0;
        this.tab_contacts = 0;
        this.tab_dependants = 0;
        this.tab_education = 0;
        this.tab_experience = 0;
    }

    public clearFilters(){
        this.search = '';
        this.dob = null;
        this.sex = '';
        this.status = '';

        this.sel_personal = 0;
        this.sel_contacts = 0;
        this.sel_dependants = 0;
        this.sel_education = 0;
        this.sel_experience = 0;
    }

    ngOnInit() {
        //this.f_datefrom = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');

        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;

              this.storage.get('common').then(c => {
                    this.cb_sex = c['data']['sex'];
                    this.cb_status = c['data']['civil'];
              });

              super.ngOnInit();
              //this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};
        this.clearData();

        this._login.getTeam().then(x => {
	        this.teamid = x;
            tosend['tid'] = this.teamid;
            tosend['dob'] = this.dob;
            tosend['status'] = this.status;
            tosend['sex'] = this.sex;
            tosend['name'] = this.search;

            tosend['personal'] = this.sel_personal;
            tosend['contacts'] = this.sel_contacts;
            //tosend['expenses'] = this.sel_personal;
            tosend['dependants'] = this.sel_dependants;
            tosend['education'] = this.sel_education;
            tosend['experience'] = this.sel_experience;
	        
	        this.loadingList = true;
	        this.srv.postCall(qst, tosend).subscribe(x => {
	            this.loadingList = false;
	            
	            if(x.status){
	                this.rows = x.data.rows;
                    this.tab_personal = this.sel_personal;
                    this.tab_contacts = this.sel_contacts;
                    this.tab_dependants = this.sel_dependants;
                    this.tab_education = this.sel_education;
                    this.tab_experience = this.sel_experience;
	            }
	        });
	    });
    }

    public download(){
		var url = this.read_url;
        var tosend = {};

        this._login.getTeam().then(x => {
	        this.teamid = x;
            tosend['tid'] = this.teamid;
            tosend['dob'] = this.dob;
            tosend['status'] = this.status;
            tosend['sex'] = this.sex;
            tosend['name'] = this.search;

            tosend['personal'] = this.sel_personal;
            tosend['contacts'] = this.sel_contacts;
            //tosend['expenses'] = this.sel_personal;
            tosend['dependants'] = this.sel_dependants;
            tosend['education'] = this.sel_education;
            tosend['experience'] = this.sel_experience;

	        tosend['export'] = 1;
	        
	        this.isDownloading = true;
	        this.srv.postBlobCall(url, tosend).subscribe(resp => {
	            this.isDownloading = false;
	            var myurl = URL.createObjectURL(resp);
	            //console.log(resp);
	            window.open(myurl);
	        });
	    });
    }

    public getValue(obj, field){
        let str = '';
        if( obj != undefined ){
            if( (obj[field] != undefined) && (obj[field] != null) ){
                str = obj[field];
            }
        }

        return str;
    }

}
