import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/authentication/login.service';

@Component({
  selector: 'app-kitchen',
  templateUrl: './kitchen.component.html',
  styleUrls: ['./kitchen.component.css']
})
export class KitchenComponent implements OnInit {

  menu:any;
  permissions = [];

	constructor(public auth: LoginService) { }

	ngOnInit() {
  		this.menu = [
  	      {name:'Despachar', desc:'Despachar platos', icon:'fas fa-tasks', url:'/kitchenprocess', perm: 'ADMINS-COCINA-DESPACHO'},
  	      {name:'Reporte Despachados', desc:'Reporte Despachados', icon:'fas fa-file-alt', url:'/kitchendelivered', perm: 'ADMINS-COCINA-REPORTE_DESPACHO'}
  		];

  		this.auth.getPermissions().then(x => {
              this.permissions = x;
          });
	}

	hasPermission(str:string){
      if( this.permissions.indexOf(str) > -1 ){
          return true;
      }
      else{
          return false;
      }
  }

}
