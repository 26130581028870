import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  	title = 'app';
  	version:any;

  	constructor(private translate: TranslateService, private swUpdate: SwUpdate) {
        var userLang = 'es';
        translate.setDefaultLang(userLang);
        translate.use(userLang);
    }

    ngOnInit() {
	    //this.version = environment.version;

	    if (this.swUpdate.isEnabled) {
	        this.swUpdate.available.subscribe(() => {
	           	let upd_msg = "Nueva versión disponible, actualizar?";

	            if(confirm(upd_msg)) {
	                window.location.reload();
	            }
	        });
	    }
	}
}
