import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { LoginService } from '../../services/authentication/login.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-comrol',
  templateUrl: './comrol.component.html',
  styleUrls: ['./comrol.component.css']
})
export class ComrolComponent extends BaseCrudComponent {

  	info:any;
	currdate:any;
    translations:any;
    download_url:any;
    show_details = false;

    listApprovers:any;
    sudo:any;
    showSudo:any;

    //sort details
    sort_field:string;
    sort_asc:boolean = false;

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        public auth: LoginService
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'commission';
        this.download_url = 'commission';
        this.update_url = '';

        this.info = false;
        this.currdate = '';

        this.listApprovers = [];
        this.sudo = null;
        this.showSudo = false;
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              //test purpose
              //this.currdate = '2023-05';
              /*this.getListPost();*/

            this.auth.hasRole(['superadmin']).then(r => {
            //this.auth.hasRole(['superadmin','admin']).then(r => {
                this.showSudo = r;
                if( r ){
                    this.getApproversData();
                }
            });
        });
    }

    getListPost(){
        var qst = this.read_url;
        var from = '';
        var tosend = {};
        this.show_details = false;

        if( this.currdate == '' ){
        	Swal.fire('Debe seleccionar fecha', '', 'error');
        	return;
        }
        else{
        	from = this.currdate + '-01';
        }

        tosend['start'] = from;

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.loadingList = true;
        this.info = false;

        this.srv.postCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            
            if( x.status && (x.data['fields'] != undefined) && (x.data['fields']['name'] != undefined) ){
                this.info = x.data.fields;
                //console.dir(this.info);

                if( this.info['type'] != 'jefe' ){
                    this.info['header'] = this.info['header'][0];
                }
                else{
                    let all = this.info['header'];
                    this.info['header'] = all[0];
                    this.info['kpis'] = all;
                    this.info['comission_total'] = 0;

                    if( Array.isArray(this.info['kpis']) ){
                        this.info['kpis'].forEach(x => {
                            this.info['comission_total'] += parseFloat(x['VALOR_COMISION']);
                        });
                    }
                }

                //used to show perc cumplimiento total
                this.info['cumplimiento_calculado'] = 0;
                let calc = 0;

                switch( this.info['type'] ){
                    case 'tope':
                    case 'superv':
                    case 'varios':
                    case 'tele':
                        calc = parseFloat(this.info.header['COMISION_RECIBIR']) / parseFloat(this.info.header['MONTO_MAXIMO']);
                        this.info['cumplimiento_calculado'] = calc;
                    break;

                    case 'ofic':
                        calc = parseFloat(this.info.header['COMISION_A_PAGAR']) / parseFloat(this.info.header['MONTO']);
                        this.info['cumplimiento_calculado'] = calc;
                    break;

                    case 'jefe':
                        calc = parseFloat(this.info['comission_total']) / parseFloat(this.info.header['MONTO']);
                        this.info['cumplimiento_calculado'] = calc;
                    break;

                    default:
                        //normal type
                        calc = parseFloat(this.info.header['COMISION_A_PAGAR']) / parseFloat(this.info.header['MARGEN_COMISION']);
                        this.info['cumplimiento_calculado'] = calc;
                    break;
                }
            }
        });
    }

    public download(){
        var url = this.download_url;
        var from = '';
        var tosend = {};

        if( this.currdate == '' ){
            Swal.fire('Debe seleccionar fecha', '', 'error');
            return;
        }
        else{
            from = this.currdate + '-01';
        }

        tosend['start'] = from;
        tosend['download'] = 1;

        if( (this.sudo != undefined) && (this.sudo != null) ){
            tosend['uid'] = this.sudo.id;
        }

        this.isDownloading = true;

        this.srv.postBlobCall(url, tosend).subscribe(resp => {
            this.isDownloading = false;
            var myurl = URL.createObjectURL(resp);
            //console.log(resp);
            window.open(myurl);
        });
    }

    public showDetails(){
        this.show_details = !this.show_details;
    }

    public getApproversData(){
        var par_url = 'admin/users/list';
        this.srv.getCall(par_url).subscribe(x => {
            this.listApprovers = x.data.rows;
        });
    }

    searchApprovers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.listApprovers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

    formatterApprovers = (x: any) => x.name;

    public sortDetails(field:string){
        if( (this.info.details != undefined) && Array.isArray(this.info.details) ){
            this.sort_asc = !this.sort_asc;
            let arr = this.info.details;

            if(this.sort_asc){
                //arr.sort((a,b) => a[field].localeCompare(b[field]));
                arr.sort((a,b) => a[field] - b[field]);
            }
            else{
                //arr.sort((a,b) => b[field].localeCompare(a[field]));
                arr.sort((a,b) => b[field] - a[field]);
            }
            
            this.info.details = arr;
        }
    }

}
