import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { LaddaModule } from 'angular2-ladda';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { OrderModule } from 'ngx-order-pipe';

import { FullComponent } from './layouts/full/full.component';
/*import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';*/

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { setTheme } from 'ngx-bootstrap/utils';
setTheme('bs4');

import { Ic2routes } from './ic2-routing';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Ic2Component } from '../ic2/ic2.component';
import { GlobalparamsComponent } from './globalparams/globalparams.component';

import {TableModule} from 'primeng/table';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {CarouselModule} from 'primeng/carousel';

import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmDrawingModule } from '@agm/drawing';
import { MapgoogleComponent } from './maps/map-google/map-google.component';
import { QuillModule } from 'ngx-quill';
import { LightboxModule } from 'ngx-lightbox';
// the scanner!
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { BaseCrudComponent } from './base-crud/base-crud.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompanycontactsComponent } from './companycontacts/companycontacts.component';
import { SitesComponent } from './sites/sites.component';
import { SiteparamsComponent } from './siteparams/siteparams.component';
import { UnitsComponent } from './units/units.component';
import { UnitparamsComponent } from './unitparams/unitparams.component';
import { SitecontactsComponent } from './sitecontacts/sitecontacts.component';
import { AlarmsComponent } from './alarms/alarms.component';
import { CustomersComponent } from './customers/customers.component';
import { SellersComponent } from './sellers/sellers.component';
import { UsersComponent } from './admins/users/users.component';
import { HomeComponent } from './home/home.component';
import { PayrolComponent } from './payrol/payrol.component';
import { ColaboratorsComponent } from './colaborators/colaborators.component';
import { AdminsComponent } from './admins/admins.component';

import { CalendarModule, CalendarDateFormatter } from 'angular-calendar';
import { MonthscheduleComponent } from './month-schedule/month-schedule.component';
import { WeatherheaderComponent } from './weather-header/weather-header.component';
import { CommentComponent } from './recent-comments/comment.component';
import { ProfileComponent } from './profile/profile.component';
import { ExperienceComponent } from './profile/experience/experience.component';
import { GeneralComponent } from './profile/general/general.component';
import { DepartmentComponent } from './profile/department/department.component';
import { PayrolinfoComponent } from './profile/payrolinfo/payrolinfo.component';
import { HealthComponent } from './profile/health/health.component';
import { DocumentsComponent } from './profile/documents/documents.component';
import { PersonalinfoComponent } from './profile/personalinfo/personalinfo.component';
import { ContactComponent } from './profile/contact/contact.component';
import { ExpensesComponent } from './profile/expenses/expenses.component';
import { DependantsComponent } from './profile/dependants/dependants.component';
import { EducationComponent } from './profile/education/education.component';
import { DocapprovalComponent } from './admins/docapproval/docapproval.component';
import { AdvanceComponent } from './advance/advance.component';
import { AdvanceapproveComponent } from './admins/advanceapprove/advanceapprove.component';
import { BenefitComponent } from './benefit/benefit.component';
import { AdvanceextComponent } from './advanceext/advanceext.component';
import { FeedhomeComponent } from './feeding/feedhome/feedhome.component';
import { LunchComponent } from './feeding/lunch/lunch.component';
import { DinnerComponent } from './feeding/dinner/dinner.component';
import { PermissionapproveComponent } from './admins/permissionapprove/permissionapprove.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { MealComponent } from './admins/meal/meal.component';
import { MealbookingComponent } from './admins/mealbooking/mealbooking.component';
import { CertificateComponent } from './certificate/certificate.component';
import { RoleComponent } from './admins/role/role.component';
import { AbsenceComponent } from './absence/absence.component';
import { AbsenceapproveComponent } from './admins/absenceapprove/absenceapprove.component';
import { BranchesComponent } from './admins/branches/branches.component';
import { MealhomeComponent } from './admins/mealhome/mealhome.component';
import { LoanComponent } from './loan/loan.component';
import { LoanapproveComponent } from './admins/loanapprove/loanapprove.component';
import { PayroladminComponent } from './admins/payroladmin/payroladmin.component';
import { KitchenComponent } from './admins/kitchen/kitchen.component';
import { ReportMonthlyComponent } from './admins/report-monthly/report-monthly.component';
import { ReportDailyComponent } from './admins/report-daily/report-daily.component';
import { ReportProviderComponent } from './admins/report-provider/report-provider.component';
import { ReportKitchenDeliveredComponent } from './admins/report-kitchen-delivered/report-kitchen-delivered.component';
import { KitchenprocessComponent } from './admins/kitchenprocess/kitchenprocess.component';
import { PollComponent } from './admins/poll/poll.component';
import { PollreportComponent } from './admins/pollreport/pollreport.component';
import { StaffapprovalComponent } from './admins/staffapproval/staffapproval.component';
import { StaffComponent } from './admins/staff/staff.component';
import { VacationComponent } from './vacation/vacation.component';
import { VacationapproveComponent } from './admins/vacationapprove/vacationapprove.component';
import { HeventComponent } from './admins/hevent/hevent.component';
import { AccountComponent } from './account/account.component';
import { OrgchartComponent } from './orgchart/orgchart.component';
import { BannerComponent } from './admins/banner/banner.component';
import { CommissionsComponent } from './admins/commissions/commissions.component';
import { ComissionCreateComponent } from './admins/commissions/comission-create/comission-create.component';
import { ComrolComponent } from './comrol/comrol.component';
import { SubsidiesComponent } from './admins/subsidies/subsidies.component';
import { DependantapproveComponent } from './admins/dependantapprove/dependantapprove.component';
import { ReportEmployeeComponent } from './admins/report-employee/report-employee.component';
import { ProfitadminComponent } from './admins/profitadmin/profitadmin.component';
import { ProfitComponent } from './profit/profit.component';
import { SettingsComponent } from './admins/settings/settings.component';
import { D3Component } from './d3/d3.component';
import { ReportsComponent } from './admins/reports/reports.component';
import { ReportAdvanceComponent } from './admins/report-advance/report-advance.component';
import { ReportVacationComponent } from './admins/report-vacation/report-vacation.component';
import { PermissionqueryComponent } from './admins/permissionquery/permissionquery.component';
import { PayrolqueryComponent } from './admins/payrolquery/payrolquery.component';
import { PayrolspecialqueryComponent } from './admins/payrolspecialquery/payrolspecialquery.component';
import { VacationqueryComponent } from './admins/vacationquery/vacationquery.component';
import { AdvancequeryComponent } from './admins/advancequery/advancequery.component';
import { AdvanceextqueryComponent } from './admins/advanceextquery/advanceextquery.component';
import { LoanqueryComponent } from './admins/loanquery/loanquery.component';
import { MycollabsComponent } from './admins/mycollabs/mycollabs.component';
import { AdminLunchComponent } from './admins/lunch/lunch.component';
import { AdminDinnerComponent } from './admins/dinner/dinner.component';
import { UpdateFormComponent } from './update-form/update-form.component';
import { BenefitreqComponent } from './benefitreq/benefitreq.component';
import { BenefitreqapproveComponent } from './admins/benefitreqapprove/benefitreqapprove.component';
import { ReportBenefitsreqComponent } from './admins/report-benefitsreq/report-benefitsreq.component';
import { MealcategoryComponent } from './admins/mealcategory/mealcategory.component';
import { RemoteworkComponent } from './remotework/remotework.component';

import {WebcamModule} from 'ngx-webcam';
import { ReportRemoteworkComponent } from './admins/report-remotework/report-remotework.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

/*registerLocaleData(localeEs);*/

@NgModule({
  declarations: [
    FullComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    Ic2Component,
    MapgoogleComponent,
    BaseCrudComponent,
    GlobalparamsComponent,
    CompaniesComponent,
    CompanycontactsComponent,
    SitesComponent,
    SiteparamsComponent,
    UnitsComponent,
    UnitparamsComponent,
    SitecontactsComponent,
    AlarmsComponent,
    CustomersComponent,
    SellersComponent,
    UsersComponent,
    HomeComponent,
    PayrolComponent,
    ColaboratorsComponent,
    AdminsComponent,
    MonthscheduleComponent,
    WeatherheaderComponent,
    CommentComponent,
    ProfileComponent,
    ExperienceComponent,
    GeneralComponent,
    DepartmentComponent,
    PayrolinfoComponent,
    HealthComponent,
    DocumentsComponent,
    PersonalinfoComponent,
    ContactComponent,
    ExpensesComponent,
    DependantsComponent,
    EducationComponent,
    DocapprovalComponent,
    AdvanceComponent,
    AdvanceapproveComponent,
    BenefitComponent,
    AdvanceextComponent,
    FeedhomeComponent,
    LunchComponent,
    DinnerComponent,
    PermissionapproveComponent,
    PermissionsComponent,
    MealComponent,
    MealbookingComponent,
    CertificateComponent,
    RoleComponent,
    AbsenceComponent,
    AbsenceapproveComponent,
    BranchesComponent,
    MealhomeComponent,
    LoanComponent,
    LoanapproveComponent,
    PayroladminComponent,
    KitchenComponent,
    ReportMonthlyComponent,
    ReportDailyComponent,
    ReportProviderComponent,
    ReportKitchenDeliveredComponent,
    KitchenprocessComponent,
    PollComponent,
    PollreportComponent,
    StaffapprovalComponent,
    StaffComponent,
    VacationComponent,
    VacationapproveComponent,
    HeventComponent,
    AccountComponent,
    OrgchartComponent,
    BannerComponent,
    CommissionsComponent,
    ComissionCreateComponent,
    ComrolComponent,
    SubsidiesComponent,
    DependantapproveComponent,
    ReportEmployeeComponent,
    ProfitadminComponent,
    ProfitComponent,
    SettingsComponent,
    D3Component,
    ReportsComponent,
    ReportAdvanceComponent,
    ReportVacationComponent,
    PermissionqueryComponent,
    PayrolqueryComponent,
    PayrolspecialqueryComponent,
    VacationqueryComponent,
    AdvancequeryComponent,
    AdvanceextqueryComponent,
    LoanqueryComponent,
    MycollabsComponent,
    AdminLunchComponent,
    AdminDinnerComponent,
    UpdateFormComponent,
    BenefitreqComponent,
    BenefitreqapproveComponent,
    ReportBenefitsreqComponent,
    MealcategoryComponent,
    RemoteworkComponent,
    ReportRemoteworkComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    TableModule,
    OrganizationChartModule,
    ScrollPanelModule,
    CarouselModule,
    NgbModule,
    // gets the scanner ready!
    ZXingScannerModule,
    //RouterModule.forRoot(Ic2routes, {onSameUrlNavigation: 'reload'}),
    RouterModule.forRoot(Ic2routes),
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    AgmDrawingModule,
    BsDatepickerModule.forRoot(),
    LaddaModule,
    CalendarModule.forRoot(),
    TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    OrderModule,
    QuillModule.forRoot(),
    LightboxModule,
    WebcamModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DatePipe
  ]/*,
  bootstrap: [AppComponent]*/
})
export class Ic2Module {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    //return new TranslateHttpLoader(http);
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}