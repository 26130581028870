import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';

@Component({
  selector: 'app-siteparams',
  templateUrl: './siteparams.component.html',
  styleUrls: ['./siteparams.component.css']
})
export class SiteparamsComponent extends BaseCrudComponent {
	params:any;
	id:any;
	units_update_url = '';

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.create_url = '';
        this.read_url = 'site/SiteParams/' + this.id;
        this.update_url = 'Parameter/UpdateParameterSetting';
        this.delete_url = '';
        this.units_update_url = 'Parameter/ArrayUpdateParameterSetting';

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getUnitsList();
            }
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.regularForm = new FormGroup({
            /*'ParName': new FormControl(null, [Validators.required]),
            'Partype': new FormControl(null, [Validators.required]),
            'ParIdProvider': new FormControl(null, [Validators.required]),
            'ParKeyProvider': new FormControl(null, [Validators.required])*/
        }, {updateOn: 'blur'});

        //this.getList();
        this.getUnitsList();
    }

    public getList(){
        var qst = this.read_url;
        this.srv.getCall(qst).subscribe(x => {
            if(x.success){
                var rs = x.val;

                rs.forEach(x => {
                  	//console.log(x);
	                if(x.Parameter.Partype == 0){
	                   x.Parameter.ParTypeStr = 'Parametro';
	                }
	                else{
	                   x.Parameter.ParTypeStr = 'Alarma';
	                }

                  	if(x.ParSOriginRange == 'S'){
		        		x.inherit = '';
		        	}
		        	else{
		        		x.inherit = 'Global';
		        	}
                });

                this.rows = rs;
            }
        });
    }

    public getUnitsList(){
        var qst = 'Units/unitbysites';
        this.srv.postCall(qst, {SitId:this.id}).subscribe(x => {
            if(x.success){
                var rs = x.val;
                this.params = rs;
                this.getList();
            }
        });
    }

    public updateItem(content:any, form:any){
        if( form == undefined ){
          form = {};
        }

        var to:any;
        var unitparams = [];
        var vm = this;
        
        to = {};
        to.ParId = form.Parameter.ParId;
        to.ParName = form.Parameter.ParName;
        to.Partype = form.Parameter.Partype;
        to.ParIndicatorType = form.Parameter.ParIndicatorType;
        to.ParFieldKey = form.Parameter.ParFieldKey;
        
        if( (form.ParSmin != undefined) || (form.ParSmax != undefined) ){
    			
			if( (form.ParSmin != '') || (form.ParSmax != '') ){
				to.ParameterSetting = 
					{
						"ParSMin": form.ParSmin,
						"ParSMax": form.ParSmax,
						"ParSStatus": form.ParSStatus,
						"ParSParameterId": to.ParId,
						"ParSItemId": vm.id,
						"ParSType": 1, //site,
						"ParSId": form.ParSId
					};

					vm.params.forEach(u => {
						if( u.selected ){
							unitparams.push({
    							"ParSMin": form.ParSmin,
								"ParSMax": form.ParSmax,
								"ParSStatus": form.ParSStatus,
								"ParSParameterId": to.ParId,
								"ParSItemId": u.UniId,
								"ParSType": 2, //unit
								"ParSId": 0
    						});
						}
					});
			}
			else{
				to.ParameterSetting = {};
			}
    			
		}
		else{
			to.ParameterSetting = {};
		}

        var qst = this.update_url;
        this.srv.postCall(qst, to.ParameterSetting).subscribe(x => {
            if(x.success){
                if( unitparams.length > 0 ){
        			this.srv.postCall(this.units_update_url, unitparams).subscribe(x => {
	                	this.getList();
	                	this.closeModal(content);
	                });
        		}
        		else{
        			this.getList();
        			this.closeModal(content);
        		}
            }//end success
            else{
            	this.closeModal(content);
            }
        });
    }

    public openUpdateModal(content: any, row: any) {
        var vm = this;
        vm.params.forEach(x => {
        	x.selected = false;
        });
        super.openUpdateModal(content, row);
    }

}
