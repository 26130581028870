import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/home',
    title: 'Inicio',
    icon: 'fas fa-home',
    class: '',
    extralink: false,
    perm: 'home',
    submenu: []
  },
  {
    path: '/collaborators',
    title: 'Servicios',
    icon: 'fas fa-th-large',
    class: '',
    extralink: false,
    perm: 'SERVICIOS',
    submenu: []
  },
  {
    path: '/admins',
    title: 'Admin',
    icon: 'fas fa-user-cog',
    class: '',
    extralink: false,
    perm: 'ADMINS',
    submenu: []
  }/*,
  {
    path: '/settings',
    title: 'Configuración',
    icon: 'fas fa-cog',
    class: '',
    extralink: false,
    perm: 'sett',
    submenu: []
  }*/
];
